import React, { Component } from 'react';
class ActionBtn extends Component {
  state = {  }
  render() { 
    return ( 
      <div className='group-actions'>
                {this.props.delete ? <a onClick={this.props.deleteClick} title="Delete"><i className="icon trash alternate" aria-hidden="true"></i></a> : null}
                {this.props.edit ? <a onClick={this.props.editClick} title="Edit"><i className="icon write" aria-hidden="true"></i></a> : null}
            </div>
     );
  }
}
ActionBtn.defaultProps = {
  delete: true,
  edit:true
}
export default ActionBtn;