import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getOrders } from '../../actions/order';
import ActivityIndicator from '../../components/loaders/ActivityIndicator';
import OrderCard from '../../components/custom/OrderCard';
import CancelOrder from '../../components/modal/CancelOrder';
import RatingOrder from '../../components/modal/RatingOrder';

class MyOrders extends Component {

    state = { openCancelModal:false, openRatingModal:false, skip:0, take:10 }

    changeCancelModal = (id)=>{
        this.setState({openCancelModal:!this.state.openCancelModal, id})
    }

    changeRatingModal = (id)=>{
        this.setState({openRatingModal:!this.state.openRatingModal, id})
    }

    componentDidMount(){
       this.onLoad()
    }

    onLoad = ()=>{
        var data = {
            customer_id:  this.props.user.id, 
            offset:0, 
            limit:this.state.take 
        }
        this.props.onGetOrders(data)
    }

    render() { 
        const {
            openCancelModal,
            openRatingModal,
            id
        } = this.state
        const {orders, loading } = this.props

        if(loading && (!orders || orders.length < 1)){
            return ( 
                <div className="oder-history">
                    <ActivityIndicator/>
                </div>
            );
        }

        if(!orders || orders.length < 1){
            return ( 
                <div className="oder-history">
                    <div className="align-center">
                        <h3>Place Your First Order</h3>
                    </div>
                </div>
            );
        }

        return ( 
            <div className="oder-history">
                {orders.map((order, index)=>(
                    <OrderCard 
                        key={index} 
                        {...order} 
                        index={index}
                        changeCancelModal={()=>this.changeCancelModal(order.id)}
                        changeRatingModal={()=>this.changeRatingModal(order.id)}/>  
                ))}
                <CancelOrder
                    isOpen={openCancelModal}
                    id={id}
                    onDone={()=>{
                        this.setState({openCancelModal: false})
                        this.onLoad()
                    }}
                    onClose={this.changeCancelModal}/>
                <RatingOrder 
                    isOpen={openRatingModal}
                    id={id}
                    onDone={()=>{
                        this.setState({openRatingModal: false})
                        this.onLoad()
                    }}
                    onClose={this.changeRatingModal}/>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.userReducer.user,
    orders: state.orderReducer.orders,
    loading: state.orderReducer.loading,
    count: state.orderReducer.count
});

const mapDispatchToProps = dispatch => ({
    onGetOrders:(data, loadMore)=>{
        dispatch(getOrders(data, loadMore))
    }
});
const MyOrdersContainer = connect(
                        mapStateToProps,
                        mapDispatchToProps)
                        (MyOrders);
export default MyOrdersContainer; 