import request from '../config/HttpClient'
import * as URLS from '../constants/Urls'

function getOrders(data) {

    return request({
      url:   URLS.GET_CUSTOMER_ORDERS,
      method: 'POST',
      data
    });
}

function getOrder(order_id) {
  var sub_url = '/'+order_id
  return request({
    url:   URLS.GET_CUSTOMER_ORDER + sub_url,
    method: 'GET'
  });
}

function cancelOrder(data) {
  return request({
    url:   URLS.CANCEL_ORDER,
    method: 'POST',
    data
  });
}

function addReview(data) {
  return request({
    url:   URLS.ADD_REVIEW,
    method: 'POST',
    data
  });
}


function getCancelReasons() {
  return request({
    url:   URLS.GET_CANCEL_REASONS,
    method: 'GET'
  });
}

function getFastDelivery() {
  return request({
    url:   URLS.GET_FAST_DELIVERY,
    method: 'GET'
  });
}


function rescheduleOrder(data) {
  return request({
    url:   URLS.RESCHEDLUE_ORDER,
    method: 'POST',
    data
  });
}

const OrderService = {
    getOrders,
    getOrder,
    getCancelReasons,
    cancelOrder,
    addReview,
    rescheduleOrder,
    getFastDelivery
}


export default OrderService;