import React from 'react'
import ContentLoader from 'react-content-loader'

const ProductLoader  = ({
  y,
  cardHeight,
  cardWidth,
  height,
  width
})=>{
  var viewBox = '0 0 '+width + ' '+height
  return(
    <ContentLoader
      speed={2}
      width={width}
      height={height}
      viewBox={viewBox}
      backgroundColor="#2e817e"
      foregroundColor="#ecebeb">
      <rect x='0' y={y} rx="2" ry="2" width={cardWidth} height={cardHeight} />
      <rect x={cardWidth + 10 } y={y} rx="2" ry="2" width={cardWidth} height={cardHeight} />
      <rect x={(cardWidth * 2)+20} y={y} rx="2" ry="2" width={cardWidth} height={cardHeight} />
      <rect x={(cardWidth * 3)+30} y={y} rx="2" ry="2" width={cardWidth} height={cardHeight} />
      <rect x={(cardWidth * 4)+40} y={y} rx="2" ry="2" width={cardWidth} height={cardHeight} />
      <rect x={(cardWidth * 5)+50} y={y} rx="2" ry="2" width={cardWidth} height={cardHeight} />
    </ContentLoader>
  )
}

ProductLoader.defaultProps = {
  y:100,
  cardHeight:350,
  cardWidth:250,
  height: 600,
  width: 1200
}

const HomeLoader = () => {

  const width = window.innerWidth
  const height = window.innerHeight

  const itemWidth = width * (1/3)
  const itemHeight = itemWidth * (1/1.6)
  var viewBox = '0 0 '+width + ' '+height

  const cardWidth = 250
  const cardHeight = 350


  const renderProduct = (index, x, y, cardWidth=250, cardHeight=340)=>{
    return <rect x={x} y={y} rx="2" ry="2" width={cardWidth} height={cardHeight} key={index}/>
  }
  const products = [1,2,3,4,5]

  return(
    <ContentLoader
      speed={2}
      width={width}
      height={height}
      viewBox={viewBox}
      backgroundColor="#2e817e"
      foregroundColor="#ecebeb">
       
        <rect x="0" y="30" rx="2" ry="2" width={itemWidth} height={itemHeight} />
        <rect x={itemWidth+10} y="30" rx="2" ry="2" width={itemWidth} height={itemHeight} />
        {products.map((p,i)=>(
          renderProduct(i, (cardWidth * i) + (10*i),  itemHeight + 50)
        ))}
        <rect x="0" y={itemHeight + 60 + cardHeight} rx="2" ry="2" width={width * 0.7} height={width*0.45} />
    </ContentLoader>
  )
}

export default HomeLoader