import _ from 'lodash'
import React, { Component } from 'react'
import { Search } from 'semantic-ui-react'

const initialState = { value: '' }

export default class SearchBar extends Component {

  state = initialState

  handleResultSelect = (e, { result }) =>{
    const {onSelect} = this.props
    if(onSelect){
      onSelect(result)
    }
  } 

  handleSearchChange = (e, { value }) => {
    const {onSearch} = this.props
    this.setState({ value })
    if(onSearch){
      onSearch(value)
    }
  }

  render() {
    const {loading, results} = this.props
    const { value } = this.state

    return (
          <Search
            loading={loading}
            onResultSelect={this.handleResultSelect}
            onSearchChange={_.debounce(this.handleSearchChange, 500, {
              leading: true,
            })}
            placeholder="Search for products"
            results={results}
            value={value}
            {...this.props}
          />
    )
  }
}