import request from '../config/HttpClient'
import * as URLS from '../constants/Urls'

function login(mobile,password) {

  return request({
    url:    URLS.LOGIN,
    method: 'POST',
    data:{
         mobile,
         password,
      }
  });
}

function register(mobile,password,c_password,first_name,second_name,email) {
  return request({
    url:    URLS.REGISTER,
    method: 'POST',
    data: {
       first_name,
       second_name,
       mobile,
       password,
       c_password,
       email
    }
  });
}

function getNotifications(customer_id,skip,take){
  var sub_url = '/'+customer_id;
  if(skip !==null && typeof skip !== 'undefined')sub_url = sub_url+'/'+skip;
  if(take !==null && typeof take !== 'undefined')sub_url = sub_url+'/'+take;
  return request({
    url:    URLS.GET_NOTIFICATIONS+sub_url,
    method: 'GET'
  });
  
}

function getPoints(customer_id){
  var sub_url = '/'+customer_id;
  return request({
    url:    URLS.GET_POINTS+sub_url,
    method: 'GET'
  });
}

function getStatements(ac_id,skip,take){
  var sub_url = '/'+ac_id;
  if(skip !==null && typeof skip !== 'undefined')sub_url = sub_url+'/'+skip;
  if(take !==null && typeof take !== 'undefined')sub_url = sub_url+'/'+take;
  return request({
    url:    URLS.GET_STATEMENTS+sub_url,
    method: 'GET'
  });
}

function sendOTP(customer_id, device_id){
  var sub_url = '/'+customer_id+'/'+device_id;
  return request({
    url:    URLS.SEND_OTP+sub_url,
    method: 'GET'
  });
}

function verifyOTP(customer_id,otp){
  var sub_url = '/'+otp+'/'+customer_id;
  return request({
    url:    URLS.VERIFY_OTP+sub_url,
    method: 'GET'
  });
}

function changePassword(data){
  return request({
    url:    URLS.CHANGE_PASSWORD,
    method: 'POST',
    data
  });
}

function getUnreadNotificationCount(customer_id){
  var sub_url = '/'+customer_id;
  return request({
    url:    URLS.GET_UNREAD_NOTIFICATION_COUNT+sub_url,
    method: 'GET'
  });
}

function registerToken(data){

  return request({
    url:    URLS.REGISTER_TOKEN,
    method: 'POST',
    data
  });
}

function lastLoged(data){
  return request({
    url:    URLS.LAST_LOGED,
    method: 'POST',
    data
  });
}

function setCustomerStore(data){

  return request({
    url:    URLS.SET_CUSTOMER_STORE,
    method: 'POST',
    data
  });
}

function changeNotificationSubscription(data){

  return request({
    url:    URLS.CHANGE_NOTIFICATION_SUBSCRIPTION,
    method: 'POST',
    data
  });
}

function changeNotificationStatus(customer_id){
  var sub_url = '/'+customer_id;
  return request({
    url:    URLS.CHANGE_NOTIFICATION_STATUS+sub_url,
    method: 'GET'
  });
}


function addLocation(data){
  return request({
    url:    URLS.ADD_CUSTOMER_LOCATION,
    method: 'POST',
    data
  });
}

function getLocations(customer_id){
  var sub_url = '/'+customer_id;
  return request({
    url:    URLS.GET_CUSTOMER_LOCATIONS+sub_url,
    method: 'GET'
  });
}

function getSavedCards(customer_id){
  var sub_url = '/'+customer_id;
  return request({
    url:    URLS.GET_SAVED_CARDS+sub_url,
    method: 'GET'
  });
}








const UserService = {
  login,
  register,
  getNotifications,
  getPoints,
  getStatements,
  sendOTP,
  verifyOTP,
  changePassword,
  getUnreadNotificationCount,
  registerToken,
  lastLoged,
  setCustomerStore,
  changeNotificationSubscription,
  changeNotificationStatus,
  addLocation,
  getLocations,
  getSavedCards
}


export default UserService;
