import React, { Component } from 'react';
import Slider from "react-slick";
import AImage from '../../components/basic/AImage';

const SETTINGS = {
    dots: false,
    autoplay:true,
    centerMode: true,
    centerPadding: '20%',
    infinite: true,
    speed: 1200,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [

        {
          breakpoint: 767,
          settings: {
            centerMode: false,
            centerPadding: '0%',
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
};

class SlidingBanner extends Component {

    onClick = (slider)=>{
        if(slider.clickable == 1){
            this.props.history.push('/products/slide/'+slider.id)
        }
    }

    render() { 
        const {sliders} = this.props
        
        return ( 
            <Slider {...SETTINGS}>
                {sliders && sliders.map((slider, index)=>(
                    <div key={index} className="sliding-banner" onClick={()=>this.onClick(slider)}>
                        <AImage src={slider.web_image} alt="wild" className="img-fluid"/>
                    </div>
                ))}
            </Slider>   
         );
    }
}
 
export default SlidingBanner;