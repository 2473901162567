import * as Actions from '../constants/ActionTypes';

const initialState ={
    success:false,
    loading:false,
}

export default function changePasswordReducer(state = initialState , action){
    switch(action.type){
       

        case Actions.CHANGE_PASSWORD_LOADING:
            return{
                success:false,
                loading:true
            }
        case Actions.CHANGE_PASSWORD_SUCCESS:
        
            return{
                loading:false,
                success: true
            }
        case Actions.CHANGE_PASSWORD_FAILED:
            return{
                success:false,
                loading:false,
            }
            
        default:
        return state
       
    }
}