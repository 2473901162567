import * as Constant from '../constants/AppConstants';

function getCartCount(cart, id){
    for(var i=0; i<cart.length; i++){
        var cartItem = cart[i]
        if(cartItem.id === parseInt(id)){
            return cartItem.count
        }
    }
    return 0
}

function tConv24(time24, disableA=false) {
    if(!time24) return ''
    var ts = time24;
    var H = +ts.substr(0, 2);
    var h = (H % 12) || 12;
    // h = (h < 10)?("0"+h):h;  // leading 0 at the left for 1 digit hours
    var ampm = H < 12 ? " AM" : " PM";
    if(disableA)ampm=""
    ts = h + ts.substr(2, 3) + ampm;
    return ts;
};

function formatDate(date, only_date=false, reverse=false){
    var d = new Date(date)
    d.setHours(0, 0, 0, 0);
    const today = new Date()
    today.setHours(0, 0, 0, 0);
    const lat = today.toString()
    const yesterday = new Date(lat)
    yesterday.setDate(yesterday.getDate() - 1);
    const tomorrow = new Date(lat)
    tomorrow.setDate(tomorrow.getDate() + 1);
    if(!only_date){
        if(d.getTime()==today.getTime()) return 'Today'
        if(d.getTime()==yesterday.getTime()) return 'Yesterday'
        if(d.getTime()==tomorrow.getTime())return 'Tomorrow'
    }
    var dd = d.getDate()<10?'0'+d.getDate():d.getDate()
    var dm = (d.getMonth()+1)<10?'0'+(d.getMonth()+1):d.getMonth()+1
    if(reverse) return d.getFullYear()+'-'+dm+'-'+dd
    return  dd+ '-' + dm + '-' +d.getFullYear()
}

function parseTime(time){
    if(time < 10){
        return '0'+time
    }
    return time
}

function isToday(someDate) {
    const today = new Date()
    return someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
}

function getIntervels(open , close, date){
    var now = new Date();
    var nowHr = now.getHours()
    var nowMin = now.getMinutes()
    var parts =date.split('-');
    var today = isToday(new Date(parts[2], parts[1] - 1, parts[0]))
    var result = []
    var limit = 30
    if(!open || !close){
        return result
    }
    var opens = open.split(':')
    var closes = close.split(':')
    if(!closes || !opens || closes.length < 2 || opens.length < 2){
        return result
    }
    var openHr = parseInt(opens[0])
    var openMin = parseInt(opens[1])
    var closeHr = parseInt(closes[0])
    var closeMin = parseInt(closes[1])
    if(openHr > closeHr){
        return result
    }

    while(true){
        var from = parseTime(openHr) + ':' + parseTime(openMin) + ':' + '00'
        openMin = openMin + limit;
        if(openMin >= 60){
            openHr = openHr + 1
            openMin = openMin - 60
        }
        var to = parseTime(openHr) + ':' + parseTime(openMin) + ':' + '00'
        if(openHr < closeHr || (openHr === closeHr && openMin <= closeMin)){
            if(((openHr < nowHr) || (openHr === nowHr && openMin<nowMin))&&today){
                continue;
            }
            result.push({from:from, to:to, id:0})
        }else{
            break;
        }
    }
    return result
}



function getDeliveryMode(mode) {
    switch(mode){
      case Constant.SLOT_DELIVERY :
          return 'Slot Delivery';
      case Constant.FAST_DELIVERY:
          return 'Fast Delivery' ;
      case Constant.TAKE_AWAY :
          return 'Take Away' ;
      default :
          return null;          
    }
}

function getPayMethod(mode) {
    switch(mode){
        case Constant.PAYMENT_ONLINE :
            return 'Online';
        case Constant.PAYMENT_COD:
            return 'COD' ;
        case Constant.PAYMENT_CARD_ON_DELIVERY:
        return 'CARD ON DELIVERY'
        default :
            return null;          
    }
}
  
function getPaymetStatus(mode) {
    switch(mode){
      case Constant.PAYMENT_SUCCESS :
          return 'Success';
      case Constant.PAYMENT_FAILED:
          return 'Failed' ;
          case Constant.PAYMENT_PENDING :
          return 'Pending' ;
      default :
          return null;          
    }
}

function getOrderStatus(mode) {
    switch(mode){
        case Constant.ORDER_CREATED :
            return 'Order Placed';
        case Constant.ORDER_ACCEPTED:
            return 'Processing' ;
        case Constant.ORDER_PACKING :
            return 'Processing' ;
        case Constant.ORDER_PACKED :
            return 'Processing';
        case Constant.ORDER_SHIPPING:  
            return 'Shipping';
        case Constant.ORDER_COMPLETED:  
            return 'Completed';
        case Constant.ORDER_CANCELLED:  
            return 'Cancelled';
        default :
            return null;          
    }
}

function getOrderStatusColor(mode) {
    let classes = "status ";
    switch(mode) {
      case Constant.ORDER_COMPLETED :
        classes += 'bg-green';
        return classes;
      case Constant.ORDER_CANCELLED:
        classes += 'bg-red';
        return classes;
      case Constant.ORDER_RESCHEDULED :
        classes += 'bg-orange';
        return classes;

      default :
        classes += 'bg-yellow';
        return classes;  
    }
}

function getPymentStatusColor(mode) {
    let classes = "st-block ";
    switch(mode) {
      case Constant.PAYMENT_SUCCESS :
        classes += 'bg-green';
        return classes;
      case Constant.PAYMENT_FAILED:
        classes += 'bg-red';
        return classes;
      case Constant.PAYMENT_PENDING :
        classes += 'bg-orange';
        return classes;

      default :
      return classes;  
    }
}

function getButtonName(mode) {
    switch(mode){
        case Constant.ORDER_CREATED :
            return 'MARK AS PROCESSING';
        case Constant.ORDER_ACCEPTED:
            return 'MARK AS SHIPPING' ;
        case Constant.ORDER_SHIPPING :
            return 'MARK AS DELIVERD';  
        default :
            return null;          
    }
}



export {
    getCartCount,
    tConv24,
    getIntervels,
    isToday,
    formatDate,
    getDeliveryMode,
    getPayMethod,
    getPaymetStatus,
    getOrderStatus,
    getOrderStatusColor,
    getPymentStatusColor,
    getButtonName
}