import React, { Component } from 'react';
import { connect } from 'react-redux';

import { changeNotificationStatus, getNotifications } from '../../actions/user';
import ActivityIndicator from '../../components/loaders/ActivityIndicator';

class Notifications extends Component {

    componentDidMount(){
        this.props.onGetNotifications(this.props.user.id, 0, 10)
    }

    renderNotification = (notification)=>{
        return(
            <div className="col-md-12" key={notification.id}>
                <div className="note-box">
                    <div className="action-area">
                        <div className="large-text">{notification.title}</div>
                        <div className="date">
                            {notification.time}
                        </div>
                    </div>
                    <div className="grey-text">
                        <p>{notification.message}</p>
                    </div>
                </div>
            </div>
        )
    }
    
    render() { 
        const {notifications, loading} = this.props

        if(loading && (!notifications || notifications.length < 1)){
            return ( 
                <div className="oder-history">
                    <ActivityIndicator/>
                </div>
            );
        }

        if(!notifications || notifications.length < 1){
            return ( 
                <div className="oder-history">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="align-center">
                                <h3>You Have No Notifications</h3>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return ( 
            <div className="row">
                {notifications.map((notification, index)=>(
                    this.renderNotification(notification)
                ))}
            </div>
         );
    }

}

const mapStateToProps = state => ({
    loggedIn: state.userReducer.loggedIn,
    user:state.userReducer.user,
    notifications:state.userReducer.notifications,
    loading:state.userReducer.notificationLoading,
});

const mapDispatchToProps = dispatch => ({
    onGetNotifications: (customer_id,skip,take) => {
        dispatch(getNotifications(customer_id,skip,take));
    },
    onChangeNotificationStatus : (customer_id)=>{
        dispatch(changeNotificationStatus(customer_id))
    }
});
export default connect(mapStateToProps,
                        mapDispatchToProps)(Notifications);
