import * as Actions from '../constants/ActionTypes';
import { PURGE, REHYDRATE } from 'redux-persist';

const initialState ={
  products:[],
  slide_banner_loading:false,
  still_banner_loading:false,
  volume_offers_loading:false,
  category_offers_loading:false,
  more_category_offers_loading:false,
  todays_deals_loading:false,
  fantastic_deals_loading:false,
  more_fantastic_deals_loading:false,
  loadingFilter:false,
  more_todays_deals_loading:false,
  filterSuccess:false,
  storeChanged:true,
  isLoadingCat:false,
  loadingFilterMore:false,
  dashboardLoading:false,
  index:null,
  loadDate:null,
  loadCount:0
}

export default function homeReducer(state = initialState , action){
  switch(action.type){

    case Actions.RESET: 
    return {};

  case Actions.DASHBOARD_LOADING:
    return {
       ...state,
       storeChanged:false,
       dashboardLoading:true,
    };
 case Actions.DASHBOARD_SUCCESS:
   return {
      ...state,
      date:action.date,
      dashboardLoading:false,
      slideBanners: action.slide_banner,
      volOffers: action.volume_offer,
      todDeals: action.todays_deal,
      fanDeals:action.fantastic_deal,
      stillBanners: action.still_banner,
      catOffers: action.category_offers

   };
 case Actions.DASHBOARD_FAILED:
   return {
      ...state,
      dashboardLoading:false,
      error:action.error
  };


  
   case Actions.SLIDE_BANNERS_LOADING:
     return {
        ...state,
        storeChanged:false,
       slide_banner_loading:true,
     };
  case Actions.SLIDE_BANNERS:
      var slide_banners = action.slideBanners;
      // if(action.skip !== null && action.skip > 0){
      //   slide_banners = slide_banners.concat(state.slideBanners)
      // }
    return {
       ...state,
       date:action.date,
       slide_banner_loading:false,
       slideBanners: slide_banners,
    };
  case Actions.SLIDE_BANNERS_FAILURE:
    return {
       ...state,
      slide_banner_loading:false,
      error:action.error
    };

  case Actions.VOLUME_OFFERS_LOADING:
  
    return {
       ...state,
      volume_offers_loading:true,
    };
  case Actions.VOLUME_OFFERS:
   return {
      ...state,
      date:action.date,
      volume_offers_loading:false,
      volOffers: action.volOffers,
   };
  case Actions.VOLUME_OFFERS_FAILURE:
   return {
      ...state,
     volume_offers_loading:false,
     error:action.error
   };



   case Actions.TODAYS_DEALS_LOADING:
     return {
        ...state,
        isLoading:true,
       index:action.index,
       count:0
     };
   case Actions.TODAYS_DEALS:
    var toady_deals = action.todDeals;
    if(action.skip !== null && action.skip > 0){
      toady_deals = toady_deals.concat(state.todDeals)
    }
    return {
       ...state,
       date:action.date,
       isLoading:false,
       index:action.index,
       todDeals: toady_deals,
       count: action.count
    };
   case Actions.TODAYS_DEALS_FAILURE:
    return {
       ...state,
       isLoading:false,
      index:action.index,
      error:action.error,
      count:0
    };


    case Actions.FANTASTIC_DEALS_LOADING:
      return {
          ...state,
          index:action.index,
          isLoading:true,
          count:0
      };
    case Actions.FANTASTIC_DEALS:
      var fantastic = action.fanDeals;
      if(action.skip !== null && action.skip > 0){
        fantastic = fantastic.concat(state.fanDeals)
      }
     return {
        ...state,
        date:action.date,
        index:action.index,
        isLoading:false,
        index:action.index,
        fanDeals: fantastic,
        count
     };
    case Actions.FANTASTIC_DEALS_FAILURE:
     return {
        ...state,
        index:action.index,
        isLoading:false,
        error:action.error,
        count:0
     };


   case Actions.STILL_BANNERS_LOADING:
     return {
        ...state,
       still_banner_loading:true,
     };
   case Actions.STILL_BANNERS:
    var still_banners = action.stillBanners;
    // if(action.skip !== null && action.skip > 0){
    //   still_banners = still_banners.concat(state.stillBanners)
    // }
    return {
       ...state,
       date:action.date,
       still_banner_loading:false,
       stillBanners: still_banners,
    };
   case Actions.STILL_BANNERS_FAILURE:
    return {
       ...state,
      still_banner_loading:false,
      error:action.error
    };



   case Actions.CATEGORY_OFFERS_LOADING:
     return {
        ...state,
        category_offers_loading:true,
     };
   case Actions.CATEGORY_OFFERS:
    var category_offers = action.catOffers;
    // if(action.skip !== null && action.skip > 0){
    //   category_offers = category_offers.concat(state.catOffers)
    // }
    return {
       ...state,
       date:action.date,
       category_offers_loading:false,
       catOffers: category_offers,
    };
   case Actions.CATEGORY_OFFERS_FAILURE:
    return {
       ...state,
      category_offers_loading:false,
      error:action.error
    };



    case Actions.MORE_CATEGORY_OFFERS_LOADING:
     return {
        ...state,
        more_category_offers_loading:true,
     };
   case Actions.MORE_CATEGORY_OFFERS:
    var category_offers = action.catOffers;
    if(action.skip !== null && action.skip > 0){
      category_offers = state.catOffers.concat(category_offers)
    }
    return {
       ...state,
       date:action.date,
       more_category_offers_loading:false,
       catOffers: category_offers,
    };
   case Actions.MORE_CATEGORY_OFFERS_FAILURE:
    return {
       ...state,
       more_category_offers_loading:false,
       error:action.error
    };

    


    case Actions.GET_CATEGORY_CHILDS_LOADING:
      var count = 0
      var products = []
      var categories = []
      if(action.skip !== null && action.skip > 0){
        count = state.count
        products = state.products
        categories = state.categories
      }
    return{
        ...state,
        isLoading:true,
        products:products,
        index:action.index,
        categories:categories,
        count:count
    }
  case Actions.GET_CATEGORY_CHILDS_CATEGORY:
    var child_cat = action.categories;
    if(action.skip !== null && action.skip > 0){
      child_cat = child_cat.concat(state.categories)
    }
    return{
        ...state,
        isLoading:false,
        categories:child_cat,
        products:[],
        isCategory: true,
        index:action.index,
        count:action.count,
        isProduct: action.isProduct
    }
case Actions.GET_CATEGORY_CHILDS_PRODUCTS:
    var child_pro = action.products;
    if(action.skip !== null && action.skip > 0){
      child_pro = state.products.concat(child_pro)
    }
    return{
        ...state,
        isLoading:false,
        products:child_pro,
        categories:[],
        count: action.count,
        index:action.index,
        isCategory: action.isCategory,
        isProduct: action.isProduct
    }
case Actions.GET_CATEGORY_CHILDS_FAILED:
    return{
        ...state,
        isLoading:false,
        index:action.index,
        error:action.error,
        products:[],
        categories:[],
        count:0
    }


case Actions.GET_FILTER_LOADING:
    return {
      ...state,
      loadingFilter:true,
      index:action.index,
      filterSuccess:false,
      products:[],
      isLoading:true,
      count: 0
    };
case Actions.GET_FILTER:
    var filter = action.result;
    // if(action.skip !== null && action.skip > 0){
    //   filter = filter.concat(state.filterProducts)
    // }
    return {
        ...state,
        loadingFilter:false,
        filterSuccess:false,
        products: filter,
        index:action.index,
        isLoading:false,
        count: action.count
    };

case Actions.GET_FILTER_FAILED:
    return {
      ...state,
      index:action.index,
      loadingFilter:false,
      isLoading:false,
      error:action.error
    };

    case Actions.MORE_GET_FILTER_LOADING:
    return {
      ...state,
      index:action.index,
      loadingFilterMore:true
    };
case Actions.MORE_GET_FILTER:
    var filter = action.result;
    // if(action.skip !== null && action.skip > 0){
      filter = state.products.concat(filter)
    // }
    return {
        ...state,
        loadingFilterMore:false,
        index:action.index,
        products: filter,
    };

case Actions.MORE_GET_FILTER_FAILED:
    return {
      ...state,
      index:action.index,
      loadingFilterMore:false,
      error:action.error
    };


  case Actions.LAST_LOAD_DATE:
    return {
      ...state,
      loadDate: action.date,
      loadCount: action.count
    };


case Actions.RESET_STORE:
    return {
        storeChanged:true
    };

  case Actions.STORE_RELOADED:
    return {
        ...state,
        storeChanged:false
    };

   default:
     return state

  }
}
