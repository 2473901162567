import * as Actions from '../constants/ActionTypes';
import GeneralService from '../services/GeneralServices'

function getFaqs(){
    return dispatch => {
        dispatch(request());
        GeneralService.getFaqs()
        .then((resp) => {
            var faqs = resp.data;
            dispatch(success(faqs));
        }).catch((error)=>{
            dispatch(failure(error));
        });
    
      };
    
      function request() { return { type: Actions.GET_FAQS_LOADING} }
      function success(faqs) { return { type: Actions.GET_FAQS_SUCCESS ,faqs:faqs} }
      function failure(error) { return { type: Actions.GET_FAQS_FAILED, error } }
}

function getAbout(){
    return dispatch => {
        dispatch(request());
        GeneralService.getAbout()
        .then((resp) => {
            var about = resp.data;
            dispatch(success(about));
        }).catch((error)=>{
            dispatch(failure(error));
        });
    
      };
    
      function request() { return { type: Actions.GET_ABOUT_LOADING} }
      function success(about) { return { type: Actions.GET_ABOUT_SUCCESS ,about:about} }
      function failure(error) { return { type: Actions.GET_ABOUT_FAILED, error } }
}

function getPointTerms(){
    return dispatch => {
        dispatch(request());
        GeneralService.getPointTerms()
        .then((resp) => {
            var pointterms = resp.data;
            dispatch(success(pointterms));
        }).catch((error)=>{
            dispatch(failure(error));
        });
    
      };
    
      function request() { return { type: Actions.GET_POINT_TERMS_LOADING} }
      function success(terms) { return { type: Actions.GET_POINT_TERMS_SUCCESS ,terms:terms} }
      function failure(error) { return { type: Actions.GET_POINT_TERMS_FAILED, error } }
}

function changePicture(data){
    return dispatch => {
        dispatch(request());
        GeneralService.changePicture(data)
        .then((resp) => {
            var user = resp.data;
            dispatch(success(user));
        }).catch((error)=>{
            dispatch(failure(error));
        });
    
      };
    
      function request() { return { type: Actions.CHANGE_PICTURE_LOADING} }
      function success(user) { return { type: Actions.CHANGE_PICTURE_SUCCESS ,user:user} }
      function failure(error) { return { type: Actions.CHANGE_PICTURE_FAILED, error } }
}

function sendResetOtp(data){
    return dispatch => {
        dispatch(request());
        GeneralService.sendResetOtp(data)
        .then((resp) => {
            var customer_id = resp.data;
            dispatch(success(customer_id));
        }).catch((error)=>{
            if(error.hasOwnProperty('data')){
                if(error.data.hasOwnProperty('message')){
                  dispatch(failure(error.data.message));
                }else{
                  dispatch(failure(error.data));
                }
            }else{
                dispatch(failure(error));
            }
            
        });
    
      };
    
      function request() { return { type: Actions.SEND_RESET_OTP_LOADING} }
      function success(customer_id) { return { type: Actions.SEND_RESET_OTP_SUCCESS, customer_id:customer_id } }
      function failure(error) { return { type: Actions.SEND_RESET_OTP_FAILED, error } }
}

function init(){
    return dispatch=>{
      dispatch(success());
    }
    function success() { return { type: Actions.INTIAL_STAGE } }
  }

  function initProfile(){
    return dispatch=>{
      dispatch(success());
    }
    function success() { return { type: Actions.INITIAL_PROFILE } }
  }

  function verifyResetOtp(otp,customer_id){
    return dispatch => {
        dispatch(request());
        GeneralService.verifyResetOtp(otp,customer_id)
        .then((resp) => {
            var otp = resp.data.otp;
            var customer_id  = resp.data.customer_id;
            dispatch(success(otp,customer_id));
        }).catch((error)=>{
            
            if(error.hasOwnProperty('data')){
                if(error.data.hasOwnProperty('message')){
                  dispatch(failure(error.data.message));
                }else{
                  dispatch(failure(error.data));
                }
            }else{
                dispatch(failure(error));
            }
            
        });
    
      };
    
      function request() { return { type: Actions.VERIFY_RESET_OTP_LOADING} }
      function success(otp,customer_id) { return { type: Actions.VERIFY_RESET_OTP_SUCCESS, customer_id:customer_id , otp:otp} }
      function failure(error) { return { type: Actions.VERIFY_RESET_OTP_FAILED, error } }
}

function resetPassword(data){
    return dispatch => {
        dispatch(request());
        GeneralService.resetPassword(data)
        .then((resp) => {
           
            dispatch(success());
        }).catch((error)=>{
            if(error.hasOwnProperty('data')){
                if(error.data.hasOwnProperty('message')){
                  dispatch(failure(error.data.message));
                }else{
                  dispatch(failure(error.data));
                }
            }else{
                dispatch(failure(error));
            }
            
        });
    
      };
    
      function request() { return { type: Actions.RESET_PASSWORD_LOADING} }
      function success() { return { type: Actions.RESET_PASSWORD_SUCCESS} }
      function failure(error) { return { type: Actions.RESET_PASSWORD_FAILED, error } }
}

function resetAll(){
    return dispatch => {
        dispatch(success());
    
    };

    function success() { return { type: Actions.RESET} }

}

function setLastLoadStatus(date, count){
    return dispatch => {
        dispatch(success(date, count));
    };
    function success(date, count) { return { type: Actions.LAST_LOAD_DATE, date, count} }
}


function getPrivacy(){
    return dispatch => {
        dispatch(request());
        GeneralService.getPrivacy()
        .then((resp) => {
            var pointterms = resp.data;
            dispatch(success(pointterms));
        }).catch((error)=>{
            dispatch(failure(error));
        });
    
      };
    
      function request() { return { type: Actions.GET_PRIVACY_LOADING} }
      function success(privacy) { return { type: Actions.GET_PRIVACY_SUCCESS, privacy} }
      function failure(error) { return { type: Actions.GET_PRIVACY_FAILED, error } }
}

function getSupportUrl(){
    return dispatch => {
      GeneralService.getSupportUrl()
      .then((resp) => {
        dispatch(success(resp.data));
      }).catch((error)=>{
      });
  
    };
  
    function success(data) { return { type: Actions.SUPPORT_URL, data} }
}
  



export{
    getFaqs,
    getAbout,
    getPointTerms,
    changePicture,
    sendResetOtp,
    init,
    verifyResetOtp,
    resetPassword,
    initProfile,
    resetAll,
    setLastLoadStatus,
    getPrivacy,
    getSupportUrl
}