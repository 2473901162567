import React, { Component } from 'react'
import { Accordion, Icon } from 'semantic-ui-react'

export default class FaqContent extends Component {
  state = { activeIndex: 0 }

  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
  }

  render() {
    const { activeIndex } = this.state
    const { items } = this.props

    if(!items || items.length <  1) return null

    return (
      <Accordion fluid styled>
        {items.map((item, index)=>(
          <div>
            <Accordion.Title
              active={activeIndex === index}
              index={index}
              onClick={this.handleClick}
            >
              <Icon name='dropdown' />
              {item.title}
            </Accordion.Title>
            <Accordion.Content active={activeIndex === index}>
              <p>
                {item.content}
              </p>
            </Accordion.Content>
          </div>
        ))}
        
      </Accordion>
    )
  }
}