import * as Actions from '../constants/ActionTypes';

const initialState ={
    faqs:[],
    loading:false,
    aboutLoading:false,
    about:{},
    terms:{},
    pointTermsLoading:false,
    privacy: {},
    support: {}
}

export default function faqReducer(state = initialState , action){
    switch(action.type){
       

        case Actions.GET_FAQS_LOADING:
            return{
                ...state,
                loading:true
            }
        case Actions.GET_FAQS_SUCCESS:
            
            return{
                ...state,
                loading:false,
                faqs: action.faqs
            }
        case Actions.GET_FAQS_FAILED:
            return{
                ...state,
                loading:false,

            }

        case Actions.GET_ABOUT_LOADING:
            return{
                ...state,
                aboutLoading:true
            }
        case Actions.GET_ABOUT_SUCCESS:
            
            return{
                ...state,
                aboutLoading:false,
                about: action.about
            }
        case Actions.GET_ABOUT_FAILED:
            return{
                ...state,
                aboutLoading:false,

            }

        case Actions.GET_POINT_TERMS_LOADING:
            return{
                ...state,
                pointTermsLoading:true
            }
        case Actions.GET_POINT_TERMS_SUCCESS:
            
            return{
                ...state,
                pointTermsLoading:false,
                terms: action.terms
            }
        case Actions.GET_POINT_TERMS_FAILED:
            return{
                ...state,
                pointTermsLoading:false,

            }

            case Actions.GET_PRIVACY_LOADING:
                return{
                    ...state,
                    pointTermsLoading:true
                }
            case Actions.GET_PRIVACY_SUCCESS:
                
                return{
                    ...state,
                    pointTermsLoading:false,
                    privacy: action.privacy
                }
            case Actions.GET_PRIVACY_FAILED:
                return{
                    ...state,
                    pointTermsLoading:false,
    
                }

            case Actions.SUPPORT_URL:
                return{
                    ...state,
                    support: action.data
                }   
            
        default:
        return state
       
    }
}