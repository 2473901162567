import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import reducers from "../reducers";
import logger from "redux-logger";
import { persistStore } from 'redux-persist'

export default () => {
    let store = createStore(reducers,applyMiddleware(thunk))
    let persistor = persistStore(store)
    return { store, persistor }
  }