import React from 'react'
import { connect } from 'react-redux'
import { getStatements } from '../../actions/user'
import FullWidth from '../../components/basic/FullWidth'
import ActivityIndicator from '../../components/loaders/ActivityIndicator'

class PointStatements extends React.Component{

    state = { skip:0, take:20}

    componentDidMount(){
        const { id } =  this.props.match.params
        this.props.onGetStatements(id,this.state.skip,this.state.take);
    }

    render(){
        const { statements, loading } = this.props

        if(loading && (!statements || statements.length<1)){
            return <FullWidth>
                <ActivityIndicator/>
            </FullWidth>
        }

        if(!statements || statements.length<1){
            return <FullWidth SectionClass="white-wrapper">
                <div className="body-content">
                    <div className="sec-title">
                        <h3>You have no point statements yet</h3>
                    </div>
                </div>
            </FullWidth>
        }

        return(
            <React.Fragment>
                <FullWidth SectionClass="home-slider pb-50">
                    <div className="row">
                    {statements.map((statement, index)=>(
                        <div className="col-md-12" key={index}>
                            <div className="note-box">
                                <div className="action-area">
                                    <div className="large-text">{statement.bill_no} {statement.store? '@ ' +statement.store:null}</div>
                                    <div className="date">
                                        {statement.tr_date}
                                    </div>
                                </div>
                                <div className="grey-text">
                                    <p>{statement.redeemed_points}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                    </div>
                </FullWidth>  
            </React.Fragment>
           
        )
    }
}

const mapStateToProps = state =>({
    statements : state.pointReducer.statements,
    loading : state.pointReducer.stLoading
})

const mapDispatchToProps = dispatch =>({
    onGetStatements : (ac_id,skip,take)=>{
        dispatch(getStatements(ac_id,skip,take))
    }
})
 
export default connect(mapStateToProps,mapDispatchToProps)(PointStatements);