import React, { Component } from 'react';
import { connect } from 'react-redux';
import Geocode from "react-geocode";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import {Link, withRouter} from 'react-router-dom';
import { Dropdown, Menu,Image,Icon } from 'semantic-ui-react';
import { search } from '../../actions/search';
import { getStores, reset, setStore } from '../../actions/store';
import { changeSelectedLocation, logout, setCustomerStore } from '../../actions/user';
import LOGO from '../../assets/images/logo.svg';
import SearchBar from './Search';
import CategoryMenu from './CategoryMenu'
import ADrawer from '../basic/ADrawer';

class NavBar extends Component {

    state = {
        show:false,
        openSearch: false
    }

    onSelectSearch = (product)=>{
        if(product && product.id){
            this.props.history.push('/product/'+product.id)
        }
    }

    onSetStore = (store)=>{
        this.props.onSetStore(store);
        this.props.onReset();
        this.props.history.push('/')
    }

    onSearch = (query)=>{
        const { store, onSearch } = this.props
        var store_id = store ? store.id: null
        onSearch(query, 0, 10, store_id);
    }

    showSearch = (e) => {
        e.preventDefault();
        this.setState({show:!this.state.show});
    }

    onLogoutClick = ()=>{
        this.props.onLogout()
    }

    componentDidMount(){
        this.props.onGetStores()
        Geocode.setApiKey("AIzaSyDgUp0KhdiBj2YGjqiKolW7EARPVAaC-mQ");
        setTimeout(()=>{
            if(!this.props.location){
                navigator.geolocation.getCurrentPosition(
                    position =>{
                        Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then((res)=>{
                            var villa = res.results[0].address_components[0].short_name
                            var title = res.results[0].formatted_address
                            var latitude = res.results[0].geometry.location.lat
                            var longitude = res.results[0].geometry.location.lng
                            var loc = {
                                villa,
                                title,
                                latitude,
                                longitude
                            }
                            this.props.onSetLocation(loc)
                            this.setState({openSearch: false})
                        }).catch((e)=>{
                        })
                    }, 
                    err => {
                        if(!this.props.store){
                            this.setState({openSearch: true})
                        }
                    }
                );
            }
        }, 100)
        
    }

    render() { 
        const {sticky,stores, store, loading, results, cart, loggedIn, user, location } = this.props

        return ( 
            <div className="topbar">
                    <div className="container">
                        <div className="flex-menu">
                            <div className="left-col">
                                <Menu.Item name='user'  className="logo-img">
                                      <Link to="/"> <Image avatar src={LOGO} /></Link>
                                </Menu.Item>
                                <div className="selected" onClick={()=>this.setState({openSearch: true})}>
                                    <span className="d-block">{store ? store.title : 'Please Switch Location'}</span>
                                    <span  className="d-block str-name">{store?store.street:location?location.villa:'tap to switch location'}</span>
                                </div>
                                <Icon name='angle down' /> 
                                {/* <Dropdown 
                                    trigger={
                                        <div className="selected">
                                            <Image avatar src={LOGO} /> 
                                            <span className="d-block">{store ? store.title : 'Please choose a store'}</span>
                                            <span  className="d-block str-name">{store?store.street:''}</span>
                                            <Icon name='angle down' /> 
                                        </div>
                                    }>
                                    <Dropdown.Menu>
                                        {stores && stores.map((st, index)=>(
                                            <Dropdown.Item onClick={()=>this.onSetStore(st)} key={index}>
                                                <span className="d-block">{st.title}</span>
                                                <span className="d-block str-name">{st.street}</span>
                                            </Dropdown.Item>
                                        ))}
                                        <Dropdown.Item onClick={()=>this.onSetStore(st)} key={index}>
                                            <span className="d-block">Search</span>
                                            <span className="d-block str-name">{st.street}</span>
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown> */}
                            </div>
                            <div className={this.state.show? 'search-col view-search' : 'search-col'}>
                                <SearchBar 
                                    onSelect={this.onSelectSearch}
                                    onSearch={this.onSearch} 
                                    loading={loading} 
                                    results={results}/>
                            </div>
                            <div className="right-col">
                                <Menu>
                                    {(loggedIn && user)?
                                        <Menu.Item>
                                            <Dropdown text={user.first_name}>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item text={user.first_name}>
                                                        <Link to="/my-account">Profile</Link>
                                                    </Dropdown.Item>
                                                    <Dropdown.Item text='Logout' onClick={this.onLogoutClick}/>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Menu.Item>:
                                        <Menu.Item name='user'>
                                            <Icon name='user' /> <Link to="/login">Login</Link>
                                        </Menu.Item>
                                    }
                                    <Menu.Item  name='cart'>
                                        <Link to="/cart" className="hide-text"><Icon name='cart'/> <span>Cart</span></Link>
                                        {(cart && cart.length > 0)?<span className="badge">{cart.length}</span>:null } 
                                    </Menu.Item>
                                    <Menu.Item name='search' className="search-mob" onClick={this.showSearch}>
                                    <Icon name='search'/>
                                  </Menu.Item>
                                </Menu>
                            </div>
                        </div>
                        <ADrawer open={this.state.openSearch} onClose={()=>this.setState({openSearch: false})}>
                            <div style={{justifyContent:'center', padding: 20, marginTop: 30}}>
                                <GooglePlacesAutocomplete
                                    apiKey="AIzaSyDgUp0KhdiBj2YGjqiKolW7EARPVAaC-mQ"
                                    selectProps={{
                                        placeholder:"search place, street",
                                        styles:{
                                            
                                        },
                                        onChange: (v, a)=>{
                                            Geocode.fromAddress(v.label).then((res)=>{
                                                var villa = res.results[0].address_components[0].short_name
                                                var title = res.results[0].formatted_address
                                                var latitude = res.results[0].geometry.location.lat
                                                var longitude = res.results[0].geometry.location.lng
                                                var loc = {
                                                    villa,
                                                    title,
                                                    latitude,
                                                    longitude
                                                }
                                                this.props.onSetLocation(loc)
                                                this.setState({openSearch: false})
                                            }).catch((e)=>{

                                            })
                                        },
                                    }}
                                    />
                            </div>
                        </ADrawer>
                        {/* <CategoryMenu/> */}
                    </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    results : state.searchReducer.result,
    loading:state.searchReducer.isLoading,
    stores : state.storeReducer.stores,
    store: state.storeReducer.store,
    cart: state.cartReducer.cart,
    reflector: state.cartReducer.reflector,
    loggedIn: state.userReducer.loggedIn,
    user:state.userReducer.user,
    location: state.userReducer.location
});
const mapDispatchToProps = dispatch => ({
    onGetStores: () => {
        dispatch(getStores());
    },
    onSetStore: (store) => {
        dispatch(setStore(store));
    },
    onReset:()=>{
        dispatch(reset())
    },
    onChangeStore:(data)=>{
        dispatch(setCustomerStore(data))
    },
    onSearch:(query, skip, take, store)=>{
        dispatch(search(query, skip, take, store));
    },
    onLogout: () => {
        dispatch(logout());
    },
    onSetLocation:(location)=>{
        dispatch(changeSelectedLocation(location))
    }
});
const NavBarContainer = connect(
                        mapStateToProps,
                        mapDispatchToProps)
                        (NavBar);
export default withRouter(NavBarContainer);

