import React from 'react'
import { Img } from 'react-image'
import ActivityIndicator from '../loaders/ActivityIndicator';
import { LazyLoadImage } from 'react-lazy-load-image-component';

function AImage({
    src,
    ...props
}){

    return(
        <LazyLoadImage
            src={src}
            placeholder={ <ActivityIndicator type="circle"/>}
            {...props}/>
           
    )
}

export default AImage