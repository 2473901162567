import * as Actions from '../constants/ActionTypes';

const initialState ={

    loading:false,
    values:{}
}

export default function filterValuesReducer(state = initialState , action){
    switch(action.type){
       

        case Actions.GET_FILTER_VALUES_LOADING:
            return{
                loading:true
            }
        case Actions.GET_FILTER_VALUES_SUCCESS:
            
            return{

                loading:false,
                values: action.data
            }
        case Actions.GET_FILTER_VALUES_FAILED:
            return{
                loading:false
            }

            
        default:
        return state
       
    }
}