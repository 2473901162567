import * as Actions from '../constants/ActionTypes';
import UserService from '../services/UserServices';
import * as constants from '../constants/AppConstants'

function intial(){
  return dispatch=>{
    dispatch(success());
  }
  function success() { return { type: Actions.INTIAL_STAGE } }
}

function intialSignup(){
  return dispatch=>{
    dispatch(success());
  }
  function success() { return { type: Actions.INTIAL_STAGE_SIGNUP } }
}

function login(mobile, password) {
    return dispatch => {
        dispatch(request());
        UserService.login(mobile, password)
        .then((resp) => {
          var user = resp.data.user;
          if(parseInt(user.status) === constants.USER_NOT_ACTIVATED){
            dispatch(unverified(user))
          }else{
            var token = resp.data.token;
            localStorage.setItem('fmart_token', token)
            dispatch(success(user,token));
          }
        }).catch((error)=>{
            if(error.hasOwnProperty('data')){
              if(error.data.hasOwnProperty('message')){
                dispatch(failure(error.data.message));
              }else{
                dispatch(failure(error.data));
              }
            }else{
              dispatch(failure(error));
            }

        });
    };
    function request() { return { type: Actions.LOGIN_REQUEST } }
    function unverified(user) { return { type: Actions.LOGIN_SUCCESS_NOT_VERIFIED,user } }
    function success(user,token) { return { type: Actions.LOGIN_SUCCESS, user:user,token:token } }
    function failure(error) { return { type: Actions.LOGIN_FAILURE, error } }
}


function register(mobile, password,c_password,first_name,second_name,email) {
    return dispatch => {
      dispatch(request());
      UserService.register(mobile, password,c_password,first_name,second_name,email)
      .then((resp) => {
        var user = resp.data.user;
        dispatch(success(user));

      }).catch((error)=>{
        if(error.hasOwnProperty('data')){
          if(error.data.hasOwnProperty('message')){
            dispatch(failure(error.data.message));
          }else{
            dispatch(failure(error.data));
          }
        }else{
          dispatch(failure(error));
        }
      });

    };

    function request() { return { type: Actions.SIGNUP_REQUEST } }
    function success(user) { return { type: Actions.SIGNUP_SUCCESS, user:user} }
    function failure(error) { return { type: Actions.SIGNUP_FAILURE, error } }
}

function getPoints(customer_id) {
  return dispatch => {
    dispatch(request());
    UserService.getPoints(customer_id)
    .then((resp) => {
      var points = resp.data;
      dispatch(success(points));

    }).catch((error)=>{
      dispatch(failure(error));
    });

  };

  function request() { return { type: Actions.GET_POINTS_LOADING } }
  function success(points) { return { type: Actions.GET_POINTS_SUCCESS, points:points } }
  function failure(error) { return { type: Actions.GET_POINTS_FAILED, error } }
}

function getStatements(ac_id, skip=null, take=null) {
  return dispatch => {
    dispatch(request());
    UserService.getStatements(ac_id,skip,take)
    .then((resp) => {
      var statements = resp.data;
      dispatch(success(statements));

    }).catch((error)=>{
      dispatch(failure(error));
    });

  };

  function request() { return { type: Actions.GET_STATEMENTS_LOADING } }
  function success(statements) { return { type: Actions.GET_STATEMENTS_SUCCESS, statements:statements } }
  function failure(error) { return { type: Actions.GET_STATEMENTS_FAILED, error } }
}

function removeSnackBar(){
  return dispatch => {
    dispatch(success())
  };
  function success() { return { type: Actions.REMOVE_SNACKBAR }};
}

function removeVerifySnackBar(){
  return dispatch => {
    dispatch(success())
  };
  function success() { return { type: Actions.REMOVE_VERIFY_SNACK }};
}

function removeSnackBarSignup(){
  return dispatch => {
    dispatch(success())
  };
  function success() { return { type: Actions.REMOVE_SNACKBAR_SIGNUP }};
}

function logout(){
  return dispatch => {
    dispatch(success())
  }
  function success() { return { type: Actions.LOGOUT } }
}

function getNotifications(customer_id,skip,take){
  return dispatch => {
    dispatch(request());
    UserService.getNotifications(customer_id,skip,take)
    .then((resp) => {
      var notifications = resp.data;
      var count = resp.count;
      dispatch(success(notifications, count, skip));

    }).catch((error)=>{
        dispatch(failure(error));

    });
};
  function request() { return { type: Actions.GET_NOTIFICATION_LOADING } }
  function success(notifications, count, skip) { return { type: Actions.GET_NOTIFICATION, notifications, count, skip } }
  function failure(error) { return { type: Actions.GET_NOTIFICATION_FAILED, error } }
  
}

function sendOTP(customer_id, device_id){
  return dispatch => {
    dispatch(request());
    UserService.sendOTP(customer_id, device_id)
    .then((resp) => {

      dispatch(success());

    }).catch((error)=>{
        dispatch(failure(error));

    });
};
  function request() { return { type: Actions.SEND_OTP_LOADING } }
  function success() { return { type: Actions.SEND_OTP_SUCCESS } }
  function failure(error) { return { type: Actions.SEND_OTP_FAILED, error } }
  
}

function verifyOTP(customer_id,otp){
  return dispatch => {
    dispatch(request());
    UserService.verifyOTP(customer_id,otp)
    .then((resp) => {
      var user = resp.data.user;
      var token = resp.data.token;
      dispatch(success(user,token));

    }).catch((error)=>{
      if(error.hasOwnProperty('data')){
        if(error.data.hasOwnProperty('message')){
          dispatch(failure(error.data.message));
        }else{
          dispatch(failure(error.data));
        }
      }else{
        dispatch(failure(error));
      }

    });
};
  function request() { return { type: Actions.VERIFY_OTP_LOADING } }
  function success(user,token) { return { type: Actions.VERIFY_OTP_SUCCESS, user:user, token:token } }
  function failure(error) { return { type: Actions.VERIFY_OTP_FAILED, error } }
  
}

function changePassword(data){
  return dispatch => {
    dispatch(request());
    UserService.changePassword(data)
    .then((resp) => {

      dispatch(success());

    }).catch((error)=>{
        dispatch(failure(error));

    });
};
  function request() { return { type: Actions.CHANGE_PASSWORD_LOADING } }
  function success() { return { type: Actions.CHANGE_PASSWORD_SUCCESS } }
  function failure(error) { return { type: Actions.CHANGE_PASSWORD_FAILED, error } }
  
}

function getUnreadNotificationCount(customer_id){
  return dispatch => {
    dispatch(request());
    UserService.getUnreadNotificationCount(customer_id)
    .then((resp) => {
      var count = resp.count;
      dispatch(success(count));

    }).catch((error)=>{
        dispatch(failure(error));

    });
};
  function request() { return { type: Actions.GET_NOTIFICATION_COUNT } }
  function success(count) { return { type: Actions.GET_NOTIFICATION_COUNT_SUCCESS, count } }
  function failure(error) { return { type: Actions.GET_NOTIFICATION_COUNT_FAILED, error } }
  
}

function registerToken(data){
  return dispatch => {
    dispatch(request());
    UserService.registerToken(data)
    .then((resp) => {

      dispatch(request());
    }).catch((error)=>{

      dispatch(request());
    });
  } 

  function request() { return { type: Actions.REGISTER_TOKEN  } }
 
}

function lastLogged(data){
  return dispatch => {
    dispatch(request());
    UserService.lastLoged(data)
    .then((resp) => {
      dispatch(request());
    }).catch((error)=>{

      dispatch(request());
    });
  } 

  function request() { return {type: Actions.LAST_LOGIN  } }
}

function setCustomerStore(data){
  return dispatch => {
    dispatch(request());
    UserService.setCustomerStore(data)
    .then((resp) => {

      dispatch(request());
    }).catch((error)=>{

      dispatch(request());
    });
    function request() { return { type: Actions.SET_CUSTOMER_STORE  } }
  } 
}

function changeNotificationStatus(customer_id){
  return dispatch => {
    dispatch(request());
    UserService.changeNotificationStatus(customer_id)
    .then((resp) => {

      dispatch(request());
    }).catch((error)=>{

      dispatch(request());
    });
    function request() { return { type: Actions.CHANGE_NOTIFICATION_STATUS  } }
  } 
}

function addLocation(data){
  return dispatch => {
    dispatch(request());
    UserService.addLocation(data)
    .then((resp) => {
      var location = resp.data
      dispatch(success(location));
    }).catch((error)=>{
      dispatch(failure(error));
    });
    function request() { return { type: Actions.ADD_LOCATION_LOADING } }
    function success(location) { return { type: Actions.ADD_LOCATION , location} }
    function failure(error) { return { type: Actions.ADD_LOCATION_FAILED, error } }
  } 
}

function getLocations(customer_id){
  return dispatch => {
    dispatch(request());
    UserService.getLocations(customer_id)
    .then((resp) => {
      var locations = resp.data;
      dispatch(success(locations));
    }).catch((error)=>{
      dispatch(failure(error));
    });
    function request() { return { type: Actions.GET_LOCATIONS_LOADING } }
    function success(locations) { return { type: Actions.GET_LOCATIONS, locations } }
    function failure(error) { return { type: Actions.GET_LOCATIONS_FAILED, error } }
  } 
}

function getSavedCards(customer_id){
  return dispatch => {
    dispatch(request());
    UserService.getSavedCards(customer_id)
    .then((resp) => {
      var cards = resp.data;
      dispatch(success(cards));
    }).catch((error)=>{
      dispatch(failure(error));
    });
    function request() { return { type: Actions.GET_SAVED_CARDS_LOADING } }
    function success(cards) { return { type: Actions.GET_SAVED_CARDS, cards } }
    function failure(error) { return { type: Actions.GET_SAVED_CARDS_FAILED, error } }
  } 
}

function changeSelectedLocation(location){
  return dispatch => {
    dispatch(success(location));
    
    function success(location) { return { type: Actions.SET_DEFAULT_LOCATION,  location } }
  } 
}







export {
  login,
  register,
  removeSnackBar,
  logout,
  intial,
  intialSignup,
  removeSnackBarSignup,
  getNotifications,
  getPoints,
  getStatements,
  sendOTP,
  verifyOTP,
  removeVerifySnackBar,
  changePassword,
  getUnreadNotificationCount,
  registerToken,
  lastLogged,
  setCustomerStore,
  changeNotificationStatus,
  addLocation,
  getLocations,
  changeSelectedLocation,
  getSavedCards
};
