import React from 'react'
import { connect } from 'react-redux';

function InvoiceCard({
    cart,
    full_amount,
    vat,
    total,
    loggedIn,
    user,
    buttonText,
    onPress,
    store,
    discount,
    cost
}) {

    var offer_value = 0
    if(discount>0){
        offer_value = (full_amount * discount)/100
    }


    return (
        <div className="price-bord">
            <table className="ui celled fixed single line table">
                <tbody>
                    <tr>
                        <td className="light-text">Taxable amount ({cart.length} items)</td>
                        <td className="">{store && store.currency.symbol} {(full_amount - vat).toFixed(2)} </td>
                    </tr>
                    <tr>
                        <td className="light-text">Tax amount</td>
                        <td className="">{store && store.currency.symbol} {vat}</td>
                    </tr>
                    <tr>
                        <td className="light-text">Bill amount</td>
                        <td className="">{store && store.currency.symbol} {full_amount}</td>
                    </tr>
                    {/* <tr>
                        <td className="light-text">Offer amount</td>
                        <td className="">{store && store.currency.symbol} {discount}</td>
                    </tr> */}
                    <tr>
                        <td className="light-text">Cart offer</td>
                        <td className="">{store && store.currency.symbol} {offer_value}</td>
                    </tr>
                    <tr>
                        <td  className="light-text">Delivery Fee</td>
                        {!cost?<td className="free">Free</td>:
                        <td className="">{store && store.currency.symbol} {cost}</td>}
                    </tr>
                </tbody>
            </table>
            <table className="ui celled fixed single line table btop">
                   <tr>
                        <td className="final">To pay</td>
                        {cost ? <td className="final">{store? store.currency.symbol:''} {parseFloat(total) + parseFloat(cost)}</td>:
                            <td className="final">{store? store.currency.symbol:''} {total}</td>
                            }   
                    </tr>
                    {/* <tr>
                        <td  className="light-text free">Total Savings</td>
                        <td className="free">{store.currency.symbol} 200</td>
                    </tr> */}
                </table>
            {onPress ? 
                <button 
                    className="yellow-btn" 
                    onClick={onPress}>
                        {(loggedIn && user)? buttonText:'PLEASE LOGIN'}
                </button>:null
            }
        </div>
    )
}

const mapStateToProps = state => ({
    cart: state.cartReducer.cart,
    total : state.cartReducer.total,
    offer: state.cartReducer.offer,
    full_amount: state.cartReducer.full_amount,
    vat: state.cartReducer.vat,
    store: state.storeReducer.store,
    loggedIn : state.userReducer.loggedIn,
    user: state.userReducer.user,
    discount: state.cartReducer.discount,
    cost: state.cartReducer.cost
});
const mapDispatchToProps = dispatch => ({

});
export default connect(mapStateToProps,
                        mapDispatchToProps)
                        (InvoiceCard);