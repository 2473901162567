import React, { Component } from 'react';
import Slider from "react-slick";
import { tConv24 } from '../../utils';
import ActivityIndicator from '../loaders/ActivityIndicator';
const SETTINGS = {
  dots: false,
  arrows: false,
  infinite: false,
  speed: 500,
  slidesToShow: 8,
  slidesToScroll: 8,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 6,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4
      }
    },
    {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      }
  ]
};

class ScrollingTime extends Component {

  render() { 
    const { slots, selected, loading, onChange } = this.props
    if(loading) return <ActivityIndicator/>
    if(!slots || slots.length<1) return null
    return ( 
        <div className="slot-picker">
          <Slider {...SETTINGS}>
            {slots.map((slot, index)=>(
              <div key={index} onClick={()=>onChange(index, slot)}>
                <button className={selected===index?"slot-btn active":"slot-btn"}>{tConv24(slot.from, true)} - {tConv24(slot.to)}</button>
              </div>
            ))}
          </Slider>
        </div>
    );
  }
}
 
export default ScrollingTime;