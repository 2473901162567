import React from 'react'
import { connect } from 'react-redux'
import { getAbout } from '../../actions/general'
import FullWidth from '../../components/basic/FullWidth'
import ActivityIndicator from '../../components/loaders/ActivityIndicator'

class About extends React.Component{

    componentDidMount(){
        this.props.onGetAbout()
    }

    render(){
        const { loading, about } = this.props

        if(loading && (!about)){
            return <FullWidth>
                <ActivityIndicator/>
            </FullWidth>
        }

        if(!about || !about.content){
            return <FullWidth SectionClass="white-wrapper">
                <div className="body-content">
                <div className="sec-title">
                     <h2>About Us</h2>
                     </div>
                    <p>No about Found</p>
                </div>
            </FullWidth>
        }

        return(
            <FullWidth SectionClass="white-wrapper">
                <div className="body-content">
                     <div className="sec-title">
                     <h2>About Us</h2>
                     </div>   
                    <p style={{whiteSpace:"pre-line"}}>{about.content}</p>
                </div>
            </FullWidth>
        )
    }
}

const mapStateToProps = state=>({
    loading: state.faqReducer.aboutLoading,
    about:state.faqReducer.about,
})

const mapDispatchToProps = dispatch=>({
    onGetAbout:()=>{
        dispatch(getAbout())
    }
})
 
export default connect(mapStateToProps,mapDispatchToProps)(About);
