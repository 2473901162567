import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import { connect } from 'react-redux';

import './Login.css';
import ActivityIndicator from '../../components/loaders/ActivityIndicator';
import AInput from '../../components/basic/AInput';
import FormWrap from './FormWrap';
import { resetPassword } from '../../actions/general';

class ResetPassword extends Component {
  constructor(props){
    super(props);
    this.state = {
        new_password : '',
        c_password :''
    }
    this.validator = new SimpleReactValidator();
  }

  onChangeText = (e)=>{
    this.setState({[e.target.name]: e.target.value})
  } 

  onResetPass(e)  {

    e.preventDefault();
    if( this.validator.allValid() && this.state.new_password === this.state.c_password  ){
      const id = this.props.match.params.id
      const otp = this.props.match.params.otp
      var data = {}
      data['new_password'] = this.state.password;
      data['c_password'] = this.state.c_password;
      data['customer_id'] = id;
      data['otp'] = otp;
      this.props.onChangePassword(data);
    }
    else {
      this.validator.showMessages();
      this.forceUpdate();
    } 
  } 


  render() {
    const {c_password, password} = this.state
    return(
      <FormWrap>
        <ActivityIndicator fullScreen loading={this.props.loading}/>
        <form className="form-vertical">
          <AInput
            onChange={this.onChangeText}
            name="password"
            value={password}
            type="password"
            validator={this.validator}
            rules='required|min:6'
            isPassword={true}
            placeholder="Password"/>
          <AInput
            onChange={this.onChangeText}
            type="password"
            name="c_password"
            value={c_password}
            validator={this.validator}
            rules='required'
            isPassword={true}
            placeholder="Confirm Password"/>
      
          <div className="bt-wrapper">
            <button 
              style={{width:'100%'}}
              onClick ={this.onResetPass} 
              className="waves-effect waves-light btn">UPDATE</button>
          </div>
          <div className="signup-here text-small">
            <Link className="d-block large-text" to="/login">LOGIN</Link>
          </div>
        </form>
      </FormWrap>
    )
  }
}

ResetPassword.deaultProps = {
  error:[],
  otp_res:{}  
}
const mapStateToProps = (state) => ({
  success:state.resetPasswordReducer.resetSuccess,
  loading:state.resetPasswordReducer.resetLoading,
  error: state.resetPasswordReducer.resetError,
  errorMessage:state.resetPasswordReducer.resetErrorMessage,
})

const mapDispatchToProps = (dispatch)=> ({
  onChangePassword:(data)=>{
    dispatch(resetPassword(data))
  }
})
export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)




