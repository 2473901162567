import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { connect } from 'react-redux';
import CategorySlider from './CategorySlider'
import FullWidth from '../../components/basic/FullWidth';
import ProductsSlider from './ProductsSlider';
import { getDashboard } from '../../actions/home';
import { getCategories } from '../../actions/category';
import HomeLoader from '../../components/loaders/HomeLoader';
import SlidingBanner from './SlidingBanner';
import { setLastLoadStatus } from '../../actions/general';
import { getNearestStore, setStore, getDeliveryMods } from '../../actions/store';
import AImage from '../../components/basic/AImage';
import banner from '../../assets/images/STILLBANNER.jpg';
import CategoryMenu from '../../components/header/CategoryMenu';
import CategoryProductsContainer from '../category/CategoryProducts';
import empty from '../../assets/images/cart empty.svg';

class HomePage extends Component {

    state = { store: null, activeTab: 'home', currentLocation: null }

    onShowAll = (type)=>{
        this.props.history.push('/products/'+type)
    }

    componentDidMount(){
        setTimeout(()=>{
            const {store, location} = this.props
            this.props.onSetLoadDate()
            if(store && location){
                var data = {}
                data['latitude'] = location.latitude;
                data['longitude'] = location.longitude;
                data['store_id'] = store.id;
                this.props.onGetNearestStore(data);
                this.onLoad()
                this.setState({currentLocation: location})
            }
        }, 100)
    }

    onLoad = ()=>{
        setTimeout(()=>{
            if(this.props.store){
                this.props.onGetDashboard(this.props.store.id,0,15);
                this.props.onGetCategories(this.props.store.id,0,50);
                if(this.props.location){
                    this.props.onGetDeliveryMods(
                        this.props.store.id,
                        this.props.location.latitude,
                        this.props.location.longitude
                    )
                }
            }
        }, 100)
    }

    componentDidUpdate(){
        if((this.props.store && !this.state.store) || 
            (this.props.store && this.state.store && this.props.store.id != this.state.store.id)){
                this.onLoad()
                this.setState({store: this.props.store})
        }
        if(this.props.stores && !this.state.store && this.props.stores.length>0){
            var store = this.props.stores[0]
            this.onLoad()
            this.setState({store: store})
            if(!this.props.store) this.props.onSetStore(store)
        }

        if((this.props.location && !this.state.currentLocation) 
        || (this.props.location && this.state.currentLocation && this.props.location.villa != this.state.currentLocation.villa)){
            var data = {}
            data['latitude'] = this.props.location.latitude;
            data['longitude'] =  this.props.location.longitude;
            data['store_id'] = null;
            this.props.onGetNearestStore(data);
            this.setState({currentLocation: this.props.location})
        }
    }

    render() { 
        const {
            sliders, 
            stillBanners, 
            categories, 
            todayDeals, 
            fantasticDeals, 
            loading,
            is_loading,
            store
        } = this.props

        if(!store){
            return  (
                <React.Fragment>
                    <FullWidth SectionClass="home-slider pb-30">
                        <div className="empty-product" style={{marginTop:50}}>
                            <img src={empty} alt="wild harbour" className="img-fluid"/>
                            <h3>No Store Found</h3>
                            <h5>store not found on selected location</h5>
                        </div>
                    </FullWidth>  
                </React.Fragment>
            )
        }

        if((loading || is_loading) && (!sliders || sliders.length<1) ){
            return  (
                <React.Fragment>
                    <FullWidth SectionClass="home-slider pb-30">
                        <HomeLoader/>
                    </FullWidth>  
                </React.Fragment>
            )
        }

        if(this.state.activeTab != 'home'){
            return (
                <React.Fragment>
                    <div style={{backgroundColor:'white'}}>
                        <FullWidth SectionClass="product-slider">
                            <CategoryMenu 
                                activeTab={this.state.activeTab} 
                                categories={categories}
                                onChangeTab={(tab)=>this.setState({activeTab: tab})}/>
                        </FullWidth>
                    </div>
                    <CategoryProductsContainer id={this.state.activeTab} history={this.props.history}/>
                </React.Fragment>
            )
        }

        return ( 
            <React.Fragment>
                <div style={{backgroundColor:'white'}}>
                    <FullWidth SectionClass="product-slider">
                        <CategoryMenu 
                            activeTab={this.state.activeTab} 
                            categories={categories}
                            onChangeTab={(tab)=>this.setState({activeTab: tab})}/>
                    </FullWidth>
                </div>
                {(sliders && sliders.length > 0) ?
                    <FullWidth SectionClass="home-slider pb-30">
                        <SlidingBanner sliders={sliders} history={this.props.history}/>
                    </FullWidth> : null 
                }
                {(todayDeals && todayDeals.length > 0 ) &&
                    <FullWidth SectionClass="product-slider pb-30">
                        <div className="flex-title mb-30">
                            <div className="left-col">
                                <div className="experience-text">
                                    <h2>Top Picks</h2>
                                </div>
                            </div>
                            <div className="right-col">
                                <Link onClick={()=>this.onShowAll('today')}>Show All</Link>
                            </div>
                        </div>
                        <ProductsSlider list={todayDeals} history={this.props.history}/>    
                    </FullWidth>
                }
                {stillBanners && stillBanners.map((banner, index)=>(
                    <FullWidth SectionClass="BannerWrapper pb-30" key={index}>
                        <div className="banner-home" onClick={()=>this.props.history.push('/products/slide/'+banner.id)}>
                            <AImage src={banner.web_image} alt="wild harbour" className="img-fluid"/>
                        </div>
                    </FullWidth>  
                ))}
                {/* two banner */}
                    {/* <FullWidth SectionClass="BannerWrapper pb-30">
                        <div className="banner-images">
                            <div className="row">
                                <div className="col-md-6">
                                <AImage src={banner} alt="wild harbour" className="img-fluid"/>
                                </div>
                                <div className="col-md-6">
                                <AImage src={banner} alt="wild harbour" className="img-fluid"/>
                                </div>
                            </div>
                        </div>
                    </FullWidth>   */}

                {(fantasticDeals && fantasticDeals.length > 0 ) &&
                    <FullWidth SectionClass="product-slider pb-30">
                        <div className="flex-title mb-30">
                            <div className="left-col">
                                <div className="experience-text">
                                    <h2>Favourite Seafoods</h2>
                                </div>
                            </div>
                            <div className="right-col">
                                <Link onClick={()=>this.onShowAll('fantastic')}>Show All</Link>
                            </div>
                        </div>
                        <ProductsSlider list={fantasticDeals} history={this.props.history}/>    
                    </FullWidth>
                }
                {(categories && categories.length > 0 ) &&
                    <FullWidth SectionClass="cat-slider pb-30">
                        <div className="flex-title mb-30">
                            <div className="left-col">
                                <div className="experience-text">
                                    <h2>Category</h2>
                                </div>
                            </div>
                            <div className="right-col">

                            </div>
                        </div>
                        <CategorySlider 
                            history = {this.props.history} 
                            categories={categories}/>    
                    </FullWidth>
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    loading:state.homeReducer.dashboardLoading,
    todayDeals: state.homeReducer.todDeals,
    fantasticDeals: state.homeReducer.fanDeals,
    catOffers:state.homeReducer.catOffers,
    store : state.storeReducer.store,
    sliders:state.homeReducer.slideBanners,
    volOffers:state.homeReducer.volOffers,
    stillBanners:state.homeReducer.stillBanners,
    is_loading:state.storeReducer.is_loading,
    categories:state.categoryReducer.categories,
    stores : state.storeReducer.stores,
    cat_loading:state.categoryReducer.cat_loading,
    location: state.userReducer.location
});
const mapDispatchToProps = dispatch => ({
    onGetDashboard : (store_id,skip,take)=>{
        dispatch(getDashboard(store_id,skip,take))
    },
    onGetCategories:(store_id,skip,take)=>{
        dispatch(getCategories(store_id,skip,take));
    },
    onSetLoadDate:(date, count)=>{
        dispatch(setLastLoadStatus(date, count))
    },
    onGetNearestStore:(data)=>{
        dispatch(getNearestStore(data))
    },
    onSetStore: (store) => {
        dispatch(setStore(store));
    },
    onGetDeliveryMods:(store_id, latitude, longitude)=>{
        dispatch(getDeliveryMods(store_id, latitude, longitude))
    }
});

const HomeContainer = connect(
                        mapStateToProps,
                        mapDispatchToProps)
                        (HomePage);
    
export default HomeContainer;