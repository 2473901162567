import request from '../config/HttpClient'
import * as URLS from '../constants/Urls'

function cartRefresh(data) {
    return request({
      url:   URLS.REFRESH_CART,
      method: 'POST',
      data
    });
}

function placeOrder(data) {
  return request({
    url:   URLS.PLACE_ORDER,
    method: 'POST',
    data
  });
}

function finalizePayment(transaction_id) {
  return request({
    url:   URLS.FINALIZE_PAYMENT + '/'+ transaction_id,
    method: 'GET'
  });
}

function minimumCart(store_id) {
  return request({
    url:   URLS.MINIMUM_CART + '/'+ store_id,
    method: 'GET'
  });
}

function deliveryAvailability(store_id, location_id) {
  return request({
    url:   URLS.DELIVERY_AVAILABILIY + '/'+ store_id+'/'+location_id,
    method: 'GET'
  });
}

function refreshOrder(data){
  return request({
    url:   URLS.REFRESH_ORDER,
    method: 'POST',
    data
  });
}

const CartService = {
    cartRefresh,
    placeOrder,
    finalizePayment,
    minimumCart,
    deliveryAvailability,
    refreshOrder
}


export default CartService;