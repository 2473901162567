import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'react-simple-snackbar'

import './Login.css';
import FormWrap from './FormWrap';
import AInput from '../../components/basic/AInput';
import { login } from '../../actions/user';
import ActivityIndicator from '../../components/loaders/ActivityIndicator';

class Login extends Component {

  constructor(props){
    super(props);

    this.state = {
      mobile : '',
      password : '',
      errors :[],
      loading:false,
    }
    this.validator = new SimpleReactValidator();
  }

  submitLogin = (e)=>{
    e.preventDefault()
    if( this.validator.allValid() ){
      this.props.onLogin(this.state.mobile, this.state.password);
    }
    else {
      this.validator.showMessages();
      this.forceUpdate();
    } 
  }

  onChangeText = (e)=>{
    this.setState({[e.target.name]: e.target.value})
  }

  componentWillReceiveProps(nextProps){
    if(this.props.loading && !nextProps.loading){
      if(nextProps.loggingIn === false && nextProps.verified === false && nextProps.user){
        this.props.history.push('/verify-otp/'+nextProps.user.id+'/verify')
      }else if(nextProps.loggedIn === true){
        this.props.history.push('/')
      }else{
        this.props.openSnackbar( "Invalid mobile number or password")
      }
    }
  }

  render() {
    const {mobile, password} = this.state
    return(
      <FormWrap>
        <ActivityIndicator fullScreen loading={this.props.loading}/>
        <form className="form-vertical">
          <AInput
            onChange={this.onChangeText}
            name="mobile"
            value={mobile}
            type="number"
            validator={this.validator}
            rules='required|phone'
            placeholder="Mobile Number"/>
          <AInput
            onChange={this.onChangeText}
            type="password"
            name="password"
            value={password}
            validator={this.validator}
            rules='required'
            isPassword={true}
            placeholder="Password"/>
          <div className="form-group row">
            <div className="col-md-12">
              <div className="forgot text-right">
                <Link className="d-block small" to="/forgot-password">Forgot Password?</Link>
              </div>
            </div>
          </div>
          <div className="bt-wrapper">
            <button 
              style={{width:'100%'}}
              onClick ={this.submitLogin} 
              className="waves-effect waves-light btn">LOGIN</button>
          </div>
          <div className="signup-here text-small">
            Not registered yet? <br/>  <Link className="d-block large-text" to="/signup">SIGNUP HERE</Link>
          </div>
        </form>
      </FormWrap>
    )
  }
}

Login.deaultProps = {
  error:[]
}

const mapStateToProps = (state) => ({
  loggingIn:state.userReducer.loggingIn ,
  loggedIn: state.userReducer.loggedIn,
  loading: state.userReducer.loading,
  error:state.userReducer.error,
  errorMessage:state.userReducer.errorMessage,
  user:state.userReducer.user,
  verified: state.userReducer.verified,
})

const mapDispatchToProps = (dispatch)=> ({
  onLogin:(mobile, password)=>{
    dispatch(login(mobile, password))
  },
})


const LoginPage = connect(mapStateToProps, mapDispatchToProps)(Login)
export default withSnackbar(withRouter(LoginPage))






