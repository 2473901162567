import React from 'react'
import { connect } from 'react-redux'
import { getSupportUrl } from '../../actions/general'
import FullWidth from '../../components/basic/FullWidth'
import ActivityIndicator from '../../components/loaders/ActivityIndicator'

class Support extends React.Component{

    componentDidMount(){
        this.props.onGetSupport()
    }

    render(){

        const { loading, support } = this.props
        if(loading && (!support || !support.mobile)){
            return <FullWidth>
                <ActivityIndicator/>
            </FullWidth>
        }

        if(!support || !support.mobile){
            return <FullWidth SectionClass="white-wrapper">
                <div className="body-content">
                     <div className="sec-title">
                     <h2>Support</h2>
                     </div>   
                    <p style={{whiteSpace:"pre-line"}}></p>
                </div>
            </FullWidth>
        }

        return(
            <FullWidth SectionClass="white-wrapper">
                <div className="body-content">
                     <div className="sec-title">
                     <h2>Support</h2>
                     </div>   
                    <h3 style={{whiteSpace:"pre-line"}}>Any queries, please reach out to us </h3>
                    <p style={{whiteSpace:"pre-line"}}>{support.mobile}</p>
                    <p style={{whiteSpace:"pre-line"}}>{support.email}</p>
                    <a href={support.url} target="_blank" style={{backgroundColor:'green', color:'white', padding: 10, borderRadius: 10}}>{support.button_title}</a>
                    <h3>Registered Office:</h3>
                    <p>{support.registered_address}</p>
                    <h3>Regional Office:</h3>
                    <p>{support.regional_address}</p>
                </div>
            </FullWidth>
        )
    }
}

const mapStateToProps = state=>({
    loading: state.faqReducer.SupportLoading,
    support:state.faqReducer.support,
})

const mapDispatchToProps = dispatch=>({
    onGetSupport:()=>{
        dispatch(getSupportUrl())
    }
})
 
export default connect(mapStateToProps,mapDispatchToProps)(Support);