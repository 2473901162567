import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Modal, Form } from 'semantic-ui-react';

import ColWidth from '../../components/basic/ColWidth';
import ThumbanilSlider from '../../components/custom/ThumbnailSlider';
import CartCounter from '../../components/custom/CartCounter';
import { getProduct } from '../../actions/product';
import ActivityIndicator from '../../components/loaders/ActivityIndicator';
import { addToCart, removeFromCart } from '../../actions/cart';
import { getCartCount } from '../../utils';
import FullWidth from '../../components/basic/FullWidth';
import ProductContainer from '../../components/custom/ProductCard';

class ProdductDetails extends Component {

    state = {count:0, selected:0, popup:false, pack: null, id: null, selectedQty:null, currentPosition:0}

    chooseWeight = ()=>{
      this.setState({popup: !this.state.popup})
    }

    onChangeWeight = (selected, qty)=>{
      const { products} = this.props
      const pos = 0
      var product = products.products[pos].packs[qty.pos];
      this.setState({selected, selectedQty:qty, pack:product})
    }

    checkCount = ()=>{
      var id = this.props.match.params.id
      if(this.state.id){
          id = this.state.id
      }
      if(this.state.pack){
        id = this.state.pack.id
      }
      var count = getCartCount(this.props.cart, id)
      if(count !== this.state.count){
        this.setState({count})
      }
    }
    
    increment = ()=>{
        const { products } = this.props
        var { count, selectedQty } = this.state

        var pos = products.pos;
        if(this.state.currentPosition != null ) pos = this.state.currentPosition
        var product = products.products[pos];
        const position = product['position']
        const item = product['packs'][position]

        var stock =0;
        if(item && item.stock){
            stock = item.stock 
        }
        count = parseInt(count) + 1

        if(count && stock < count){
            alert("Sorry! We are out of stock for adding more quantity to cart");
            return
        }

        this.setState({
            count
        })
        
        var qty = product['qtys'][0]
        if(selectedQty){
            qty = selectedQty
        }
        var newproduct = product.packs[qty.pos]
        var new_packs = product.packs.map((p, i)=>{
            return {...p, packs:[]}
        })
        product['packs'] = new_packs
        newproduct['packs'] = product
        this.props.onAddToCart(newproduct, count)
    }

    decrement = ()=>{
        const { products } = this.props
        var { count, selectedQty } = this.state
        count = count - 1
        this.setState({
            count
        })
        var pos = products.pos;
        if(this.state.currentPosition != null ) pos = this.state.currentPosition
        var product = products.products[pos];
        var qty = product['qtys'][0]
        if(selectedQty){
            qty = selectedQty
        }
        var newproduct = product.packs[qty.pos]
        var new_packs = product.packs.map((p, i)=>{
            return {...p, packs:[]}
        })
        product['packs'] = new_packs
        newproduct['packs'] = product
        this.props.onRemoveFromCart(newproduct, count)
    }

    renderWeights = (qtys)=>{
        if(!qtys || qtys.length<1) return null
        return(
          <Form>
            <Form.Group grouped>
              {qtys.map((qty, index)=>(
                <Form.Checkbox 
                  checked={this.state.selected===index}
                  key={index}
                  onChange={()=>this.onChangeWeight(index, qty)}
                  label={qty.qty} 
                  name='filter' 
                  value='low' />
              ))}
            </Form.Group>
          </Form>
        )
    }

    componentDidMount(){
        const id = this.props.match.params.id
        this.setState({id})
        this.props.onGetProduct(id)
        this.checkCount()
    }

    componentDidUpdate(){
        const id = this.props.match.params.id
        // if(this.state.id && this.state.id != id){
        //     this.setState({id})
        //     this.props.onGetProduct(id)
        // }
        this.checkCount()
    }

    _click = (value,i)=>{
        // var selectedValues = [value];
        // var back = this.search(this.props.products.products, selectedValues);
        var product = this.props.products.products[value].packs[this.props.products.products[value].position];
        this.setState({currentPosition: value, id: product.id})
    }

    search(products, selectedValues){
        if(selectedValues.length < 1) return null;
        var lastFound = 0;
        var match = 0; 

        for (var i=0; i < products.length; i++) {

            if(match === selectedValues.length) return lastFound;
            var pack = products[i].packs[products[i].position]
          
            if(pack.hasOwnProperty('attributes')){
                var attributes = pack.attributes;
                match = 0;
                for (var n=0; n < selectedValues.length; n++) {
                    for (var j=0; j < attributes.length; j++) {
                        if(attributes[j].value === selectedValues[n]){
                            lastFound = i;
                            match = match + 1;
                            // return i
                        }
                    }
                    if(match === selectedValues.length) {
                        break;
                    }
                }
            }
        }
        if(lastFound !== 0)return lastFound;
        return null;
    }


    checkValue(arr,value) {
        if(typeof arr === 'undefined') return false;
        var found = arr.some(function (el) {
          return el.value === value;
        });
        return found;
    }

    render() { 
        const { products, loading, store } = this.props
        const { count, pack } = this.state

        if(loading){
            return(
                <FullWidth SectionClass="two-rows-wrapper pb-50">
                    <ActivityIndicator loading={loading} />
                </FullWidth>
            )
        }

        if(!products){
            return(
                <ColWidth SectionClass="Product-details">
                    <h3>No Product Found</h3>
                </ColWidth>
            )
        }

        if(products.length< 1 || !products.products || products.products.length<1){
            return null
        }
        var pos = products.pos;
        if(this.state.currentPosition != null ) pos = this.state.currentPosition
        const it = products.products[pos]
        const position = it['position']
        const packs = it['packs']
        const qtys = it['qtys']
        const item = it['packs'][position]
        var selectedValues = [];
        var variants = products.variants;
        for(var i =0 ; i < variants.length; i++){
            variants[i].values.map((value)=>{
                var back = this.checkValue(item.attributes, value.value);
                if(back){
                    selectedValues.push(value.value);
                }
            })
        }

        var { 
            id,
            title, 
            selling_price, 
            wholesale_price, 
            featured_image,
            description,
            brand, 
            qty, 
            unit, 
            offer, 
            discount, 
            byPerc, 
            disc_price,
            images
          } = item;
        
        if(pack){
            var { 
                id,
                title, 
                selling_price, 
                wholesale_price, 
                featured_image,
                brand, 
                qty, 
                unit, 
                offer, 
                discount, 
                byPerc, 
                disc_price,
                images
            } = pack;
        }

          const WeigthModal = (
            <Modal
              size={'mini'}
              open={this.state.popup}
              onClose={this.chooseWeight}
            >
              <Modal.Header>Available Packs</Modal.Header>
              <Modal.Content>
                {this.renderWeights(qtys)}
              </Modal.Content>
            </Modal>
          )
        return ( 
            <ColWidth SectionClass="Product-details">
                <ActivityIndicator loading={loading} fullScreen/>
                <div className="col-md-5">
                   <ThumbanilSlider images={images}/>
                </div>
                <div className="col-md-7">
                    <div className="p-info">
                        <div className="info-row">
                            {offer?<div className="offer">{byPerc? discount + '% Off': discount + ' '+store.currency.symbol}</div>:null}
                            {/* <div className="cat-name grey font-14">{brand}</div> */}
                            <h4 className="p-title font-18 black">{title}</h4>
                            <div className="fl-row">
                                <div className="price-wrapper">
                                    <span className="currency grey font-16">{store.currency.symbol}</span> 
                                    <span className="price black">{disc_price}</span> 
                                    {disc_price !== selling_price &&<span className="old-price grey font-16">{selling_price}</span>}
                                </div>
                                
                                <CartCounter
                                    count={count}
                                    onIncrement={this.increment}
                                    onDecrement={this.decrement}/> 
                            </div>
                            <div className="row" style={{marginTop: 10, marginBottom: 10}}>
                                {item && item.attributes && item.attributes.map((at, i)=>(
                                    <div className="variant" key={i}>
                                        <span>{at.value}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                        
                        <div className="info-row" style={{marginTop: 20, marginBottom: 20}}>
                            <h5>Quatity</h5>
                            <button className="weight mt-2 grey font-16 drp-btn" onClick={this.chooseWeight}>
                            {qty} {unit} <i class="zmdi zmdi-chevron-down"></i>
                            </button>
                        </div>
                        {description && <div className="info-row" style={{marginTop: 20, marginBottom: 20}}>
                            <h5>About</h5>
                            <p>{description}</p>
                        </div>
                        }
                        {products && products.products && products.products.length > 1 &&
                            <div className="info-row" style={{marginTop: 10, marginBottom: 10}}>
                                <h5 style={{marginTop: 20, marginBottom: 20}}>Variants</h5>
                                {products.products.map((p,n)=>{
                                    var pa = p.packs[p.position]
                                    if(pa.id === id) return null
                                    return <ProductContainer 
                                        {...pa} horizontal={true} 
                                        history={this.props.history} 
                                        onClick={()=>this._click(n)}
                                        key={n} packs={p}/>
                                })}
                                    
                            </div>
                        }
                    </div>
                </div>
                {WeigthModal}
            </ColWidth>
         );
    }
}

const mapStateToProps = state => ({
    loading:state.productReducer.isLoading,
    products:state.productReducer.product,
    store : state.storeReducer.store,
    cart: state.cartReducer.cart
});
const mapDispatchToProps = dispatch => ({
    onGetProduct:(product_id)=>{
        dispatch(getProduct(product_id));
    },
    onAddToCart:(item, count)=>{
        dispatch(addToCart(item, count))
    },
    onRemoveFromCart:(item, count)=>{
      dispatch(removeFromCart(item, count))
    }
});

const ProdductDetailsContainer = connect(
                        mapStateToProps,
                        mapDispatchToProps)
                        (ProdductDetails);
    
export default ProdductDetailsContainer;

