export const SERACH_HISTORY = 'LAST_SERACH_HISTORY';

export const USER_NOT_ACTIVATED = 1;
export const USER_ACTIVATED = 2;
export const USER_SUSPENDED = 3;
export const USER_BLOCKED = 4;
export const USER_REMOVED = 5;

export const LOGED_IN = 1;
export const NOT_LOGED_IN = 2;

export const USER_TYPE_ADMIN = 'admin';
export const USER_TYPE_STORE = 'store';

export const BANNER_TYPE_STILL= 1;
export const BANNER_TYPE_SLIDE=2;

export const OFFER_TYPE_PRODUCT = 1;
export const OFFER_TYPE_CATEGORY = 2;
export const OFFER_TYPE_BRAND =3;
export const OFFER_TYPE_VOLUME =4;

export const ATTRIBUTE_TYPE_TEXT = 1;
export const ATTRIBUTE_TYPE_COLOR = 2;

export const STORE_AVAILABLE=1;
export const STORE_DISABLED=2;

export const ON_SALE_AVAILABLE = 1;
export const ON_SALE_DISABLED = 2;
export const ON_SALE_BLOCKED = 3;

export const MENU_TYPE_CATALOGUE=1;
export const MENU_TYPE_PRODUCT=2;
export const MENU_TYPE_OFFER=3;
export const MENU_TYPE_ACCOUNT=4;
export const MENU_TYPE_REVIEW=5;
export const MENU_TYPE_REPORT=6;
export const MENU_TYPE_STAFF=7;
export const MENU_TYPE_CUSTOMER=8;
export const MENU_TYPE_CONTENT=9;
export const MENU_TYPE_DELIVERYBOY=10;
export const MENU_TYPE_ORDER=11;
export const MENU_TYPE_NOTIFICATION=12;

export const SEND_NOTIFICATION_REGISTERED_CUSTOMERS = 1;
export const SEND_NOTIFICATION_STORE_CUSTOMERS = 2;
export const SEND_NOTIFICATION_GUEST_CUSTOMERS =3
export const SEND_NOTIFICATION_ALL_CUSTOMERS =4


export const ORDER_CREATED = 1;
export const ORDER_ACCEPTED = 2;
export const ORDER_PACKING = 3;
export const ORDER_PACKED = 4;
export const ORDER_SHIPPING = 5;
export const ORDER_COMPLETED = 6;
export const ORDER_CANCELLED = 7;
export const ORDER_RESCHEDULED = 8;

export const PAYMENT_ONLINE = 1;
export const PAYMENT_COD = 2;
export const PAYMENT_CARD_ON_DELIVERY = 3;

export const SLOT_DELIVERY=1;
export const FAST_DELIVERY=2;
export const TAKE_AWAY = 3;

export const PAYMENT_SUCCESS = 2;
export const PAYMENT_FAILED = 3;
export const PAYMENT_PENDING = 1;



