import request from '../config/HttpClient'
import * as URLS from '../constants/Urls'

function getFaqs() {

    return request({
      url:   URLS.GET_FAQS,
      method: 'GET',
    });
  }
  function getAbout() {

    return request({
      url:   URLS.GET_ABOUT,
      method: 'GET',
    });
  }

  function getPointTerms() {

    return request({
      url:   URLS.GET_POINT_TERMS,
      method: 'GET',
    });
  }

  function changePicture(data) {

    return request({
      url:   URLS.CHANGE_PICTURE,
      method: 'POST',
      data
    });
  }

  function sendResetOtp(data) {

    return request({
      url:   URLS.SEND_RESET_OTP,
      method: 'POST',
      data
    });
  }

  function verifyResetOtp(otp,customer_id) {
    var sub_url = '/'+otp+'/'+customer_id;
    return request({
      url:   URLS.VERIFY_RESET_OTP + sub_url,
      method: 'GET'
    });
  }

  function resetPassword(data) {

    return request({
      url:   URLS.RESET_PASSWORD,
      method: 'POST',
      data
    });
  }

  function getPrivacy() {

    return request({
      url:   URLS.GET_POLICY,
      method: 'GET',
    });
  }

  function getSupportUrl() {
    return request({
      url:    URLS.SUPPORT_URL,
      method: 'GET'
    });
  }

  

  const GeneralService = {
    getFaqs,
    getAbout,
    getPointTerms,
    changePicture,
    sendResetOtp,
    verifyResetOtp,
    resetPassword,
    getPrivacy,
    getSupportUrl
  }
  
  
  export default GeneralService;
  