import * as Actions from '../constants/ActionTypes';

const initialState ={
    loading: false,
    orders: [],
    order: null,
    reasons: [],
    cost: 5,
    time: 30
}


export default function orderReducer(state = initialState , action){
    switch(action.type){
        case Actions.GET_ORDERS_LOADING:
            return{
                ...state,
                loading:true
            }
        case Actions.GET_ORDERS:
            const loadMore = action.loadMore
            var orders = action.orders
            if(loadMore){
                orders = state.orders.concat(orders)
            }
            return{
                ...state,
                loading:false,
                count: action.count,
                orders: orders
            }
        case Actions.GET_ORDERS_FAILED:
            return{
                ...state,
                loading:false
            }

        case Actions.GET_FAST_DELIVERY_LOADING:
            return{
                ...state,
                loading:true
            }
        case Actions.GET_FAST_DELIVERY:
        
            return{
                ...state,
                loading:false,
                cost: action.cost,
                time: action.time
            }
        case Actions.GET_FAST_DELIVERY_FAILED:
            return{
                ...state,
                loading:false
            }
    
        case Actions.GET_ORDER_LOADING:
            return{
                ...state,
                loading:true,
                order:null
            }
        case Actions.GET_ORDER:
            return{
                ...state,
                loading:false,
                order: action.order
            }
        case Actions.GET_ORDER_FAILED:
            return{
                ...state,
                loading:false
            }

        case Actions.GET_CANCEL_REASONS_LOADING:
            return{
                ...state,
                loading:true
            }
        case Actions.GET_CANCEL_REASONS:
            return{
                ...state,
                loading:false,
                reasons: action.reasons
            }
        case Actions.GET_CANCEL_REASONS_FAILED:
            return{
                ...state,
                loading:false
            }

        case Actions.CANCEL_ORDER_LOADING:
            return{
                ...state,
                popup: false,
                success: false,
                loading:true
            }
        case Actions.CANCEL_ORDER:
            return{
                ...state,
                loading:false,
                popup: true,
                success: true
            }
        case Actions.CANCEL_ORDER_FAILED:
            return{
                ...state,
                loading:false,
                popup: true,
                success: false,
            }

        case Actions.RESCHEDULE_ORDER_LOADING:
            return{
                ...state,
                popup: false,
                success: false,
                loading:true
            }
        case Actions.RESCHEDULE_ORDER:
            return{
                ...state,
                loading:false,
                popup: true,
                success: true
            }
        case Actions.RESCHEDULE_ORDER_FAILED:
            return{
                ...state,
                loading:false,
                popup: true,
                success: false,
            }

        case Actions.ADD_REVIEW_LOADING:
            return{
                ...state,
                popup: false,
                success: false,
                loading:true
            }
        case Actions.ADD_REVIEW:
            return{
                ...state,
                loading:false,
                popup: true,
                success: true
            }
        case Actions.ADD_REVIEW_FAILED:
            return{
                ...state,
                loading:false,
                popup: true,
                success: false,
            }

        case Actions.RESET_SUCCESS:
            return{
                ...state,
                loading:false,
                popup: false,
                success: false,
            }

        
        default:
        return state
       
    }
}