import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { getFantasticDeals, getTodaysDeals } from '../../actions/home'
import { getBannerItems } from '../../actions/product'
import FullWidth from '../../components/basic/FullWidth'
import ProductList from '../../components/custom/ProductList'

function Products({
    loading,
    todayDeals,
    fantasticDeals,
    store,
    count,
    bannerItemLoading,
    bannerProducts,
    ...props
}){

    const [skip, setSkip] = useState(0)
    const [initial, setInitial] = useState(true)
    const [take] = useState(10)
    const type = props.match.params.type
    var data = []
    if(type == 'today' && todayDeals){
        data = todayDeals
    }
    if(type == 'fantastic' && fantasticDeals){
        data = fantasticDeals
    }
    if(type == 'slide' && bannerProducts && bannerProducts.childs){
        data = bannerProducts.childs
    }

    useEffect(()=>{
        if(initial){
            load()
            setInitial(false)
        }
    })

    const hasMore = ()=>{
        if(data && count>data.length && count>skip){
            return true
        }
        return false
    }

    const load = (skip=0)=>{
        if(store){
            if(type === 'today'){
                props.onGetTodaysDeal(store.id, skip, take, 0)
            }
            else  if(type === 'slide'){
                props.onGetBannerItems(props.match.params.id, store.id)
            }
            else{
                props.onGetFantasticDeals(store.id, skip, take, 0)
            }
        }
    }

    const loadMore = ()=>{
        if(!loading && hasMore()){
            const newSkip = skip + take
            load(newSkip)
            setSkip(newSkip)
        }
    }

    return(
        <FullWidth SectionClass="two-rows-wrapper pb-50">
            <ProductList 
                count={count}
                loading={((bannerItemLoading || loading) && (!data || data.length<1))}
                fetchMore={loadMore}
                hasMore={hasMore()}
                history={props.history}
                products={data}/>
        </FullWidth>
    )
}

const mapStateToProps = state => ({
    count:state.homeReducer.count,
    products:state.homeReducer.products,
    loading: state.homeReducer.isLoading,
    store : state.storeReducer.store,
    todayDeals: state.homeReducer.todDeals,
    fantasticDeals: state.homeReducer.fanDeals,
    bannerItemLoading:state.bannerReducer.bannerItemLoading,
    bannerProducts:state.bannerReducer.data
});
const mapDispatchToProps = dispatch => ({
    onGetTodaysDeal:(store, skip, take, index)=>{
        dispatch(getTodaysDeals(store, skip, take, index))
    },
    onGetFantasticDeals:(store_id,skip,take,index)=>{
        dispatch(getFantasticDeals(store_id,skip,take,index));
    },
    onGetBannerItems:(banner_id,store_id)=>{
        dispatch(getBannerItems(banner_id,store_id));
    },
});
const ProductsContainer = connect(
                        mapStateToProps,
                        mapDispatchToProps)
                        (Products);
export default ProductsContainer;