import * as Actions from '../constants/ActionTypes';

const initialState ={
    loading:false,
    points:{},
    stLoading:false,
    more_stLoading:false
}

export default function pointReducer(state = initialState , action){
    switch(action.type){
        

        case Actions.GET_POINTS_LOADING:
            return{
                ...state,
                loading:true,
            }
        case Actions.GET_POINTS_SUCCESS:
            return{
                ...state,
                loading : false,
                points: action.points
            }
        case Actions.GET_POINTS_FAILED:
            return{
                ...state,
                loading : false,
            }

        
        case Actions.GET_STATEMENTS_LOADING:
            return{
                ...state,
                stLoading:true,
            }
        case Actions.GET_STATEMENTS_SUCCESS:
            return{
                ...state,
                stLoading : false,
                statements: action.statements
            }
        case Actions.GET_STATEMENTS_FAILED:
            return{
                ...state,
                stLoading : false,
            }

        case Actions.MORE_GET_STATEMENTS_LOADING:
            return{
                ...state,
                more_stLoading:true,
            }
        case Actions.MORE_GET_STATEMENTS_SUCCESS:
            var sts = action.statements;
            if(action.skip !== null && action.skip > 0){
                sts = state.statements.concat(sts)
            }
            return{
                ...state,
                more_stLoading : false,
                statements: sts
            }
        case Actions.MORE_GET_STATEMENTS_FAILED:
            return{
                ...state,
                more_stLoading : false,
            }

        default:
        return state
         
    }
}