import React from 'react'
import { connect } from 'react-redux'
import { getPrivacy } from '../../actions/general'
import FullWidth from '../../components/basic/FullWidth'
import ActivityIndicator from '../../components/loaders/ActivityIndicator'

class Privacy extends React.Component{

    componentDidMount(){
        this.props.onGetPrivacy()
    }

    render(){

        const { loading, privacy } = this.props
        console.log(privacy)
        if(loading && (!privacy || !privacy.content)){
            return <FullWidth>
                <ActivityIndicator/>
            </FullWidth>
        }

        if(!privacy || !privacy.content){
            return <FullWidth SectionClass="white-wrapper">
                <div className="body-content">
                     <div className="sec-title">
                     <h2>Privacy Policy</h2>
                     </div>   
                    <p style={{whiteSpace:"pre-line"}}></p>
                </div>
            </FullWidth>
        }

        return(
            <FullWidth SectionClass="white-wrapper">
                <div className="body-content">
                     <div className="sec-title">
                     <h2>Privacy Policy</h2>
                     </div>   
                    <p style={{whiteSpace:"pre-line"}}>{privacy.content}</p>
                </div>
            </FullWidth>
        )
    }
}

const mapStateToProps = state=>({
    loading: state.faqReducer.PrivacyLoading,
    privacy:state.faqReducer.privacy,
})

const mapDispatchToProps = dispatch=>({
    onGetPrivacy:()=>{
        dispatch(getPrivacy())
    }
})
 
export default connect(mapStateToProps,mapDispatchToProps)(Privacy);