import * as Actions from '../constants/ActionTypes';

const initialState ={
 loading:false,
 count:0
}

export default function customerReducer(state = initialState , action){
  switch(action.type){
    case Actions.GET_NOTIFICATION_COUNT:
     return {
         ...state,
        loading:true
     };
     case Actions.GET_NOTIFICATION_COUNT_SUCCESS:
       return {
        ...state,
        loading:false,
        count: action.count
      };

      case Actions.GET_NOTIFICATION_COUNT_FAILED:
       return {
        ...state,
        loading:false,
      };


   default:
     return state

  }
}
