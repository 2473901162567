export const INTIAL_STAGE = 'INTIAL_STAGE';
export const REMOVE_SNACKBAR_SIGNUP = 'REMOVE_SNACKBAR_SIGNUP';
export const INTIAL_STAGE_SIGNUP = 'INTIAL_STAGE_SIGNUP';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_SUCCESS_NOT_VERIFIED = 'LOGIN_SUCCESS_NOT_VERIFIED';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT = 'LOGOUT';

export const USER="USER";
export const REMOVE_SNACKBAR = "REMOVE_SNACKBAR";

export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';

export const PRODUCTS_LOADING = 'PRODUCTS_LOADING';
export const PRODUCTS = 'PRODUCTS';
export const PRODUCTS_FAILURE = 'PRODUCTS_FAILURE';


export const CATEGORIES_LOADING = 'CATEGORIES_LOADING';
export const CATEGORIES = 'CATEGORIES';
export const CATEGORIES_FAILURE = 'CATEGORIES_FAILURE';


export const CHILD_CATEGORIES_LOADING = 'CHILD_CATEGORIES_LOADING';
export const CHILD_CATEGORIES = 'CHILD_CATEGORIES';
export const CHILD_CATEGORIES_FAILURE = 'CHILD_CATEGORIES_FAILURE';

export const MORE_CATEGORIES_LOADING = 'MORE_CATEGORIES_LOADING';
export const MORE_CATEGORIES = 'MORE_CATEGORIES';
export const MORE_CATEGORIES_FAILURE = 'MORE_CATEGORIES_FAILURE';

export const SLIDE_BANNERS_LOADING = 'SLIDE_BANNER_LOADING';
export const SLIDE_BANNERS = 'SLIDE_BANNERS';
export const SLIDE_BANNERS_FAILURE = 'SLIDE_BANNERS_FAILURE';

export const STILL_BANNERS_LOADING = 'STILL_BANNERS_LOADING';
export const STILL_BANNERS = 'STILL_BANNERS';
export const STILL_BANNERS_FAILURE = 'STILL_BANNERS_FAILURE';

export const VOLUME_OFFERS_LOADING = 'VOLUME_OFFERS_LOADING';
export const VOLUME_OFFERS = 'VOLUME_OFFERS';
export const VOLUME_OFFERS_FAILURE = 'VOLUME_OFFERS_FAILURE';

export const CATEGORY_OFFERS_LOADING = 'CATEGORY_OFFERS_LOADING';
export const CATEGORY_OFFERS = 'CATEGORY_OFFERS';
export const CATEGORY_OFFERS_FAILURE = 'CATEGORY_OFFERS_FAILURE';

export const MORE_CATEGORY_OFFERS_LOADING = 'MORE_CATEGORY_OFFERS_LOADING';
export const MORE_CATEGORY_OFFERS = 'MORE_CATEGORY_OFFERS';
export const MORE_CATEGORY_OFFERS_FAILURE = 'MORE_CATEGORY_OFFERS_FAILURE';

export const TODAYS_DEALS_LOADING = 'TODAYS_DEALS_LOADING';
export const TODAYS_DEALS = 'TODAYS_DEALS';
export const TODAYS_DEALS_FAILURE = 'TODAYS_DEALS_FAILURE';

export const MORE_TODAYS_DEALS_LOADING = 'MORE_TODAYS_DEALS_LOADING';
export const MORE_TODAYS_DEALS = 'MORE_TODAYS_DEALS';
export const MORE_TODAYS_DEALS_FAILURE = 'MORE_TODAYS_DEALS_FAILURE';

export const FANTASTIC_DEALS_LOADING = 'FANTASTIC_DEALS_LOADING';
export const FANTASTIC_DEALS = 'FANTASTIC_DEALS';
export const FANTASTIC_DEALS_FAILURE = 'FANTASTIC_DEALS_FAILURE';


export const MORE_FANTASTIC_DEALS_LOADING = 'MORE_FANTASTIC_DEALS_LOADING';
export const MORE_FANTASTIC_DEALS = 'MORE_FANTASTIC_DEALS';
export const MORE_FANTASTIC_DEALS_FAILURE = 'MORE_FANTASTIC_DEALS_FAILURE';

export const GET_STORE_LOADING = 'GET_STORE_LOADING';
export const STORES = 'STORES';
export const STORES_FAILURE = 'STORES_FAILURE';
export const SET_STORE = 'SET_STORE';

export const GET_NEAREST_STORE_LOADING = 'GET_NEAREST_STORE_LOADING';
export const NEAREST_STORE = 'NEAREST_STORE';
export const NEAREST_STORE_FAILURE = 'NEAREST_STORE_FAILURE';

export const GET_NEAREST_STORE_LOADING2 = 'GET_NEAREST_STORE_LOADING2';
export const NEAREST_STORE2 = 'NEAREST_STORE2';
export const NEAREST_STORE_FAILURE2 = 'NEAREST_STORE_FAILURE2';


export const GET_DEFAULT_STORE_LOADING = 'GET_DEFAULT_STORE_LOADING';
export const DEFAULT_STORE = 'DEFAULT_STORE';
export const DEFAULT_STORE_FAILURE = 'DEFAULT_STORE_FAILURE';

export const GET_DEFAULT_STORE_LOADING2 = 'GET_DEFAULT_STORE_LOADING2';
export const DEFAULT_STORE2 = 'DEFAULT_STORE2';
export const DEFAULT_STORE_FAILURE2 = 'DEFAULT_STORE_FAILURE2';

export const GET_CATEGORY_CHILDS_LOADING = 'GET_CATEGORY_CHILDS_LOADING';
export const GET_CATEGORY_CHILDS_CATEGORY = 'GET_CATEGORY_CHILDS_CATEGORY';
export const GET_CATEGORY_CHILDS_PRODUCTS = 'GET_CATEGORY_CHILDS_PRODUCTS';
export const GET_CATEGORY_CHILDS_FAILED = 'GET_CATEGORY_CHILDS_FAILED';

export const MORE_GET_CATEGORY_CHILDS_LOADING = 'MORE_GET_CATEGORY_CHILDS_LOADING';
export const MORE_GET_CATEGORY_CHILDS_CATEGORY = 'MORE_GET_CATEGORY_CHILDS_CATEGORY';
export const MORE_GET_CATEGORY_CHILDS_PRODUCTS = 'MORE_GET_CATEGORY_CHILDS_PRODUCTS';
export const MORE_GET_CATEGORY_CHILDS_FAILED = 'MORE_GET_CATEGORY_CHILDS_FAILED';

export const GET_PRODUCT_LOADING = "GET_PRODUCT_LOADING";
export const GET_PRODUCT = "GET_PRODUCT";
export const GET_PRODUCT_FAILED = "GET_PRODUCT_FAILED";

export const GET_RELATED_PRODUCTS_LOADING = "GET_RELATED_PRODUCTS_LOADING";
export const GET_RELATED_PRODUCTS = "GET_RELATED_PRODUCTS";
export const GET_RELATED_PRODUCTS_FAILED = "GET_RELATED_PRODUCTS_FAILED";

export const MORE_GET_RELATED_PRODUCTS_LOADING = "MORE_GET_RELATED_PRODUCTS_LOADING";
export const MORE_GET_RELATED_PRODUCTS = "MORE_GET_RELATED_PRODUCTS";
export const MORE_GET_RELATED_PRODUCTS_FAILED = "MORE_GET_RELATED_PRODUCTS_FAILED";

export const SEARCH_STARTED = "SEARCH_STARTED";
export const SEARCH = "SEARCH";
export const SEARCH_FAILED = "SEARCH_FAILED";
export const INIT_SEARCH = "INIT_SEARCH";

export const BANNER_ITEMS_LOADING = "BANNER_ITEMS_LOADING";
export const BANNER_ITEMS = "BANNER_ITEMS";
export const BANNER_ITEMS_FAILED = "BANNER_ITEMS_FAILED";

export const ADD_TO_SEARCH_HISTORY = "ADD_TO_SEARCH_HISTORY";
export const ADD_SEARCH_HISTORY_FAILED = "ADD_SEARCH_HISTORY_FAILED";

export const GET_NOTIFICATION_LOADING = 'GET_NOTIFICATION_LOADING';
export const GET_NOTIFICATION = 'GET_NOTIFICATION';
export const GET_NOTIFICATION_FAILED = 'GET_NOTIFICATION_FAILED';

export const MORE_GET_NOTIFICATION_LOADING = 'MORE_GET_NOTIFICATION_LOADING';
export const MORE_GET_NOTIFICATION = 'MORE_GET_NOTIFICATION';
export const MORE_GET_NOTIFICATION_FAILED = 'MORE_GET_NOTIFICATION_FAILED';

export const GET_SEARCH_HISTORY = 'GET_SEARCH_HISTORY';
export const GET_SEARCH_HISTORY_FAILED = 'GET_SEARCH_HISTORY_FAILED';

export const GET_FILTER_LOADING = 'GET_FILTER_LOADING';
export const GET_FILTER = 'GET_FILTER';
export const GET_FILTER_FAILED = 'GET_FILTER_FAILED';

export const RESET_FILTER = 'RESET_FILTER';

export const MORE_GET_FILTER_LOADING = 'MORE_GET_FILTER_LOADING';
export const MORE_GET_FILTER = 'MORE_GET_FILTER';
export const MORE_GET_FILTER_FAILED = 'MORE_GET_FILTER_FAILED';

export const GET_FILTER_LOADING2 = 'GET_FILTER_LOADING2';
export const GET_FILTER2 = 'GET_FILTER2';
export const GET_FILTER_FAILED2 = 'GET_FILTER_FAILED2';

export const MORE_GET_FILTER_LOADING2 = 'MORE_GET_FILTER_LOADING2';
export const MORE_GET_FILTER2 = 'MORE_GET_FILTER2';
export const MORE_GET_FILTER_FAILED2 = 'MORE_GET_FILTER_FAILED2';


export const RESET_STORE = 'RESET_STORE';
export const STORE_RELOADED = 'STORE_RELOADED';

export const RESET_FILTER_SUCCESS = 'RESET_FILTER_SUCCESS';


export const GET_POINTS_LOADING = 'GET_POINTS_LOADING';
export const GET_POINTS_SUCCESS = 'GET_POINTS_SUCCESS';
export const GET_POINTS_FAILED = 'GET_POINTS_FAILED';

export const GET_STATEMENTS_LOADING = 'GET_STATEMENTS_LOADING';
export const GET_STATEMENTS_SUCCESS = 'GET_STATEMENTS_SUCCESS';
export const GET_STATEMENTS_FAILED = 'GET_STATEMENTS_FAILED';

export const MORE_GET_STATEMENTS_LOADING = 'MORE_GET_STATEMENTS_LOADING';
export const MORE_GET_STATEMENTS_SUCCESS = 'MORE_GET_STATEMENTS_SUCCESS';
export const MORE_GET_STATEMENTS_FAILED = 'MORE_GET_STATEMENTS_FAILED';

export const GET_BRANDS_LOADING = 'GET_BRANDS_LOADING';
export const GET_BRANDS_SUCCESS = 'GET_BRANDS_SUCCESS';
export const GET_BRANDS_FAILED = 'GET_BRANDS_FAILED';

export const SEND_OTP_LOADING = 'SEND_OTP_LOADING';
export const SEND_OTP_SUCCESS = 'SEND_OTP_SUCCESS';
export const SEND_OTP_FAILED = 'SEND_OTP_FAILED';

export const VERIFY_OTP_LOADING = 'VERIFY_OTP_LOADING';
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS';
export const VERIFY_OTP_FAILED = 'VERIFY_OTP_FAILED';

export const REMOVE_VERIFY_SNACK = 'REMOVE_VERIFY_SNACK';

export const CHANGE_PASSWORD_LOADING = 'CHANGE_PASSWORD_LOADING';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED';

export const GET_NOTIFICATION_COUNT = 'GET_NOTIFICATION_COUNT';
export const GET_NOTIFICATION_COUNT_SUCCESS = 'GET_NOTIFICATION_COUNT_SUCCESS';
export const GET_NOTIFICATION_COUNT_FAILED = 'GET_NOTIFICATION_COUNT_FAILED';


export const REGISTER_TOKEN = 'REGISTER_TOKEN';
export const LAST_LOGIN = 'LAST_LOGIN';
export const SET_CUSTOMER_STORE = 'SET_CUSTOMER_STORE';

export const GET_FAQS_LOADING = 'GET_FAQS_LOADING';
export const GET_FAQS_SUCCESS = 'GET_FAQS_SUCCESS';
export const GET_FAQS_FAILED = 'GET_FAQS_FAILED';

export const GET_ABOUT_LOADING = 'GET_ABOUT_LOADING';
export const GET_ABOUT_SUCCESS = 'GET_ABOUT_SUCCESS';
export const GET_ABOUT_FAILED = 'GET_ABOUT_FAILED';

export const GET_POINT_TERMS_LOADING = 'GET_POINT_TERMS_LOADING';
export const GET_POINT_TERMS_SUCCESS = 'GET_POINT_TERMS_SUCCESS';
export const GET_POINT_TERMS_FAILED = 'GET_POINT_TERMS_FAILED';

export const GET_PRIVACY_LOADING = 'GET_PRIVACY_LOADING';
export const GET_PRIVACY_SUCCESS = 'GET_PRIVACY_SUCCESS';
export const GET_PRIVACY_FAILED = 'GET_PRIVACY_FAILED';

export const SUPPORT_URL = 'SUPPORT_URL';

export const CHANGE_PICTURE_LOADING = 'CHANGE_PICTURE_LOADING';
export const CHANGE_PICTURE_SUCCESS = 'CHANGE_PICTURE_SUCCESS';
export const CHANGE_PICTURE_FAILED = 'CHANGE_PICTURE_FAILED';

export const SEND_RESET_OTP_LOADING = 'SEND_RESET_OTP_LOADING';
export const SEND_RESET_OTP_SUCCESS = 'SEND_RESET_OTP_SUCCESS';
export const SEND_RESET_OTP_FAILED = 'SEND_RESET_OTP_FAILED';

export const VERIFY_RESET_OTP_LOADING = 'VERIFY_RESET_OTP_LOADING';
export const VERIFY_RESET_OTP_SUCCESS = 'VERIFY_RESET_OTP_SUCCESS';
export const VERIFY_RESET_OTP_FAILED = 'VERIFY_RESET_OTP_FAILED';

export const RESET_PASSWORD_LOADING = 'RESET_PASSWORD_LOADING';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';


export const DASHBOARD_LOADING = 'DASHBOARD_LOADING';
export const DASHBOARD_SUCCESS = 'DASHBOARD_SUCCESS';
export const DASHBOARD_FAILED = 'DASHBOARD_FAILED';

export const GET_FILTER_VALUES_LOADING = 'GET_FILTER_VALUES_LOADING';
export const GET_FILTER_VALUES_SUCCESS = 'GET_FILTER_VALUES_SUCCESS';
export const GET_FILTER_VALUES_FAILED = 'GET_FILTER_VALUES_FAILED';

export const INITIAL_OTP = 'INITIAL_OTP';
export const INITIAL_PROFILE = 'INITIAL_PROFILE';

export const IS_EXISTING_STORE_LOADING = 'IS_EXISTING_STORE_LOADING';
export const IS_EXISTING_STORE_SUCCESS = 'IS_EXISTING_STORE_SUCCESS';
export const IS_EXISTING_STORE_FAILED = 'IS_EXISTING_STORE_FAILED';
export const IS_EXISTING_STORE_FAILED2 = 'IS_EXISTING_STORE_FAILED2';


export const RESET = 'RESET';
export const CHANGE_NOTIFICATION_STATUS = 'CHANGE_NOTIFICATION_STATUS';
export const LAST_LOAD_DATE = 'LAST_LOAD_DATE';



//Phase2
export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const CLEAR_CART = 'CLEAR_CART';


export const REFRESH_CART_LOADING = 'REFRESH_CART_LOADING';
export const REFRESH_CART = 'REFRESH_CART';
export const REFRESH_CART_FAILED = 'REFRESH_CART_FAILED';


export const ADD_LOCATION_LOADING = 'ADD_LOCATION_LOADING';
export const ADD_LOCATION = 'ADD_LOCATION';
export const ADD_LOCATION_FAILED = 'ADD_LOCATION_FAILED';

export const GET_LOCATIONS_LOADING = 'GET_LOCATIONS_LOADING';
export const GET_LOCATIONS = 'GET_LOCATIONS';
export const GET_LOCATIONS_FAILED = 'GET_LOCATIONS_FAILED';

export const GET_SAVED_CARDS_LOADING = 'GET_SAVED_CARDS_LOADING';
export const GET_SAVED_CARDS = 'GET_SAVED_CARDS';
export const GET_SAVED_CARDS_FAILED = 'GET_SAVED_CARDS_FAILED';

export const SET_DEFAULT_LOCATION = 'SET_DEFAULT_LOCATION';

export const GET_SLOTS_LOADING = 'GET_SLOTS_LOADING';
export const GET_SLOTS = 'GET_SLOTS';
export const GET_SLOTS_FAILED = 'GET_SLOTS_FAILED';

export const GET_AVAILABILITIES_LOADING = 'GET_AVAILABILITIES_LOADING';
export const GET_AVAILABILITIES = 'GET_AVAILABILITIES';
export const GET_AVAILABILITIES_FAILED = 'GET_AVAILABILITIES_FAILED';

export const GET_DELIVERY_MODS_LOADING = 'GET_DELIVERY_MODS_LOADING';
export const GET_DELIVERY_MODS = 'GET_DELIVERY_MODS';
export const GET_DELIVERY_MODS_FAILED = 'GET_DELIVERY_MODS_FAILED';

export const SET_DELIVERY_SLOT = 'SET_DELIVERY_SLOT';
export const RESET_DELIVERY_SLOT = 'RESET_DELIVERY_SLOT';

export const SET_DELIVERY_MODE = 'SET_DELIVERY_MODE';

export const PLACE_ORDER_LOADING = 'PLACE_ORDER_LOADING';
export const PLACE_ORDER = 'PLACE_ORDER';
export const PLACE_ORDER_FAILED = 'PLACE_ORDER_FAILED';


export const FINALIZE_PAYMENT_LOADING = 'FINALIZE_PAYMENT_LOADING';
export const FINALIZE_PAYMENT = 'FINALIZE_PAYMENT';
export const FINALIZE_PAYMENT_FAILED = 'FINALIZE_PAYMENT_FAILED';

export const GET_ORDERS_LOADING = 'GET_ORDERS_LOADING';
export const GET_ORDERS = 'GET_ORDERS';
export const GET_ORDERS_FAILED = 'GET_ORDERS_FAILED';

export const GET_ORDER_LOADING = 'GET_ORDER_LOADING';
export const GET_ORDER = 'GET_ORDER';
export const GET_ORDER_FAILED = 'GET_ORDER_FAILED';


export const GET_CANCEL_REASONS_LOADING = 'GET_CANCEL_REASONS_LOADING';
export const GET_CANCEL_REASONS = 'GET_CANCEL_REASONS';
export const GET_CANCEL_REASONS_FAILED = 'GET_CANCEL_REASONS_FAILED';

export const CANCEL_ORDER_LOADING = 'CANCEL_ORDER_LOADING';
export const CANCEL_ORDER = 'CANCEL_ORDER';
export const CANCEL_ORDER_FAILED = 'CANCEL_ORDER_FAILED';

export const RESCHEDULE_ORDER_LOADING = 'RESCHEDULE_ORDER_LOADING';
export const RESCHEDULE_ORDER = 'RESCHEDULE_ORDER';
export const RESCHEDULE_ORDER_FAILED = 'RESCHEDULE_ORDER_FAILED';


export const ADD_REVIEW_LOADING = 'ADD_REVIEW_LOADING';
export const ADD_REVIEW = 'ADD_REVIEW';
export const ADD_REVIEW_FAILED = 'ADD_REVIEW_FAILED';

export const GET_FAST_DELIVERY_LOADING = 'GET_FAST_DELIVERY_LOADING';
export const GET_FAST_DELIVERY = 'GET_FAST_DELIVERY';
export const GET_FAST_DELIVERY_FAILED = 'GET_FAST_DELIVERY_FAILED';

export const GET_MINIMUM_CART_LOADING = 'GET_MINIMUM_CART_LOADING';
export const GET_MINIMUM_CART = 'GET_MINIMUM_CART';
export const GET_MINIMUM_CART_FAILED = 'GET_MINIMUM_CART_FAILED';


export const GET_DELIVERY_AVAILABILITY_LOADING = 'GET_DELIVERY_AVAILABILITY_LOADING';
export const GET_DELIVERY_AVAILABILITY = 'GET_DELIVERY_AVAILABILITY';
export const GET_DELIVERY_AVAILABILITY_FAILED = 'GET_DELIVERY_AVAILABILITY_FAILED';

export const REFRESH_ORDER_LOADING = 'REFRESH_ORDER_LOADING';
export const REFRESH_ORDER = 'REFRESH_ORDER';
export const REFRESH_ORDER_FAILED = 'REFRESH_ORDER_FAILED';


export const RESET_SUCCESS = 'RESET_SUCCESS';