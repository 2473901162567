import React, { Component } from 'react';
import { connect } from 'react-redux';
import { resetCart } from '../../actions/cart';

import FullWidth from '../../components/basic/FullWidth';
import InvoiceCard from '../../components/custom/InvoiceCard';
import ActivityIndicator from '../../components/loaders/ActivityIndicator';
import AccordionCheckout from './CheckoutAccordion';

class Checkout extends Component {
    state = {  }

    onPay = ()=>{

    }

    componentDidMount(){
    }

    componentDidUpdate(prevProps){
        if(!this.props.loading && prevProps.loading && this.props.success){
            if(this.props.order ){
                const pro = this.props
                if(this.props.order.payment_mode == 1 && this.props.order.transaction_id){
                    // var options = {
                    //     description: 'Wild Harbour Purchase',
                    //     name: 'Wild Harbour',
                    //     key: 'rzp_live_z2vbRjMsdxovPh',
                    //     currency: this.props.order.store.currency.currency_iso_code,
                    //     order_id: this.props.order.transaction_id,//Replace this with an order_id created using Orders API.
                    //     amount: this.props.order.payment_amount,
                    //     handler: function (response){
                    //         pro.history.push('/order-success')
                    //         pro.onResetCart()
                    //     },
                    //     prefill: {
                    //         email: this.props.user.email,
                    //         contact: this.props.user.mobile,
                    //         name: this.props.user.first_name
                    //     },
                    //     theme: {
                    //         "color": "#103c3b"
                    //     }
                    // };
                    // var rzp1 = new Razorpay(options);
                    // rzp1.open();
                }else{
                    pro.history.push('/order-success')
                    pro.onResetCart()
                }
            }
        }
    }

    render() { 
        const { loading } = this.props
        return ( 
            <FullWidth SectionClass="cart-wrapper pb-100">
                <ActivityIndicator loading={loading} fullScreen/>
                <div className="row">
                    <div className="col-md-8">
                        <div className="checkout-wrapper">
                            <AccordionCheckout/>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <InvoiceCard/>
                    </div>
                </div>

            </FullWidth>  
        );
    }
}


const mapStateToProps = state => ({
    loading: state.cartReducer.loading,
    success: state.cartReducer.success,
    order: state.cartReducer.order,
    user: state.userReducer.user,
});

const mapDispatchToProps = dispatch => ({
    onResetCart:()=>{
        dispatch(resetCart())
    }
});
  
export default connect(mapStateToProps, mapDispatchToProps) (Checkout);