import * as Actions from '../constants/ActionTypes';

const initialState ={
    cart:[],
    total:0,
    full_amount:0,
    discount:0,
    vat: 0,
    date:null,
    time:null,
    slot:null,
    mode: 3,
    minimum_amount: 25,
    offer: 0,
    fast_delivery: true,
    normal_delivery: true,
    take_away: true,
    reflector: false,
    fast_delivery_charge: 0,
    available: false,
    fast_time: 30,
    cost: 0,
    normal_delivery_charge: 0,
    slotObj: null
}

const getTotal = (cart, field)=>{
    var total = 0
    for(var i=0; i<cart.length; i++){
        var sub = cart[i][field] * cart[i]['count']
        total += sub
    }
    return total.toFixed(2)
}

const getFullVat = (cart)=>{
    var total = 0
    for(var i=0; i<cart.length; i++){
        if(cart[i]['vat']){
            var val = (cart[i]['vat'] * cart[i]['selling_price'])/100
            var sub = val * cart[i]['count']
            total += sub
        }
    }
    return total.toFixed(2)
}


function addItem(cart, item, count){
    var found = false
    for(var i=0; i<cart.length;i++){
        var cartItem = cart[i];
        if(item.id == cartItem.id){
            cartItem['count'] = count
            found = true
        }
    }

    if(cart.length < 1){
        item['count'] = count
        cart.push(item)
    }else{
        if(!found){
            item['count'] = count
            cart.push(item)
        }
    }
    return cart
}

function removeItem(cart, item, count){
    for(var i=0; i<cart.length;i++){
        var cartItem = cart[i];
        if(item.id == cartItem.id){
            if(cartItem['count'] > 1){
                cartItem['count'] = count
            }else{
                cart.splice(i, 1);
                cart = cart.filter((el)=>{
                    return el !=null && typeof el != 'undefined'
                })
            }
        }
    }
    return cart
}

function cartRefresh( oldCart, newCart){
    
    for(var i=0; i<oldCart.length;i++){
        var oldItem = oldCart[i]
        var count = oldItem['count']
        for(var j=0; j<newCart.length;j++){
            var newItem = newCart[j]
            var stock = newItem['stock']
            if(stock < count){
                oldItem['count'] = stock
            }
            if(oldItem.id == newItem.id){
                oldItem['selling_price'] = newItem.selling_price
                oldItem['discount'] = newItem.discount
                oldItem['byPerc'] = newItem.byPerc
                oldItem['disc_price'] = newItem.disc_price
                oldItem['stock'] = stock
            }
        }
    }
    
    return oldCart;
}

export default function cartReducer(state = initialState , action){
    switch(action.type){
        case Actions.ADD_TO_CART:
            var newCart =  addItem(state.cart, action.item, action.count)
            var total = getTotal(newCart, 'disc_price')
            var discount = getTotal(newCart, 'discount')
            var full_amount = getTotal(newCart, 'selling_price')
            var vat = getFullVat(newCart)
            return{
                ...state,
                cart: newCart,
                full_amount:full_amount,
                discount:discount,
                total:total,
                vat:vat,
                reflector: !state.reflector
            }
        case Actions.REMOVE_FROM_CART:
            var newCart =  removeItem(state.cart, action.item, action.count)
            var total = getTotal(newCart, 'disc_price')
            var discount = getTotal(newCart, 'discount')
            var full_amount = getTotal(newCart, 'selling_price')
            var vat = getFullVat(newCart)
            return{
                ...state,
                cart: newCart,
                full_amount:full_amount,
                discount:discount,
                total:total,
                vat:vat,
                reflector: !state.reflector
            }

        case Actions.REFRESH_CART:
            var newCart =  cartRefresh(state.cart, action.products)
            newCart = newCart.filter((el)=> el.count)
            var total = getTotal(newCart, 'disc_price')
            var discount = getTotal(newCart, 'discount')
            var full_amount = getTotal(newCart, 'selling_price')
            var vat = getFullVat(newCart)
            return{
                ...state,
                cart: newCart,
                full_amount:full_amount,
                discount:discount,
                total:total,
                vat:vat,
                reflector: !state.reflector
            }

        case Actions.CLEAR_CART:
            return{
                ...state,
                cart: [],
                full_amount : 0,
                discount: 0,
                total: 0,
                reflector: !state.reflector,
                date:null,
                time:null,
                slot:null,
                mode: 1,
                reflector: !state.reflector,
                cost: 0,
            }

        case Actions.SET_DELIVERY_MODE:
            return{
                ...state,
                mode: action.mode,
                cost: action.charge
            }
        case Actions.SET_DELIVERY_SLOT:
            return{
                ...state,
                date: action.date,
                from: action.from,
                to: action.to,
                slot: action.slot_id,
                index: action.index,
                slotObj: action.slot,
                cost: action.slot?action.slot.shipping_charge: state.cost
            }
        case Actions.RESET_DELIVERY_SLOT:
            return{
                ...state,
                date: null,
                from: null,
                to: null,
                slot: null,
                index: null
            }

        case Actions.PLACE_ORDER_LOADING:
            return{
                ...state,
                order:null,
                loading:true,
                success:false,
                page:'payment'
            }
        case Actions.PLACE_ORDER:
            return{
                ...state,
                order:action.order,
                loading:false,
                success:true
            }
        case Actions.PLACE_ORDER_FAILED:
            return{
                ...state,
                order: null,
                loading:false,
                success:false
            }

        case Actions.FINALIZE_PAYMENT_LOADING:
            return{
                ...state,
                loading:true,
                success:false,
                page:"finalize"
            }
        case Actions.FINALIZE_PAYMENT:
            return{
                ...state,
                loading:false,
                success:true
            }
        case Actions.FINALIZE_PAYMENT_FAILED:
            return{
                ...state,
                loading:false,
                success:false
            }

        case Actions.GET_MINIMUM_CART_LOADING:
            return{
                ...state
            }
        case Actions.GET_MINIMUM_CART:
            return{
                ...state,
                minimum_amount: action.minimum_amount
            }
        case Actions.GET_MINIMUM_CART_FAILED:
            return{
                ...state,
            }

        case Actions.GET_DELIVERY_AVAILABILITY_LOADING:
            return{
                ...state,
                available: false
            }
        case Actions.GET_DELIVERY_AVAILABILITY:
            return{
                ...state,
                available: action.available
            }
        case Actions.GET_DELIVERY_AVAILABILITY_FAILED:
            return{
                ...state,
                available: false
            }

        case Actions.GET_DELIVERY_MODS_LOADING:
            return{
                ...state
            }
        case Actions.GET_DELIVERY_MODS:
            return{
                ...state,
                normal_delivery: action.normal_delivery,
                fast_delivery: action.fast_delivery,
                take_away: action.take_away,
                fast_delivery_charge: action.fast_delivery_charge,
                available: action.delivery_availability,
                fast_time: action.fast_time
            }
        case Actions.GET_DELIVERY_MODS_FAILED:
            return{
                ...state
            }

        case Actions.REFRESH_ORDER:
            var offer = action.offer
            var newCart =  cartRefresh(state.cart, action.products)
            var total = getTotal(newCart, 'disc_price')
            var discount = getTotal(newCart, 'discount')
            var full_amount = getTotal(newCart, 'selling_price')
            var vat = getFullVat(newCart)
            return{
                ...state,
                cart: newCart,
                full_amount,
                discount,
                total,
                vat,
                offer: offer?offer>0?offer:0:0,
                reflector: !state.reflector
            }
        
        case Actions.SET_STORE:
            return{
                cart:[],
                total:0,
                full_amount:0,
                discount:0,
                date:null,
                time:null,
                slot:null,
                mode: 3,
                reflector: !state.reflector
            }

        case Actions.DEFAULT_STORE:
            return {
                cart:[],
                total:0,
                full_amount:0,
                discount:0,
                date:null,
                time:null,
                slot:null,
                mode: 1,
                reflector: !state.reflector,
                cost: 0,
                fast_delivery_charge:0,
                fast_time: 30,
                available: false,
                normal_delivery_charge: 0
            };

        case Actions.RESET_SUCCESS:
            return{
                ...state,
                success:false,
                page:'',
                reflector: !state.reflector
            }
        default:
        return state
       
    }
}