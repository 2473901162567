// export const DOMAIN = "http://65.2.165.238:8082/";
// export const DOMAIN = "http://localhost:8082/";
export const DOMAIN = "https://app.wildharbour.co:8443/"

export const SUB_DOMAIN = "api/";
export const VER = "v1";
export const BASE_URL = DOMAIN+SUB_DOMAIN+VER+"/"

export const LOGIN = BASE_URL+"customer/login";
export const REGISTER = BASE_URL+"customer/register";

export const GET_PRODUCTS = BASE_URL+"get-item-products";
export const GET_CATEGORIES = BASE_URL+"get-categories";

export const GET_CHILD_CATEGORIES = BASE_URL+"get-child-categories";

export const GET_STORES = BASE_URL+"get-stores";

export const GET_SLIDE_BANNER = BASE_URL+"get-slide-banner";
export const GET_STILL_BANNER = BASE_URL+"get-still-banner";
export const GET_VOLUME_OFFERS = BASE_URL+"get-volume-offers";
export const GET_CATEGORY_OFFERS = BASE_URL+"get-category-offers";
export const GET_TODAY_DEALS = BASE_URL+"get-todays-deals";
export const GET_FANTASTIC_DEALS = BASE_URL+"get-fantastic-deals";

export const GET_BANNER_ITEMS = BASE_URL+"get-banner-items";

export const GET_CATEGORY_CHILDS = BASE_URL+"get-category-childs";

export const GET_PRODUCT = BASE_URL+"get-product";

export const GET_POLICY = BASE_URL+"get-policy";

export const GET_NOTIFICATIONS = BASE_URL+"get-notifications";

export const GET_RELATED_PRODUCTS = BASE_URL+"get-related-products";

export const SEARCH = BASE_URL+"search";

export const FILTER = BASE_URL+"filter";

export const GET_POINTS = BASE_URL+"get-points";
export const GET_STATEMENTS = BASE_URL+"get-statements";

export const GET_BRANDS = BASE_URL+"get-brands";

export const GET_NEAREST_STORE = BASE_URL+"nearest-store";
export const GET_DEFAULT_STORE = BASE_URL+"get-default-store";

export const SEND_OTP = BASE_URL+"send-otp";
export const VERIFY_OTP = BASE_URL+"verify-otp";


export const CHANGE_PASSWORD = BASE_URL+"customer/change-password";

export const GET_UNREAD_NOTIFICATION_COUNT = BASE_URL+"get-unread-notification-count";
export const REGISTER_TOKEN = BASE_URL+"register-token";
export const LAST_LOGED = BASE_URL+"last-loged";
export const SET_CUSTOMER_STORE = BASE_URL+"set-customer-store";
export const CHANGE_NOTIFICATION_SUBSCRIPTION = BASE_URL+"change-notification-subscription";

export const GET_FAQS = BASE_URL+"get-faqs";
export const GET_ABOUT = BASE_URL+"get-about";
export const GET_POINT_TERMS = BASE_URL+"get-point-terms";

export const CHANGE_PICTURE = BASE_URL+"customer/change-picture";
export const SEND_RESET_OTP = BASE_URL+"send-reset-otp";
export const VERIFY_RESET_OTP = BASE_URL+"verify-reset-otp";
export const RESET_PASSWORD = BASE_URL+"reset-password";

export const GET_DASHBOARD = BASE_URL+"customer/get-dashboard";
export const SUPPORT_URL = BASE_URL+"support-url"

export const GET_FILTER_VALUES = BASE_URL+"get-filter-values";
export const STORE_EXISTING = BASE_URL+"store-existing";
export const CHANGE_NOTIFICATION_STATUS = BASE_URL+"change-notification-status";



//phase2
export const REFRESH_CART = BASE_URL+"refresh-cart";
export const ADD_CUSTOMER_LOCATION = BASE_URL+"add-customer-location";
export const GET_CUSTOMER_LOCATIONS = BASE_URL+"get-customer-locations";

export const PLACE_ORDER = BASE_URL+"place-order";
export const GET_CUSTOMER_ORDERS = BASE_URL+"get-customer-orders";
export const GET_CUSTOMER_ORDER = BASE_URL+"get-order";
export const RESCHEDLUE_ORDER = BASE_URL+"reschedule-order";
export const CANCEL_ORDER = BASE_URL+"cancel-order";

export const GET_SLOTS = BASE_URL+"get-slots";
export const GET_STORE_AVAILABILITY = BASE_URL+"get-store-availabilities";
export const GET_CANCEL_REASONS = BASE_URL+"get-reasons";

export const ADD_REVIEW = BASE_URL+"add-review";
export const GET_FAST_DELIVERY = BASE_URL+"get-fast-delivery";

export const FINALIZE_PAYMENT = BASE_URL+"finalize-payment";
export const DELIVERY_AVAILABILIY = BASE_URL+"delivery-availability";
export const MINIMUM_CART = BASE_URL+"minimum-cart";
export const REFRESH_ORDER = BASE_URL+"refresh-order";
export const GET_SAVED_CARDS = BASE_URL+"get-saved-cards";

export const GET_DELIVERY_MODS = BASE_URL+"get-store-delivery-mods";













