import * as Actions from '../constants/ActionTypes';
import CategoryService from '../services/CategoryServices'

function getCategoryChilds(store_id,category_id,skip=null,take=null,index){
    return dispatch => {
        dispatch(request(index, skip));
        CategoryService.getCategoryChilds(store_id,category_id,skip,take)
        .then((resp) => {

            var isCategory = resp.data.isCategory;
            var isProduct = resp.data.isProduct;
            var count = resp.count;
            if(isCategory){
                var categories = resp.data.categories;
                dispatch(success(categories,isCategory,isProduct,count,skip,index));
            }else{
                var products = resp.data.products;
                dispatch(successProduct(products,isCategory,isProduct,count,skip,index));
            }
    
        }).catch((error)=>{
            dispatch(failure(error,index));
        });
    
      };
    
      function request(index, skip) { return { type: Actions.GET_CATEGORY_CHILDS_LOADING,index, skip} }
      function success(categories,isCategory,isProduct,count,skip,index) { return { type: Actions.GET_CATEGORY_CHILDS_CATEGORY, categories:categories,isCategory,isProduct,count,skip,index} }
      function successProduct(products,isCategory,isProduct,count,skip,index) { return { type: Actions.GET_CATEGORY_CHILDS_PRODUCTS, products:products,isCategory,isProduct,count,skip,index} }
      function failure(error,index) { return { type: Actions.GET_CATEGORY_CHILDS_FAILED, error,index } }
}

function getCategories(store_id,skip=null,take=null) {

    return dispatch => {
      dispatch(request());
      CategoryService.getCategories(store_id,skip,take)
      .then((resp) => {
        var date = new Date();

        var categories = resp.data;
        dispatch(success(categories,date,skip));

      }).catch((error)=>{
          dispatch(failure(error));
      });

    };

    function request() { return { type: Actions.CATEGORIES_LOADING} }
    function success(categories,date,skip) { return { type: Actions.CATEGORIES, categories:categories,date:date,skip} }
    function failure(error) { return { type: Actions.CATEGORIES_FAILURE, error } }
}

function getChildCategories(category_id, store_id,skip=null,take=null) {

    return dispatch => {
      dispatch(request());
      CategoryService.getChildCategories(category_id, store_id, skip, take)
      .then((resp) => {
        var date = new Date();

        var categories = resp.data.categories;
        dispatch(success(categories));

      }).catch((error)=>{
          dispatch(failure(error));
      });

    };

    function request() { return { type: Actions.CHILD_CATEGORIES_LOADING} }
    function success(categories) { return { type: Actions.CHILD_CATEGORIES, categories:categories} }
    function failure(error) { return { type: Actions.CHILD_CATEGORIES_FAILURE, error } }
}

function getCategoriesMore(store_id,skip=null,take=null) {

    return dispatch => {
      dispatch(request());
      CategoryService.getCategories(store_id,skip,take)
      .then((resp) => {
        var date = new Date();
        var categories = resp.data;
        dispatch(success(categories,date,skip));

      }).catch((error)=>{
          dispatch(failure(error));
      });

    };

    function request() { return { type: Actions.MORE_CATEGORIES_LOADING} }
    function success(categories,date,skip) { return { type: Actions.MORE_CATEGORIES, categories:categories,date:date,skip} }
    function failure(error) { return { type: Actions.MORE_CATEGORIES_FAILURE, error } }
}


export {
    getCategoryChilds,
    getCategories,
    getCategoriesMore,
    getChildCategories
  };