import React, { Component } from 'react';
import AImage from '../basic/AImage';

class CategoryCard extends Component {
    state = {  }
    render() { 
        const {onClick, featured_image, name} = this.props
        return ( 
            <div onClick={onClick}>
                <div className="category-box">
                    <a>
                        <AImage src={featured_image} className="img-fluid" />
                        <h3>{name}</h3>
                    </a>
                </div>
            </div>
         );
    }
}
 
export default CategoryCard;