import * as Actions from '../constants/ActionTypes';

const initialState ={
    otpSending:false,
    otpsendSuccess:false,
    error:false,
    errorMessage:'',
    customer_id:null,
    otpVerLoading:false,
    otpVerified:false,
    new_customer_id:null,
    otpError:false,
    resetLoading:false,
    resetError:false,
    resetErrorMessage:'',
    resetSuccess:false
}

export default function resetPasswordReducer(state = initialState , action){
    switch(action.type){
        case Actions.SEND_RESET_OTP_LOADING:
            return{
                otpsendSuccess:false,
                otpSending:true
            }
        case Actions.SEND_RESET_OTP_SUCCESS:
            return{
                otpsendSuccess:true,
                otpSending:false,
                customer_id: action.customer_id
            }
        case Actions.SEND_RESET_OTP_FAILED:
            return{
                otpsendSuccess:false,
                otpSending:false,
                error: true,
                errorMessage: action.error
            }
            case Actions.RESET_STORE:
            return {
                
            };
        case Actions.INITIAL_OTP:
            return {
                otpSending:false,
                otpsendSuccess:false,
                error:false,
                errorMessage:'',
                customer_id:null,
            };

        case Actions.VERIFY_RESET_OTP_LOADING:
            return{
                otpVerified:false,
                otpVerLoading:true
            }
        case Actions.VERIFY_RESET_OTP_SUCCESS:
            return{
                otpVerified:true,
                otpVerLoading:false,
                new_customer_id: action.customer_id,
                otp:action.otp

            }
        case Actions.VERIFY_RESET_OTP_FAILED:
            return{
                otpVerified:false,
                otpVerLoading:false,
                otpError: true,
                otpErrorMessage: action.error
            }
            case Actions.RESET_STORE:
            return {
                
            }; 
            
        case Actions.RESET_PASSWORD_LOADING:
            return{
                resetSuccess:false,
                resetLoading:true
            }
        case Actions.RESET_PASSWORD_SUCCESS:
            return{
                resetLoading:false,
                resetSuccess:true
            }
        case Actions.RESET_PASSWORD_FAILED:
            return{
                resetLoading:false,
                resetSuccess:false,
                resetError: true,
                resetErrorMessage: action.error
            }
        case Actions.RESET_STORE:
        return {
            
        };   
   
        default:
        return state
    }
}