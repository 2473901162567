import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import Login from './views/login/Login'; 
import Register from './views/login/SignUp'; 
import UILayout from './views/index';
import ForgotPassword from './views/login/ForgotPassword';
import VerifyOTP from './views/login/VerifyOTP';
import ResetPassword from './views/login/ResetPassword';

const history = createBrowserHistory() 

class App extends Component {
  render() {
    return (      
      <Router history ={history} onUpdate={() => window.scrollTo(0, 0)}>
        <Switch>
          <Route path="/login" component={Login} />  
          <Route exact path="/signup" component={Register} />  
          <Route exact path="/forgot-password" component={ForgotPassword} />  
          <Route exact path="/reset-password/:id/:otp" component={ResetPassword} />  
          <Route exact path="/verify-otp/:id/:type" component={VerifyOTP} />  
          <Route  path="/" component={UILayout} />
        </Switch>
      </Router>
    );
  }
}
 
export default App;