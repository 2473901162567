import React, { Component } from 'react';
import {Radio } from 'semantic-ui-react'
import AddAdressModal from './AddAddressModal';

class DeliveryAddressPicker extends Component {

    render() { 
        const { locations, onChange, selected } = this.props
        return ( 
              <div className="savedAdress">
                <div className="row">
                    {locations && locations.map((location, index)=>(
                        <div className="col-md-6" key={index}>
                            <div className="address-box">
                                    <div className="action-area">
                                        <Radio
                                            label={location.villa}
                                            name='radioGroup'
                                            value={location.id}
                                            checked={selected? selected.id === location.id : false}
                                            onChange={()=>onChange?onChange(location):null}/>
                                    </div>
                                <div className="large-text">
                                    <p>{location.title}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                    {(selected && !selected.id)&&
                    <div className="col-md-6" >
                        <div className="address-box">
                                <div className="action-area">
                                    <Radio
                                        label={selected.villa}
                                        name='radioGroup'
                                        checked={true}
                                        onChange={()=>onChange?onChange(selected):null}/>
                                </div>
                            <div className="large-text">
                                <p>{selected.title}</p>
                            </div>
                        </div>
                    </div>
                    }
                </div>
                <AddAdressModal/>
            </div>
        );
    }
}
 
export default DeliveryAddressPicker;
