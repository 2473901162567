import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withSnackbar } from 'react-simple-snackbar';
import { Accordion, Icon } from 'semantic-ui-react'
import { deliveryAvailability, minimumCart, placeOrder } from '../../actions/cart';
import { getDeliveryMods } from '../../actions/store';

import { changeSelectedLocation, getLocations } from '../../actions/user';
import DeliveryAddressPicker from '../../components/custom/DeliveryAddressPicker';
import DeliverySlotPicker from '../../components/custom/DeliverySlotPicker';
import PaymentPicker from '../../components/custom/PaymentPicker';

class AccordionCheckout extends Component {
  state = { activeIndex: 1 }
  
  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
  }

  onChangeLocation = (location)=>{
    this.props.onChangeLocation(location)
    if(this.props.store){
      this.props.onGetDeliveryMods(
        this.props.store.id,
        location.latitude,
        location.longitude
      )
    }
  }

  componentDidMount(){
    const {user, location, store} = this.props
    if(user){
      this.props.onGetLocations(user.id)
    }
    if(store){

    }
  }

  placeOrder = (payment_mode)=>{
    const {
      cart, 
      from, 
      to, 
      date, 
      mode, 
      slot, 
      store, 
      user, 
      location, 
      cost, 
      offer, 
      total, 
      minimum_amount, 
      available,
      openSnackbar,
      discount
    } = this.props

    if(mode === 1 || mode === 2 ){
      if(!location){
        // choose a location for delivery
        openSnackbar( "Choose a delivery location")
        return
      }
      if(!available){
        //delivery not available on selected location
        openSnackbar( "Delivery not available on selected location")
        return
      }
      if(parseFloat(total) < parseFloat(store.min_amount)){
        //cart amount should be minimum
        openSnackbar( "Cart amount should be minimum "+store.currency.symbol +' ' + store.min_amount)
        return
      }
    }

    if(mode === 1 || mode === 3 || !mode){
      if(!from || !to || !date){
        //please choose a slot
        openSnackbar( "Please choose a valid delivery slot")
        return
      }
    }

    var new_date = new Date()
    if(mode !=2 ){
        var parts =date.split('-');
        new_date = parts[2] +'-'+ parts[1] +'-'+ parts[0]
    }

    var data = {
      products: cart,
      customer_id: user.id,
      store_id: store.id,
      from: from,
      to: to,
      date: new_date,
      delivery_mode: mode,
      payment_mode: payment_mode,
      slot: slot,
      delivery_address: location.title,
      latitude: location.latitude,
      longitude: location.longitude,
      delivery_charge:cost,
      offer: discount
    }
    this.props.onPlaceOrder(data)
  }

  render() {
    const { activeIndex } = this.state
    const { 
      locations, 
      location,
      fast_delivery,
      normal_delivery,
      take_away,
      total,
      store,
      cost
     } = this.props

    return (
      <Accordion fluid styled>
        <Accordion.Title
          active={activeIndex === 1}
          index={1}
          onClick={this.handleClick}>
          <Icon name='map marker alternate' size={22}/>
          Delivery Location
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 1}>
            <DeliveryAddressPicker 
              onChange={this.onChangeLocation}
              selected={location}
              locations={locations}/>
        </Accordion.Content>

        <Accordion.Title
          active={activeIndex === 2}
          index={2}
          onClick={this.handleClick}
        >
          <Icon name='truck' size={22}/>
          Delivery Type
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 2}>
            <DeliverySlotPicker 
              fastDelivery={fast_delivery} 
              normalDelivery={normal_delivery} 
              takeAway={take_away}/>
        </Accordion.Content>

        <Accordion.Title
          active={activeIndex === 3}
          index={3}
          onClick={this.handleClick}
        >
          <Icon name='payment' size={22}/>
          Payment
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 3}>
            <PaymentPicker amount={cost?parseFloat(total) + parseFloat(cost): total} onPlace={this.placeOrder} store={store} />
        </Accordion.Content>
      </Accordion>
    )
  }
}

const mapStateToProps = state => ({
  fast_delivery: state.cartReducer.fast_delivery,
  normal_delivery: state.cartReducer.normal_delivery,
  take_away: state.cartReducer.take_away,
  available: state.cartReducer.available,
  minimum_amount: state.cartReducer.minimum_amount,
  cart: state.cartReducer.cart,
  total : state.cartReducer.total,
  store : state.storeReducer.store,
  loggedIn : state.userReducer.loggedIn,
  user: state.userReducer.user,
  location: state.userReducer.location,
  locations: state.userReducer.locations,
  from: state.cartReducer.from,
  to: state.cartReducer.to,
  date: state.cartReducer.date,
  index: state.cartReducer.index,
  cost: state.cartReducer.cost,
  fast_time: state.cartReducer.fast_time,
  mode: state.cartReducer.mode,
  slot: state.cartReducer.slot,
  offer: state.cartReducer.offer,
  discount: state.cartReducer.discount,
  loading: state.cartReducer.loading,
  success: state.cartReducer.success,
});

const mapDispatchToProps = dispatch => ({
  onGetLocations:(customer_id)=>{
      dispatch(getLocations(customer_id))
  },
  onChangeLocation:(location)=>{
    dispatch(changeSelectedLocation(location))
  },
  onCheckDeliveryAvailability:(store_id, location_id)=>{
    dispatch(deliveryAvailability(store_id, location_id))
  },
  onPlaceOrder:(data)=>{
    dispatch(placeOrder(data))
  },
  onGetDeliveryMods:(store_id, latitude, longitude)=>{
    dispatch(getDeliveryMods(store_id, latitude, longitude))
}
 
});

export default withSnackbar(connect(mapStateToProps, mapDispatchToProps) (AccordionCheckout));