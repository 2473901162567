import * as Actions from '../constants/ActionTypes';

const initialState ={
  loggedIn:false,
  loggingIn:false,
  signuping:false,
  token:'',
  error:false,
  signUpError:false,
  notificationLoading: false,
  moreNotificationLoading:false,
  notificationsCount:0,
  sendOtpLoading:false,
  verifyOtpLoading:false,
  fromVerified:false,
  signupSuccess:false,
  fromLogin:false,
  verified:true,
  otpError:false,
  otpErrorMessage:'',
  changePictureLoading:false,
  loading: false,
  locations:[],
  location: null,
  cards: []
}

export default function userReducer(state = initialState , action){
  switch(action.type){

    
    case Actions.CHANGE_NOTIFICATION_STATUS:
    return {
      ...state
    };
    case Actions.INTIAL_STAGE:
     return {
       ...state,
       loggedIn: false,
       loggingIn:false,
       error:false,
     };
     case Actions.REMOVE_SNACKBAR:
       return {
        ...state,
         loggingIn:false,
         loggedIn: false,
         error:false,
      };


    case Actions.LOGIN_REQUEST:
     return {
      ...state,
       loading:true,
       loggingIn: true,
       user: action.user,
       token: action.token
     };
   case Actions.LOGIN_SUCCESS:
     return {
       loggingIn:false,
       loading:false,
       loggedIn: true,
       user: action.user,
       fromLogin:true,
       token:action.token
     };
   case Actions.LOGIN_FAILURE:
     return {
      loading:false,
       loggingIn:false,
       loggedIn: false,
       error:true,
       errorMessage:action.error
     };

     case Actions.LOGIN_SUCCESS_NOT_VERIFIED:
     return {
       loggingIn:false,
       loggedIn: false,
       user:action.user,
       verified:false
     };

     
   case Actions.LOGOUT:
     return {
       loggedIn:false,
       loggingIn:false,
       signuping:false,
       token:''
     };

     case Actions.GET_NOTIFICATION_LOADING:
     return {
       ...state,
       notifications:[],
       notificationLoading: true
     };
    case Actions.GET_NOTIFICATION:

      var notifications = action.notifications
      var skip = action.skip
      if(skip){
        notifications = state.notifications.concat(notifications)
      }

     return {
       ...state,
       notificationLoading : false,
       notifications: notifications,
       notificationsCount: action.count
     };
 
    case Actions.GET_NOTIFICATION_FAILED:
     return {
       ...state,
       notifications:[],
       notificationLoading: false,
     };



     case Actions.SEND_OTP_LOADING:
     return {
       ...state,
       sendOtpLoading: true
     };
    case Actions.SEND_OTP_SUCCESS:
     return {
      ...state,
       sendOtpLoading : false
     };
 
    case Actions.SEND_OTP_FAILED:
     return {
      ...state,
       sendOtpLoading: false,
     };


     case Actions.CHANGE_PICTURE_LOADING:
     return {
       ...state,
       loggedIn: true,
       changePictureLoading: true
     };
    case Actions.CHANGE_PICTURE_SUCCESS:
     return {
      ...state,
      user:action.user,
      loggedIn: true,
      changePictureLoading : false
     };
 
    case Actions.CHANGE_PICTURE_FAILED:
     return {
      ...state,
      loggedIn: true,
      changePictureLoading: false,
     };

     case Actions.INITIAL_PROFILE:
     return {
      ...state,
      changePictureLoading: false,
     };

     


     case Actions.VERIFY_OTP_LOADING:
     return {
      ...state,
      otpError:false,
      fromVerified:false,
      otpErrorMessage:'',
       verifyOtpLoading: true
     };
    case Actions.VERIFY_OTP_SUCCESS:
     return {
      ...state,
       verifyOtpLoading : false,
       fromVerified:true,
       loggedIn: true,
       otpError:false,
       user: action.user,
       otpErrorMessage:'',
       token:action.token
     };
 
    case Actions.VERIFY_OTP_FAILED:
     return {
      ...state,
      fromVerified:false,
      otpError:true,
      verifyOtpLoading: false,
      otpErrorMessage:action.error
    };

    case Actions.ADD_LOCATION_LOADING:
     return {
      ...state,
      loading: true,
      success:false
     };
    case Actions.ADD_LOCATION:
     return {
      ...state,
      loading: false,
      location: action.location,
      success:true
     };
 
    case Actions.ADD_LOCATION_FAILED:
     return {
      ...state,
      loading: false,
      success:false
    };

    case Actions.GET_LOCATIONS_LOADING:
      return {
       ...state,
       loading: true
      };
     case Actions.GET_LOCATIONS:
      return {
       ...state,
       loading: false,
       locations:action.locations
      };
  
     case Actions.GET_LOCATIONS_FAILED:
      return {
       ...state,
       loading: false
     };

     case Actions.GET_SAVED_CARDS_LOADING:
      return {
       ...state,
       loading: true
      };
     case Actions.GET_SAVED_CARDS:
      return {
       ...state,
       loading: false,
       cards:action.cards
      };
  
     case Actions.GET_SAVED_CARDS_FAILED:
      return {
       ...state,
       loading: false
     };

     case Actions.SET_DEFAULT_LOCATION:
      return {
       ...state,
       location: action.location
     };


    case Actions.REMOVE_VERIFY_SNACK:
    return {
     ...state,
     fromVerified:false,
     otpError:false,
     verifyOtpLoading: false,
     otpErrorMessage:''
   };



   default:
     return state

  }
}
