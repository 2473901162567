import React from 'react'
import Drawer from '@material-ui/core/Drawer';

export default function ADrawer({
    anchor="left",
    open,
    onClose,
    children
}){

    return(
        <Drawer anchor={anchor} open={open} onClose={onClose}>
            {children}
        </Drawer>
    )
}