import React, { Component } from 'react';
import Slider from "react-slick";

import CategoryCard from '../../components/custom/CategoryCard';
const { innerWidth: width, innerHeight: height } = window;

const SETTINGS = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

class CategorySlider extends Component {

  onClickCategory = (id)=>{
    const {onPress} = this.props
    if(onPress){
      onPress(id)
    }else{
      this.props.history.push('/category/'+id)
    }
  }

  render() { 
    const {categories} = this.props

    if(!categories || categories.length <1) return null
      
    return ( 
      <div className="categories">
        <Slider {...SETTINGS}>
            {categories.map((category, index)=>(
              <CategoryCard 
                onClick={()=>this.onClickCategory(category.id)} 
                key={index} 
                {...category}/>
            ))}
        </Slider>   
      </div>
    );
  }
}
 
export default CategorySlider;