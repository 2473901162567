import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import { withSnackbar } from 'react-simple-snackbar'

import './Login.css';
import FormWrap from './FormWrap';
import AInput from '../../components/basic/AInput';
import { sendOTP, verifyOTP } from '../../actions/user';
import ActivityIndicator from '../../components/loaders/ActivityIndicator';
import { verifyResetOtp } from '../../actions/general';

class VerifyOtp extends Component {
  constructor(props){
    super(props);
    this.state = {
        otp : '',
    }
    this.validator = new SimpleReactValidator();
  }

  verifyOtpSubmit = (e)=>  {
    e.preventDefault();
    if( this.validator.allValid() ){
        const id = this.props.match.params.id
        const type = this.props.match.params.type
        if(type === 'verify'){
          this.props.onVerifyOTP(id, this.state.otp);
        }else{
          this.props.onVerifyResetOTP(id, this.state.otp);
        }
    }
    else {
      this.validator.showMessages();
      this.forceUpdate();
    } 
  } 

  componentDidMount(){
    const id = this.props.match.params.id
    const device = 'web_'+id
    this.props.onSendOTP(id, device)
  }

  componentDidUpdate(prevProps) {
    if(!this.props.loading && prevProps.loading){
      if(this.props.loggedIn && this.props.verified){
        this.props.history.push('/')
      }else{
        this.props.openSnackbar( "OTP verification failed")
      }
    }
    if(!this.props.resetLoading && prevProps.resetLoading){
      if(this.props.reset_customer_id && this.props.resetVerified){
        this.props.history.push('/reset-password/'+this.props.reset_customer_id +'/' + this.state.otp)
      }else{
        this.props.openSnackbar( "OTP verification failed")
      }
    }
  }

  render(){
    const { loading } = this.props
    return(
      <FormWrap>
        <ActivityIndicator loading={loading} fullScreen/>
        <form className="form-vertical">
          <AInput
            name="otp"
            validator={this.validator}
            rules='required|size:4'
            type="number"
            value={this.state.otp}
            onChange={(e)=>this.setState({otp: e.target.value})}
            placeholder="OTP"
          />
          <div className="bt-wrapper">
            <button 
              style={{width:'100%'}}
              onClick ={this.verifyOtpSubmit}
              className="waves-effect waves-light btn">VERIFY OTP</button>
          </div>
          <div className="signup-here text-small">
            <br/>  <Link className="d-block large-text" to="/login">LOGIN</Link>
          </div>
        </form>
      </FormWrap>
    )
  }
}
VerifyOtp.deaultProps = {
  error:[],
  otp_res:{}
}
const mapStateToProps = (state) => ({
    loading:state.userReducer.verifyOtpLoading ,
    loggedIn: state.userReducer.loggedIn,
    verified: state.userReducer.fromVerified,
    error: state.userReducer.otpError,
    errorMessage:state.userReducer.otpErrorMessage,
    resetVerified:state.resetPasswordReducer.otpVerified,
    resetLoading:state.resetPasswordReducer.otpVerLoading,
    reset_customer_id: state.resetPasswordReducer.new_customer_id,
})

const mapDispatchToProps = (dispatch)=> ({
  onSendOTP:(customer_id, device_id)=>{
    dispatch(sendOTP(customer_id, device_id))
  },
  onVerifyOTP:(customer_id, otp)=>{
    dispatch(verifyOTP(customer_id, otp))
  },
  onVerifyResetOTP:(customer_id,otp)=>{
    dispatch(verifyResetOtp(otp,customer_id))
  },
  // onDismiss:()=>{
  //   dispatch(onDismiss())
  // }
})
export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(VerifyOtp))




