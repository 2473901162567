import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import { connect } from 'react-redux';

import './Login.css';
import FormWrap from './FormWrap';
import SimpleReactValidator from 'simple-react-validator';
import AInput from '../../components/basic/AInput';
import { intialSignup, register } from '../../actions/user';
import ActivityIndicator from '../../components/loaders/ActivityIndicator';


class Register extends Component {

  constructor(props){
    super(props)
    this.state = {
      first_name : '',
      second_name: '',
      mobile:'',
      password:'',
      email:'',
      confirm_password:''
    }
    this.validator = new SimpleReactValidator();
  }

  onChangeText = (e)=>{
    this.setState({[e.target.name]:e.target.value})
  }

  onSave = (e)=>{
    e.preventDefault()
    if( this.validator.allValid() ){
      this.props.onRegister(
        this.state.mobile,
        this.state.password,
        this.state.confirm_password,
        this.state.first_name,
        this.state.second_name,
        this.state.email
      );
    }
    else {
      this.validator.showMessages();
      this.forceUpdate();
    } 
  }

  componentDidMount(){
    this.props.onInit()
  }

  componentDidUpdate(){
    if(this.props.signupSuccess && this.props.user){
      this.props.history.push('/verify-otp/'+this.props.user.id+'/verify')
    }
  }

  render() {
    const {
      first_name, 
      second_name, 
      email, 
      mobile, 
      password, 
      confirm_password
    } = this.state

    return(
      <FormWrap>
        <ActivityIndicator fullScreen loading={this.props.loading}/>
        <form className="form-vertical">
          <AInput
            onChange={this.onChangeText}
            name="first_name"
            value={first_name}
            validator={this.validator}
            rules='required'
            placeholder="First Name"/>
          <AInput
            onChange={this.onChangeText}
            name="second_name"
            value={second_name}
            validator={this.validator}
            rules='required'
            placeholder="Second Name"/>
          <AInput
            onChange={this.onChangeText}
            name="mobile"
            value={mobile}
            validator={this.validator}
            rules='required|phone|min:10'
            type="number"
            placeholder="Mobile Number"/>
          <AInput
            onChange={this.onChangeText}
            name="password"
            value={password}
            type="password"
            validator={this.validator}
            rules='required|min:6'
            isPassword={true}
            placeholder="Password"/>
          <AInput
            onChange={this.onChangeText}
            name="confirm_password"
            value={confirm_password}
            validator={this.validator}
            rules='required|min:6'
            type="password"
            isPassword={true}
            placeholder="Confirm Password"/>
          <AInput
            onChange={this.onChangeText}
            name="email"
            value={email}
            validator={this.validator}
            rules='email'
            placeholder="Email"/>
           <div className="signup-here text-small">
            <span>The password must be at least 6 characters. </span>
            <span>Include atleast one letter and one number.</span>
           </div>
          <div className="bt-wrapper">
            <button
              onClick={this.onSave} 
              style={{width:'100%'}}
              className="waves-effect waves-light btn">SIGNUP</button>
          </div>
          <div className="signup-here text-small">
            <span style={{textAlign:'center'}}>By tapping "SIGNUP", your indicating that your accept our Terms of Service and Privacy Policy</span>
            <br/>  <Link className="d-block text-small" to="/terms-condition">Terms of Service</Link>
            <Link className="d-block text-small" to="/privacy-policy">Privacy Policy</Link>
            <br/>  <Link className="d-block large-text" to="/login">LOGIN</Link>
          </div>
        </form>
      </FormWrap>
    )
  }
}

const mapStateToProps = state => ({
  loading:state.signupReducer.signuping ,
  loggedIn: state.userReducer.loggedIn,
  user:state.signupReducer.user,
  signUpError:state.signupReducer.signUpError,
  signupSuccess: state.signupReducer.signupSuccess,
  errorMessage2:state.signupReducer.errorMessage2,
});
const mapDispatchToProps = dispatch => ({
  onRegister: (mobile,password,c_password,first_name,second_name,email) => {
    dispatch(register(mobile,password,c_password,first_name,second_name,email));
  },
  onInit:()=>{
    dispatch(intialSignup());
  }
});
export default connect(mapStateToProps,
                        mapDispatchToProps)
                        (Register);




