import * as Actions from '../constants/ActionTypes';

const initialState ={
    brands:[],
    loading:false,
}

export default function brandReducer(state = initialState , action){
    switch(action.type){
       

        case Actions.GET_BRANDS_LOADING:
            return{
                ...state,
                loading:true
            }
        case Actions.GET_BRANDS_SUCCESS:
            var item = action.brands;
            if(action.skip !== null && action.skip > 0){
                item = item.concat(this.state.brands)
            }
            return{
                ...state,
                loading:false,
                brands: item
            }
        case Actions.GET_BRANDS_FAILED:
            return{
                ...state,
                loading:false,

            }
        case Actions.RESET_STORE:
            return {
                
            };
            
        default:
        return state
       
    }
}