import request from '../config/HttpClient'
import * as URLS from '../constants/Urls'

function getStores() {
  return request({
    url:    URLS.GET_STORES,
    method: 'GET',
  });
}

function getDefaultStore(data) {
  return request({
    url:    URLS.GET_DEFAULT_STORE,
    method: 'POST',
    data
  });
}

function getNearestStore(data){
  return request({
    url:    URLS.GET_NEAREST_STORE,
    method: 'POST',
    data
  });
}

function isStoreExisting(store_id) {
  var sub_url = '/'+store_id;
  return request({
    url:    URLS.STORE_EXISTING + sub_url,
    method: 'GET'
  });
}

function getSlots(store_id, date) {
  var sub_url = '/'+store_id+'/'+date;
  return request({
    url:    URLS.GET_SLOTS + sub_url,
    method: 'GET'
  });
}

function getAvailability(store_id, date) {
  var sub_url = '/'+store_id+'/'+date;
  return request({
    url:    URLS.GET_STORE_AVAILABILITY + sub_url,
    method: 'GET'
  });
}

function getDeliveryMods(store_id, latitude, longitude) {
  var sub_url = '/'+store_id+'/'+latitude+'/'+longitude;
  return request({
    url:    URLS.GET_DELIVERY_MODS + sub_url,
    method: 'GET'
  });
}







const StoreService = {
  getStores,
  getNearestStore,
  isStoreExisting,
  getDefaultStore,
  getSlots,
  getAvailability,
  getDeliveryMods
}


export default StoreService;
