import React, { Component, useState } from 'react'
import { useSelector } from 'react-redux'
import {Menu } from 'semantic-ui-react'

export default function CategoryMenu({
  activeTab,
  onChangeTab,
  categories,
  disableHome
}){
  const [activeItem, setActiveItem ] = useState("home")

  const handleItemClick = (name)=>{
    setActiveItem(name)
    onChangeTab(name)
  }

  return (
    <div className="menubar">
        <Menu pointing secondary>
          { !disableHome  && <Menu.Item
            name='home'
            active={activeTab === 'home'}
            onClick={()=>handleItemClick('home')}
          />}
          {categories && categories.map((category, index)=>(
            <Menu.Item
              name={category.name}
              key={index}
              active={activeTab === category.id}
              onClick={()=>handleItemClick(category.id)}
            />
          ))}
          
        </Menu>

    </div>
    )
}