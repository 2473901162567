import * as Actions from '../constants/ActionTypes';
import CartService from '../services/CartServices'

function addToCart(item, count){
    return dispatch => {
        dispatch(success(item, count));
    }; 
    function success(item, count) { return { type: Actions.ADD_TO_CART, item:item, count} } 
}

function removeFromCart(item, count){
    return dispatch => {
        dispatch(success(item, count));
    }; 
    function success(item, count) { return { type: Actions.REMOVE_FROM_CART, item:item, count} } 
}

function setDeliverySlot(date, from, to, index, slot_id=null, slot=null){
    return dispatch => {
        dispatch(success(date, from, to, index, slot_id, slot));
    }; 
    function success(date, from, to, index, slot_id, slot) { return { type: Actions.SET_DELIVERY_SLOT, date, from, to, index, slot_id, slot} } 
}

function resetSlot(){
    return dispatch => {
        dispatch(success());
    }; 
    function success() { return { type: Actions.RESET_DELIVERY_SLOT} } 
}

function setDeliveryMode(mode, charge){
    return dispatch => {
        dispatch(success(mode, charge));
    }; 
    function success(mode, charge) { return { type: Actions.SET_DELIVERY_MODE, mode, charge} } 
}

function resetCart(){
    return dispatch => {
        dispatch(success());
    }; 
    function success() { return { type: Actions.CLEAR_CART} } 
}


function refreshCart(data){
    return dispatch => {
        dispatch(request());
        CartService.cartRefresh(data)
        .then((resp) => {
          var products = resp.data;
          dispatch(success(products));
    
        }).catch((error)=>{
            dispatch(failure(error));
        });
    
      };
    
      function request() { return { type: Actions.REFRESH_CART_LOADING} }
      function success(products) { return { type: Actions.REFRESH_CART, products:products} }
      function failure(error) { return { type: Actions.REFRESH_CART_FAILED, error } }
}

function placeOrder(data){
    return dispatch => {
        dispatch(request());
        CartService.placeOrder(data)
        .then((resp) => {
          var order = resp.data;
          dispatch(success(order));
    
        }).catch((error)=>{
            dispatch(failure(error));
        });
    
      };
    
      function request() { return { type: Actions.PLACE_ORDER_LOADING} }
      function success(order) { return { type: Actions.PLACE_ORDER, order:order} }
      function failure(error) { return { type: Actions.PLACE_ORDER_FAILED, error } }
}

function finalizePayment(transaction_id){
    return dispatch => {
        dispatch(request());
        CartService.finalizePayment(transaction_id)
        .then((resp) => {
          dispatch(success());
        }).catch((error)=>{
            dispatch(failure(error));
        });
      };
    
      function request() { return { type: Actions.FINALIZE_PAYMENT_LOADING} }
      function success() { return { type: Actions.FINALIZE_PAYMENT} }
      function failure(error) { return { type: Actions.FINALIZE_PAYMENT_FAILED, error } }
}

function minimumCart(store_id){
    return dispatch => {
        dispatch(request());
        CartService.minimumCart(store_id)
        .then((resp) => {
          dispatch(success(resp.data));
        }).catch((error)=>{
            dispatch(failure(error));
        });
      };
    
      function request() { return { type: Actions.GET_MINIMUM_CART_LOADING} }
      function success(minimum_amount) { return { type: Actions.GET_MINIMUM_CART, minimum_amount} }
      function failure(error) { return { type: Actions.GET_MINIMUM_CART_FAILED, error } }
}

function deliveryAvailability(store_id, location_id){
    return dispatch => {
        dispatch(request());
        CartService.deliveryAvailability(store_id, location_id)
        .then((resp) => {
          dispatch(success(resp.data));
        }).catch((error)=>{
            dispatch(failure(error));
        });
      };
    
      function request() { return { type: Actions.GET_DELIVERY_AVAILABILITY_LOADING} }
      function success(available) { return { type: Actions.GET_DELIVERY_AVAILABILITY, available} }
      function failure(error) { return { type: Actions.GET_DELIVERY_AVAILABILITY_FAILED, error } }
}

function refreshOrder(data){
    return dispatch => {
        dispatch(request());
        CartService.refreshOrder(data)
        .then((resp) => {
          dispatch(success(resp.data.products, resp.data.offer));
        }).catch((error)=>{
            dispatch(failure(error));
        });
      };
    
      function request() { return { type: Actions.REFRESH_ORDER_LOADING} }
      function success(products, offer) { return { type: Actions.REFRESH_ORDER, products, offer} }
      function failure(error) { return { type: Actions.REFRESH_ORDER_FAILED, error } }
}

export {
    addToCart,
    removeFromCart,
    refreshCart,
    setDeliverySlot,
    setDeliveryMode,
    resetSlot,
    placeOrder,
    resetCart,
    finalizePayment,
    minimumCart,
    deliveryAvailability,
    refreshOrder
}