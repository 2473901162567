import * as Actions from '../constants/ActionTypes';
import StoreService from '../services/StoreServices';


function getStores() {
    return dispatch => {
      dispatch(request());
      StoreService.getStores()
      .then((resp) => {
        var stores = resp.data;
        dispatch(success(stores));

      }).catch((error)=>{
          dispatch(failure(error));
      });

    };

    function request() { return { type: Actions.GET_STORE_LOADING } }
    function success(stores) { return { type: Actions.STORES, stores:stores} }
    function failure(error) { return { type: Actions.STORES_FAILURE, error } }
}

function setStore(store) {
    return dispatch => {
      dispatch(success(store));
    function success(store) { return { type: Actions.SET_STORE, store:store} }
  }
}

function reset(){
  return dispatch => {
    dispatch(reset());
  function reset() { return { type: Actions.RESET_STORE} }}
}

function reload(){
  return dispatch => {
    dispatch(reload());
  function reload() { return { type: Actions.STORE_RELOADED} }}
}

function getDefaultStore(data){
  return dispatch => {
    dispatch(request());
    StoreService.getDefaultStore(data)
    .then((resp) => {
      var store = resp.data;
      dispatch(success(store));

    }).catch((error)=>{
        dispatch(failure(error));
    });

  };

  function request() { return { type: Actions.GET_DEFAULT_STORE_LOADING } }
  function success(store) { return { type: Actions.DEFAULT_STORE, store:store} }
  function failure(error) { return { type: Actions.DEFAULT_STORE_FAILURE, error } }
}

function getDefaultStore2(data){
  return dispatch => {
    dispatch(request());
    StoreService.getNearestStore(data)
    .then((resp) => {
      var store = resp.data;
      dispatch(success(store));

    }).catch((error)=>{
        dispatch(failure(error));
    });

  };

  function request() { return { type: Actions.GET_DEFAULT_STORE_LOADING2 } }
  function success(store) { return { type: Actions.DEFAULT_STORE2, store:store} }
  function failure(error) { return { type: Actions.DEFAULT_STORE_FAILURE2, error } }
}

function getNearestStore(data){
  return dispatch => {
    dispatch(request());
    StoreService.getNearestStore(data)
    .then((resp) => {
      var store = resp.data;
      dispatch(success(store));
    }).catch((error)=>{
        dispatch(failure(error));
    });

  };

  function request() { return { type: Actions.GET_NEAREST_STORE_LOADING } }
  function success(store) { return { type: Actions.NEAREST_STORE, store:store} }
  function failure(error) { return { type: Actions.NEAREST_STORE_FAILURE, error } }
}

function getNearestStore2(data){
  return dispatch => {
    dispatch(request());
    StoreService.getNearestStore(data)
    .then((resp) => {
      var store = resp.data;
      dispatch(success(store));

    }).catch((error)=>{
        dispatch(failure(error));
    });

  };

  function request() { return { type: Actions.GET_NEAREST_STORE_LOADING2 } }
  function success(store) { return { type: Actions.NEAREST_STORE2, store:store} }
  function failure(error) { return { type: Actions.NEAREST_STORE_FAILURE2, error } }
}


function isExistingStore(store_id){
  return dispatch => {
    dispatch(request());
    StoreService.isStoreExisting(store_id)
    .then((resp) => {
      var data = resp.data;
      var store = data.store;
      var old = data.old;
      dispatch(success(store,old));

    }).catch((error)=>{
      if(error.hasOwnProperty('data')){
        if(error.data.hasOwnProperty('failed')){
          dispatch(failure(error));
        }else{
          dispatch(failure2(error));
        }
      }else{
        dispatch(failure2(error));
      }
    });

  };

  function request() { return { type: Actions.IS_EXISTING_STORE_LOADING } }
  function success(store,old) { return { type: Actions.IS_EXISTING_STORE_SUCCESS, store:store, old} }
  function failure(error) { return { type: Actions.IS_EXISTING_STORE_FAILED, error } }
  function failure2(error) { return { type: Actions.IS_EXISTING_STORE_FAILED2, error } }
}

function getSlots(store_id, date){
  return dispatch => {
    dispatch(request());
    StoreService.getSlots(store_id, date)
    .then((resp) => {
      var slots = resp.data;
      dispatch(success(slots, date));

    }).catch((error)=>{
        dispatch(failure(error));
    });

  };

  function request() { return { type: Actions.GET_SLOTS_LOADING } }
  function success(slots, date) { return { type: Actions.GET_SLOTS, slots:slots, date:date} }
  function failure(error) { return { type: Actions.GET_SLOTS_FAILED, error } }
}

function getAvailability(store_id, date){
  return dispatch => {
    dispatch(request());
    StoreService.getAvailability(store_id, date)
    .then((resp) => {
      var availabilities = resp.data;
      dispatch(success(availabilities, date));

    }).catch((error)=>{
        dispatch(failure(error));
    });

  };

  function request() { return { type: Actions.GET_AVAILABILITIES_LOADING } }
  function success(availabilities, date) { return { type: Actions.GET_AVAILABILITIES, availabilities:availabilities, date:date} }
  function failure(error) { return { type: Actions.GET_AVAILABILITIES_FAILED, error } }
}

function getDeliveryMods(store_id, latitude, longitude){
  return dispatch => {
    dispatch(request());
    StoreService.getDeliveryMods(store_id, latitude, longitude)
    .then((resp) => {
      var fast_delivery = resp.data.fast_delivery;
      var normal_delivery = resp.data.normal_delivery;
      var take_away = resp.data.take_away;
      var fast_delivery_charge = resp.data.fast_delivery_charge;
      var delivery_availability = resp.data.delivery_availability;
      var fast_time = resp.data.fast_delivery_time;
      dispatch(success(fast_delivery, normal_delivery, take_away, fast_delivery_charge, delivery_availability, fast_time));

    }).catch((error)=>{
        dispatch(failure(error));
    });

  };

  function request() { return { type: Actions.GET_DELIVERY_MODS_LOADING } }
  function success(fast_delivery, normal_delivery, take_away, fast_delivery_charge, delivery_availability, fast_time) { 
    return { type: Actions.GET_DELIVERY_MODS, fast_delivery, normal_delivery, take_away, fast_delivery_charge, delivery_availability, fast_time} }
  function failure(error) { return { type: Actions.GET_DELIVERY_MODS_FAILED, error } }
}




export {
  getStores,
  setStore,
  reload,
  reset,
  getDefaultStore,
  getDefaultStore2,
  getNearestStore,
  getNearestStore2,
  isExistingStore,
  getSlots,
  getAvailability,
  getDeliveryMods
};
