import { REHYDRATE, PURGE, persistReducer } from 'redux-persist';
import { combineReducers } from 'redux'
import storage from 'redux-persist/lib/storage'

import userReducer from './user';
import homeReducer from './home';
import storeReducer from './store';
import categoryReducer from './category'
import productReducer from './product'
import relatedproductReducer from './relatedproducts'
import searchReducer from './search'
import filterResultReducer from './filterResult'
import bannerReducer from './banner';
import signupReducer from './signup'
import pointReducer from './point';
import brandReducer from './brand'
import changePasswordReducer from './changePassword'
import customerReducer from './customer';
import faqReducer from './faq'
import resetPasswordReducer from './resetPassword'
import filterValuesReducer from './filterValues'

import cartReducer from './cart'
import orderReducer from './order'

const config = {
  key: 'root',
  storage,
}


const rootReducer = combineReducers({
  userReducer,
  homeReducer,
  storeReducer,
  categoryReducer,
  productReducer,
  searchReducer,
  filterResultReducer,
  relatedproductReducer,
  bannerReducer,
  signupReducer,
  pointReducer,
  brandReducer,
  changePasswordReducer,
  customerReducer,
  faqReducer,
  resetPasswordReducer,
  filterValuesReducer,

  cartReducer,
  orderReducer
})

export default persistReducer(config,rootReducer);
