import React from 'react'
import { connect } from 'react-redux'

import FullWidth from '../../components/basic/FullWidth'
import ProfileSideNavigator from '../../components/custom/ProfileSideNavigator'
import ProfileHeader from '../../components/header/ProfileHeader'
import MyOrders from './MyOrders'
import MyPoints from './MyPoints'
import Notifications from './Notifications'
import SavedAdress from './SavedAddresses'
import empty from '../../assets/images/empty cart.png';

const KEYS = {
    MY_ORDERS : 'myorders',
    MY_POINTS : 'mypoints',
    MY_ADDRESS: 'myaddress',
    MY_NOTIFICATIONS:'mynotifications'
}

const TABS = [
    {
        name: 'My Orders',
        key: KEYS.MY_ORDERS,
        icon: 'shopping basket'
    },
    // {
    //     name: 'My Points',
    //     key: KEYS.MY_POINTS,
    //     icon: 'star'
    // },
    {
        name: 'Saved Addresses',
        key: KEYS.MY_ADDRESS,
        icon: 'map marker alternate'
    },
    {
        name: 'Notifications',
        key: KEYS.MY_NOTIFICATIONS,
        icon: 'bell'
    }
]

class Profile extends React.Component{

    state = {activeItem: TABS[0].key}

    onChangeItem = (key)=>{
        this.setState({activeItem:key})
    }

    renderPanel = ()=>{
        const {...props} = this.props
        switch(this.state.activeItem){
            case KEYS.MY_ORDERS:
                return <MyOrders {...props}/>
            case KEYS.MY_POINTS:
                return <MyPoints {...props}/>
            case KEYS.MY_ADDRESS:
                return <SavedAdress {...props}/>
            case KEYS.MY_NOTIFICATIONS:
                return <Notifications {...props}/>
        }
    }

    render(){
        const {activeItem} = this.state
        const { user, loggedIn } = this.props

        if(!loggedIn || !user){
            return(
                <React.Fragment>
                    <ProfileHeader/>
                    <div className="empty-product" style={{marginTop:50}}>
                        <img src={empty} alt="wildharbour" className="img-fluid"/>
                        <h3>Please login</h3>
                    </div>
                </React.Fragment>
            )
        }

        return(
            <React.Fragment>
            <ProfileHeader/>
            <FullWidth SectionClass="account-wrapper two-rows-wrapper pb-50">
                <div className="flex-grid">
                    <div className="f-col left-column">
                        <ProfileSideNavigator 
                            items={TABS}
                            activeItem={activeItem}
                            onChangeItem={this.onChangeItem}/>
                    </div>
                    <div className="f-col right-column">
                        {this.renderPanel()}
                    </div>
                </div>
            </FullWidth>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    loggedIn: state.userReducer.loggedIn,
    user:state.userReducer.user,
});
const mapDispatchToProps = dispatch => ({

});
export default connect(mapStateToProps,
                        mapDispatchToProps)
                        (Profile);
