import * as Actions from '../constants/ActionTypes';
import { PURGE, REHYDRATE } from 'redux-persist';

const initialState ={
    categories:[],
    count:0,
    storeChanged:false,
    more_cat_loading :false,
    childCats:[]
}
export default function categoryReducer(state = initialState , action){

    switch(action.type){

        case Actions.RESET: 
        return {};
        
        case Actions.CATEGORIES_LOADING:
            return {
                ...state,
                storeChanged:false,
                cat_loading:true,
            };
        case Actions.CATEGORIES:
            var cate = action.categories;
  
            return {
                ...state,
                date:action.date,
                cat_loading:false,
                categories: cate,
            };
        case Actions.CATEGORIES_FAILURE:
            return {
                ...state,
                storeChanged:false,
                cat_loading:false,
                cat_error:action.error
            };

        case Actions.CHILD_CATEGORIES_LOADING:
            return {
                ...state,
                childCats:[],
                cat_loading:true,
            };
        case Actions.CHILD_CATEGORIES:
            var cate = action.categories;
    
            return {
                ...state,
                cat_loading:false,
                childCats: cate,
            };
        case Actions.CHILD_CATEGORIES_FAILURE:
            return {
                ...state,
                childCats:[],
                cat_loading:false,
                cat_error:action.error
            };

        case Actions.MORE_CATEGORIES_LOADING:
            return {
                ...state,
                storeChanged:false,
                more_cat_loading:true,
            };
        case Actions.MORE_CATEGORIES:
            var cate = action.categories;
            if(action.skip !== null && action.skip > 0){
                cate = state.categories.concat(cate)
            }
            return {
                ...state,
                date:action.date,
                more_cat_loading:false,
                categories: cate,
            };
        case Actions.MORE_CATEGORIES_FAILURE:
            return {
                ...state,
                storeChanged:false,
                more_cat_loading:false,
                cat_error:action.error
            };

        case Actions.RESET_STORE:
            return {
                storeChanged:true
            };
        case Actions.STORE_RELOADED:
            return {
                ...state,
                storeChanged:false
            };
        default:
            return state
    }
}