import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom';
import { getPoints } from '../../actions/user';

import PointImg from '../../assets/images/point.jpg';

class MyPoints extends Component {
    state = {  }

    componentDidMount(){
        const { user, loggedIn, onGetPoints } = this.props
        if(loggedIn && user){
            onGetPoints(user.id)
        }
    }

    render() { 
        const { loading, points } = this.props

        var ac_id = 0
        if(typeof points !== undefined && points !== null && points.id){
            ac_id = points.id;
        }

        return ( 
            <div className="Points-wrapper">
                <div className="point-img-wrap">
                <img src={PointImg} alt="wild" className="img-fluid"/>
                    <div className="centered">
                        <div className="point">{points.point}</div>
                        <p>POINTS</p>
                    </div>
                </div>
                <div className="short-dis">
                    <p>
                        You can pay your billing value through points. <br/>
                        Use them on the payment desk during billing.
                    </p>
                </div>
                <div className="page-anchors">
                    <Link to ={"/point-statements/"+ac_id} className="btn-border">Point Statement</Link>
                    <Link to ={"/point-terms"} className="btn-border">General terms and conditions</Link>
                </div>
            </div>
         );
    }
}

const mapStateToProps = (state)=>({
    loggedIn : state.userReducer.loggedIn,
    user: state.userReducer.user,
    points: state.pointReducer.points,
    loading: state.pointReducer.loading
})

const mapDispatchToProps = (dispatch) =>({
    onGetPoints:(customer_id)=>{
        dispatch(getPoints(customer_id))
    }
})

export default connect(mapStateToProps,mapDispatchToProps)(MyPoints);