import request from '../config/HttpClient'
import * as URLS from '../constants/Urls'

function getProducts() {
  return request({
    url:    URLS.GET_PRODUCTS,
    method: 'GET',
  });
}

function getCategories() {
  return request({
    url:    URLS.GET_CATEGORIES,
    method: 'GET',
  });
}

function getSlideBanners(store_id,skip,take) {
  var sub_url = '/'+store_id;
  if(skip !==null && typeof skip !== 'undefined')sub_url = sub_url+'/'+skip;
  if(take !==null && typeof take !== 'undefined')sub_url = sub_url+'/'+take;
  return request({
    url:   URLS.GET_SLIDE_BANNER+sub_url,
    method: 'GET',
  });
}

function getVolumeOffers(store_id,skip,take) {
  var sub_url = '/'+store_id;
  if(skip !==null && typeof skip !== 'undefined')sub_url = sub_url+'/'+skip;
  if(take !==null && typeof take !== 'undefined')sub_url = sub_url+'/'+take;
  return request({
    url:   URLS.GET_VOLUME_OFFERS+sub_url,
    method: 'GET',
  });
}

function getCategoryOffers(store_id,skip,take) {
  var sub_url = '/'+store_id;
  if(skip !==null && typeof skip !== 'undefined')sub_url = sub_url+'/'+skip;
  if(take !==null && typeof take !== 'undefined')sub_url = sub_url+'/'+take;
  return request({
    url:   URLS.GET_CATEGORY_OFFERS+sub_url,
    method: 'GET',
  });
}

function getStillBanners(store_id,skip,take) {
  var sub_url = '/'+store_id;
  if(skip !==null && typeof skip !== 'undefined')sub_url = sub_url+'/'+skip;
  if(take !==null && typeof take !== 'undefined')sub_url = sub_url+'/'+take;
  return request({
    url:   URLS.GET_STILL_BANNER+sub_url,
    method: 'GET',
  });
}

function getTodaysDeals(store_id,skip,take) {
  var sub_url = '/'+store_id;
  if(skip !==null && typeof skip !== 'undefined')sub_url = sub_url+'/'+skip;
  if(take !==null && typeof take !== 'undefined')sub_url = sub_url+'/'+take;
  return request({
    url:   URLS.GET_TODAY_DEALS+sub_url,
    method: 'GET',
  });
}

function getFantasticDeals(store_id,skip,take) {
  var sub_url = '/'+store_id;
  if(skip !==null && typeof skip !== 'undefined')sub_url = sub_url+'/'+skip;
  if(take !==null && typeof take !== 'undefined')sub_url = sub_url+'/'+take;
  return request({
    url:   URLS.GET_FANTASTIC_DEALS+sub_url,
    method: 'GET',
  });
}


function getBrands(skip,take) {
  var sub_url ='';
  if(skip !==null && typeof skip !== 'undefined')sub_url = sub_url+'/'+skip;
  if(take !==null && typeof take !== 'undefined')sub_url = sub_url+'/'+take;
  return request({
    url:   URLS.GET_BRANDS+sub_url,
    method: 'GET',
  });
}

function getDashboard(store_id,skip,take) {
  var sub_url ='/'+store_id;
  if(skip !==null && typeof skip !== 'undefined')sub_url = sub_url+'/'+skip;
  if(take !==null && typeof take !== 'undefined')sub_url = sub_url+'/'+take;
  return request({
    url:   URLS.GET_DASHBOARD+sub_url,
    method: 'GET',
  });
}







const ProductService = {
  getProducts,
  getCategories,
  getSlideBanners,
  getVolumeOffers,
  getCategoryOffers,
  getStillBanners,
  getTodaysDeals,
  getFantasticDeals,
  getBrands,
  getDashboard
}


export default ProductService;
