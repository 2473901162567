import * as Actions from '../constants/ActionTypes';
import ProductService from '../services/ProductServices'

function getProduct(product_id){
    return dispatch => {
        dispatch(request());
        ProductService.getProduct(product_id)
        .then((resp) => {
    
          var product = resp.data;
          dispatch(success(product,product_id));
    
        }).catch((error)=>{
            dispatch(failure(error));
        });
    
      };
    
      function request() { return { type: Actions.GET_PRODUCT_LOADING} }
      function success(product,product_id) { return { type: Actions.GET_PRODUCT, product:product,product_id:product_id} }
      function failure(error) { return { type: Actions.GET_PRODUCT_FAILED, error } }
}

function getBannerItems(banner_id,store_id){
    return dispatch => {
        dispatch(request());
        ProductService.getBannerItems(banner_id,store_id)
        .then((resp) => {
    
          var data = resp.data;
          dispatch(success(data));
    
        }).catch((error)=>{
            dispatch(failure(error));
        });
    
      };
    
      function request() { return { type: Actions.BANNER_ITEMS_LOADING} }
      function success(data) { return { type: Actions.BANNER_ITEMS, data} }
      function failure(error) { return { type: Actions.BANNER_ITEMS_FAILED, error } }
}

function getRelatedProducts(product_id,skip=null,take=null){
    return dispatch => {
        dispatch(request());
        ProductService.getRelatedProducts(product_id,skip,take)
        .then((resp) => {
    
          var data = resp.data;
          dispatch(success(data,skip,product_id));
    
        }).catch((error)=>{
            dispatch(failure(error));
        });
    
      };
    
      function request() { return { type: Actions.GET_RELATED_PRODUCTS_LOADING} }
      function success(data,skip,product_id) { return { type: Actions.GET_RELATED_PRODUCTS, relatedProducts:data,skip,product_id:product_id} }
      function failure(error) { return { type: Actions.GET_RELATED_PRODUCTS_FAILED, error } }
}

function getRelatedProductsMore(product_id,skip=null,take=null){
    return dispatch => {
        dispatch(request());
        ProductService.getRelatedProducts(product_id,skip,take)
        .then((resp) => {
    
          var data = resp.data;
          dispatch(success(data,skip,product_id));
    
        }).catch((error)=>{
            dispatch(failure(error));
        });
    
      };
    
      function request() { return { type: Actions.MORE_GET_RELATED_PRODUCTS_LOADING} }
      function success(data,skip,product_id) { return { type: Actions.MORE_GET_RELATED_PRODUCTS, relatedProducts:data,skip,product_id:product_id} }
      function failure(error) { return { type: Actions.MORE_GET_RELATED_PRODUCTS_FAILED, error } }
}



export {
    getProduct,
    getBannerItems,
    getRelatedProducts,
    getRelatedProductsMore
};
  