import request from '../config/HttpClient'
import * as URLS from '../constants/Urls'


function search(query,skip,take, store_id){
  var sub_url = '/'+query;
  if(skip !==null && typeof skip !== 'undefined')sub_url = sub_url+'/'+skip;
  if(take !==null && typeof take !== 'undefined')sub_url = sub_url+'/'+take;
  if(store_id !==null && typeof store_id !== 'undefined')sub_url = sub_url+'/'+store_id;
    return request({
      url:   URLS.SEARCH+sub_url,
      method: 'GET'
    });
}


function filter(data){
    return request({
      url:   URLS.FILTER,
      method: 'POST',
      data:data
    });
}

function getFilterValues(data){
  return request({
    url:   URLS.GET_FILTER_VALUES,
    method: 'POST',
    data:data
  });
}





const SearchService = {
  search,
  filter,
  getFilterValues
}
  
  
export default SearchService;