import React, { PureComponent } from 'react';

class CartCounter extends PureComponent {

    render() { 
        const {count, onIncrement, onDecrement} = this.props

        const buttonStyle = {
        }

        if(count > 0){
            return(
                <div className="row-btns">
                    <button 
                        className="yellow-btn btn-ct" 
                        style={buttonStyle} 
                        onClick={onDecrement}><i className="zmdi zmdi-minus"></i></button>
                    <label className="ct-value" style={buttonStyle}>{count}</label>
                    <button 
                        className="yellow-btn btn-ct" 
                        style={buttonStyle} 
                        onClick={onIncrement}><i className="zmdi zmdi-plus"></i></button>
                </div>
            )
        }

        return ( 
            <button 
                className="yellow-btn" 
                onClick={onIncrement}>ADD <i className="zmdi zmdi-plus"></i></button>
         );
    }
}

export default CartCounter;
 