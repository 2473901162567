import React, { Component, useState } from 'react';
import { Accordion, Form, Menu } from 'semantic-ui-react';

const SortForm = ({
  onChange,
  value
})=>{

  const onChangeCheckbox = (value)=>{
    if(onChange) onChange(value)
  }

  return(
    <Form>
      <Form.Group grouped>
        <Form.Checkbox 
          checked={value==='0'}
          onChange={()=>onChangeCheckbox('0')}
          label='Price low to high' 
          name='filter' 
          value='0' />
        <Form.Checkbox 
          checked={value==='1'}
          onChange={()=>onChangeCheckbox('1')}
          label='Price high to low' name='filter' value='1'/>
        <Form.Checkbox 
          checked={value==='2'}
          onChange={()=>onChangeCheckbox('2')}
          label='Alphabatical order' name='filter' value='2' />
      </Form.Group>
    </Form>
  )
}

const BrandForm = ({
  brands,
  onChange,
  values
})=>{
  const onChangeForm = (e, {value})=>{
    if(values.includes(value)){
      const index = values.indexOf(value)
      values.splice(index, 1)
    }else{
      values.push(value)
    }
    if(onChange) onChange(values)
  }

  return(
    <Form>
      <Form.Group grouped >
        {brands.map((brand, i)=>(
          <Form.Checkbox 
            label={brand.name} 
            name='brand'
            checked={values.includes(brand.name)} 
            onChange={onChangeForm}
            value={brand.name} 
            key={i}/>
        ))}
      </Form.Group>
    </Form>
  )
}

const PriceForm = ({
  prices,
  onChange,
  values
})=>{

  const onChangeForm = (e, {value})=>{
    if(values.includes(value)){
      const index = values.indexOf(value)
      values.splice(index, 1)
    }else{
      values.push(value)
    }
    if(onChange) onChange(values)
  }

  return(
    <Form>
      <Form.Group grouped>
        {prices.map((price, i)=>(
          <Form.Checkbox 
            onChange={onChangeForm}
            label={price.name} 
            checked={values.includes(price.value)} 
            name='price' 
            value={price.value} key={i}/>
        ))}
      </Form.Group>
    </Form>
  )
}

const OfferForm = ({
  offers,
  onChange,
  values
})=>{

  const onChangeForm = (e, {value})=>{
    if(values.includes(value)){
      const index = values.indexOf(value)
      values.splice(index, 1)
    }else{
      values.push(value)
    }
    if(onChange) onChange(values)
  }

  return(
    <Form>
      <Form.Group grouped>
        {offers.map((offer, i)=>(
          <Form.Checkbox 
            label={offer.name} 
            name='offer'
            checked={values.includes(offer.value)} 
            onChange={onChangeForm} 
            value={offer.value} key={i}/>
        ))}
      </Form.Group>
    </Form>
  )
}
  

export default class FilterBar extends Component {
  state = { activeIndex: 0 }

  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index
    this.setState({ activeIndex: newIndex })
  }

  render() {
    const { activeIndex } = this.state
    const { 
      brands, 
      offers, 
      prices,
      selectedBrands,
      selectedPrices,
      selectedOffers,
      selectedFilter,
      onChangeBrands,
      onChangePrices,
      onChangeOffers,
      onChangeSort
    } = this.props

    return (
      <Accordion as={Menu} vertical>
        <Menu.Item>
          <Accordion.Title
            active={activeIndex === 0}
            content='Sort'
            index={0}
            onClick={this.handleClick}
          />
          <Accordion.Content 
            active={activeIndex === 0}  
            content={<SortForm 
                      value={selectedFilter} 
                      onChange={onChangeSort}/>
          }/>
        </Menu.Item>
        {(brands && brands.length > 0)?
          <Menu.Item>
            <Accordion.Title
              active={activeIndex === 1}
              content='Brand'
              index={1}
              onClick={this.handleClick}
            />
            <Accordion.Content 
              active={activeIndex === 1} 
              content={<BrandForm 
                        brands={brands} 
                        values={selectedBrands} 
                        onChange={onChangeBrands}/>
            }/>
          </Menu.Item>:null}
        {(prices && prices.length > 0)?
          <Menu.Item>
            <Accordion.Title
              active={activeIndex === 2}
              content='Price'
              index={2}
              onClick={this.handleClick}
            />
            <Accordion.Content 
              active={activeIndex === 2} 
              content={<PriceForm 
                        prices={prices} 
                        values={selectedPrices} 
                        onChange={onChangePrices}/>
            }/>
          </Menu.Item>:null}
        {(offers && offers.length > 0)?
          <Menu.Item>
            <Accordion.Title
              active={activeIndex === 3}
              content='Offer'
              index={3}
              onClick={this.handleClick}
            />
            <Accordion.Content 
              active={activeIndex === 3} 
              content={<OfferForm 
                        offers={offers} 
                        values={selectedOffers} 
                        onChange={onChangeOffers}/>
              }/>
          </Menu.Item>:null}
      </Accordion>
    )
  }
}