import React from 'react'
import { connect } from 'react-redux'
import { getPointTerms } from '../../actions/general'
import FullWidth from '../../components/basic/FullWidth'
import ActivityIndicator from '../../components/loaders/ActivityIndicator'

class PointTerms extends React.Component{

    componentDidMount(){
        this.props.onGetPointTerms()
    }

    render(){

        const { loading, terms } = this.props

        if(loading && (!terms || !terms.content)){
            return <FullWidth>
                <ActivityIndicator/>
            </FullWidth>
        }

        if(!terms || !terms.content){
            return <FullWidth SectionClass="white-wrapper">
                <div className="body-content">
                     <div className="sec-title">
                     <h2>Terms & Condition</h2>
                     </div>   
                    <p style={{whiteSpace:"pre-line"}}></p>
                </div>
            </FullWidth>
        }

        return(
            <FullWidth SectionClass="white-wrapper">
                <div className="body-content">
                     <div className="sec-title">
                     <h2>Terms & Condition</h2>
                     </div>   
                    <p style={{whiteSpace:"pre-line"}}>{terms.content}</p>
                </div>
            </FullWidth>
        )
    }
}

const mapStateToProps = state=>({
    loading: state.faqReducer.pointTermsLoading,
    terms:state.faqReducer.terms,
})

const mapDispatchToProps = dispatch=>({
    onGetPointTerms:()=>{
        dispatch(getPointTerms())
    }
})
 
export default connect(mapStateToProps,mapDispatchToProps)(PointTerms);