import * as Actions from '../constants/ActionTypes';

const initialState ={
    product:null,
    isLoading:false,
    bannerItemLoading:false,
    relatedProducts:[],
    relatedProductsLoading:false,
    product_id:0
}

export default function productReducer(state = initialState , action){
    switch(action.type){
        case Actions.GET_PRODUCT_LOADING:
            return{
                isLoading:true
            }
        case Actions.GET_PRODUCT:
            
            return{
                isLoading:false,
                product: action.product,
                product_id: action.product_id
            }
        case Actions.GET_PRODUCT_FAILED:
            return{
                isLoading:false,

            }

        case Actions.RESET_STORE:
            return {
                
            };
            
        default:
        return state
       
    }
}