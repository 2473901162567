import * as Actions from '../constants/ActionTypes';

const initialState ={
  signuping:false,
  signUpError:false
}

export default function signupReducer(state = initialState , action){
  switch(action.type){
  
    case Actions.INTIAL_STAGE_SIGNUP:
     return {
       signuping:false,
       signUpError:false,
     };
     case Actions.REMOVE_SNACKBAR_SIGNUP:
       return {
         signuping:false,
         signUpError:false,
      };

     
   case Actions.SIGNUP_REQUEST:
    return {
      signuping: true
    };
  case Actions.SIGNUP_SUCCESS:
    return {
      signuping:false,
      user:action.user,
      signupSuccess : true
    };

  case Actions.SIGNUP_FAILURE:
    return {
      signuping:false,
      signUpError:true,
      errorMessage2:action.error
    };

   default:
     return state

  }
}