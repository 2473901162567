import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'react-simple-snackbar'

import FormWrap from './FormWrap';
import './Login.css';
import AInput from '../../components/basic/AInput';
import { sendResetOtp } from '../../actions/general';
import { init } from '../../actions/search';
import ActivityIndicator from '../../components/loaders/ActivityIndicator';

class ForgotPassword extends Component {
  constructor(props){
    super(props);
    this.state = {
      mobile : ''
    }
    this.validator = new SimpleReactValidator();
  }

  sendOtp = (e)=>  {
    e.preventDefault()
    var data = {
      mobile:this.state.mobile
    }
    if( this.validator.allValid() ){
        this.props.onSend(data);
    }
    else {
      this.validator.showMessages();
      this.forceUpdate();
    } 
  } 

  componentDidUpdate(prevProps) {
    if(!this.props.loading && prevProps.loading){
      if(this.props.success && this.props.customer_id){
        this.props.history.push('/verify-otp/'+this.props.customer_id+'/forgot-password')
      }else{
        this.props.openSnackbar( "Check the mobile number you entered")
      }
    }
  }

  render(){
    return(
      <FormWrap>
        <ActivityIndicator fullScreen loading={this.props.loading}/>
        <form className="form-vertical">
          <AInput
            name="mobile"
            validator={this.validator}
            rules='required|min:10'
            type="number"
            value={this.state.mobile}
            onChange={(e)=>this.setState({mobile: e.target.value})}
            placeholder="Mobile Number"
          />
          <div className="bt-wrapper">
            <button 
              style={{width:'100%'}}
              onClick ={this.sendOtp}
              className="waves-effect waves-light btn">SEND OTP</button>
          </div>
          <div className="signup-here text-small">
            <br/>  <Link className="d-block large-text" to="/login">LOGIN</Link>
          </div>
        </form>
      </FormWrap>
    )
  }
}
ForgotPassword.deaultProps = {
  error:[]
}
const mapStateToProps = (state) => ({
  success:state.resetPasswordReducer.otpsendSuccess,
  loading:state.resetPasswordReducer.otpSending,
  customer_id: state.resetPasswordReducer.customer_id,
  error:state.resetPasswordReducer.error,
  errorMessage: state.resetPasswordReducer.errorMessage
})

const mapDispatchToProps = (dispatch)=> ({
  onSend:(data)=>{
    dispatch(sendResetOtp(data))
  },
  onInit: ()=>{
    dispatch(init())
  }
})
const ForgotPasswordPage = connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
export default withSnackbar(withRouter(ForgotPasswordPage))




