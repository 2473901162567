import React, { Component } from 'react';
import { connect } from 'react-redux';

import ActionBtn from '../../components/basic/Action';
import { getLocations } from '../../actions/user';
import ActivityIndicator from '../../components/loaders/ActivityIndicator';
import AddAdressModal from '../../components/custom/AddAddressModal';

class SavedAdress extends Component {

    componentDidMount(){
        this.props.onGetLocations(this.props.user.id)
    }

    addAddress = (data)=>{
    }

    myAdress = (location)=>{
        return(
            <div className="col-md-6">
                <div className="address-box">
                    <div className="action-area">
                        <div className="small-text">{location.villa}</div>
                        <ActionBtn/>
                    </div>
                    <div className="large-text">
                        <p>{location.title}</p>
                    </div>
                </div>
            </div>
        )
    }
    
    render() { 
        const {locations, loading } = this.props

        if(loading && (!locations || locations.length < 1)){
            return ( 
                <div className="oder-history">
                    <ActivityIndicator/>
                </div>
            );
        }

        if(!locations || locations.length < 1){
            return ( 
                <div className="oder-history">
                    
                    <div className="row">
                        <div className="col-md-12">
                            <div className="add-new-address">
                            <button className="btn-border"> <i className="zmdi zmdi-plus"></i>  Add new address</button>   
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        
        return ( 
            <div className="savedAdress">
                <div className="row flex-row">
                    {locations.map((location)=>{
                        return this.myAdress(location)
                    })}
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <AddAdressModal onSave={this.addAddress}/>
                    </div>
                </div>
            </div>
         );
    }

}

const mapStateToProps = state => ({
    loading : state.userReducer.loading,
    success: state.userReducer.success,
    location : state.userReducer.location,
    locations: state.userReducer.locations,
    loggedIn : state.userReducer.loggedIn,
    user: state.userReducer.user
});

const mapDispatchToProps = dispatch => ({
    onGetLocations:(customer_id)=>{
        dispatch(getLocations(customer_id))
    },
    // onChangeLocation:(location)=>{
    //     dispatch(changeSelectedLocation(location))
    // },
   
});
 
export default connect(mapStateToProps, mapDispatchToProps) (SavedAdress);