import * as Actions from '../constants/ActionTypes';
import { PURGE, REHYDRATE } from 'redux-persist';
import { getIntervels, isToday } from '../utils'

const initialState ={
  stores:[],
  store:null,
  is_loading:false,
  slot:{},
  availabilities:{},
  changed: false,
  reflector:false
}

function parseSlot(slotObj, slots, date){
  if(!slotObj) return {}
  var obj = slotObj;
  obj[date] = slots
  Object.keys(obj).forEach(e => {
    var key = new Date(e);
    var today = new Date();
    today.setDate(today.getDate() - 1);  
    if(key<today){
      delete obj[e];
    }
  });
  return obj
}

function filterSlot(slots, date){
  var now = new Date();
  var nowHr = now.getHours()
  var nowMin = now.getMinutes()
  var parts =date.split('-');
  var today = isToday(new Date(parts[2], parts[1] - 1, parts[0]))
  if(today){
      var new_slots = slots.map((slot)=>{
        var open = slot.from
        var opens = open.split(':')
        var openHr = parseInt(opens[0])
        var openMin = parseInt(opens[1])

        if((openHr > nowHr) || (openHr === nowHr && nowMin < openMin)){
          return slot
        }
      }).filter((el)=>{
        return el !=null
      })
      return new_slots
  }
  return slots
}

export default function storeReducer(state = initialState , action){
  switch(action.type){

    case Actions.RESET: 
    return {};

    case Actions.GET_STORE_LOADING:
      return {
         ...state,
        stores_loading:true,
      };
    case Actions.STORES:
      return {
        ...state,
        stores_loading:false,
        stores:action.stores,
      };

    case Actions.STORES_FAILURE:
      return {
         ...state,
        stores_loading:false,
        error:action.error
      };

   


    case Actions.GET_NEAREST_STORE_LOADING:
      return {
         ...state,
         loading: true
      };
    case Actions.NEAREST_STORE:
      return {
        ...state,
        store: action.store,
        loading:true,
      };

    case Actions.NEAREST_STORE_FAILURE:
      return {
         ...state,
         loading: false,
         error:action.error,
         store: null
      };

    case Actions.GET_SLOTS_LOADING:
      return {
          ...state,
          loading: true,
          reflector: !state.reflector
      };
    case Actions.GET_SLOTS:
      var new_slots = filterSlot(action.slots, action.date)
      var slot = parseSlot(state.slot, new_slots, action.date)
      return {
        ...state,
        slot: slot,
        loading: false,
        reflector: !state.reflector
      };

    case Actions.GET_SLOTS_FAILED:
      return {
        ...state,
        loading: false,
        reflector: !state.reflector
      };


    case Actions.GET_AVAILABILITIES_LOADING:
      return {
          ...state,
          loading: true
      };
    case Actions.GET_AVAILABILITIES:
        var result = []
      if(action.availabilities && action.availabilities.length > 0){
        var item = action.availabilities[0]
        result = getIntervels(item.open, item.close, action.date)
      }
      var availabilities = parseSlot(state.availabilities, result, action.date)
      return {
        ...state,
        availabilities: availabilities,
        reflector: !state.reflector,
        loading: false
      };

    case Actions.GET_AVAILABILITIES_FAILED:
      return {
        ...state,
        loading: false
      };

  case Actions.SET_STORE:
    return {
        ...state,
        existingFailed:false,
        store: action.store,
    };

   default:
     return state

  }
}
