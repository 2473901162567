import axios from 'axios';
import * as URLS from '../constants/Urls';

/**
* Request Wrapper with default success/error actions
*/

const request = async function (options, isHeader = true) {

 let call;
 let authHeader = null;
 if (isHeader) {
   authHeader = "Bearer "+sessionStorage.getItem('fmart_token');
 }

 const client = axios.create({
   baseURL: URLS.BASE_URL,

 });

 const onSuccess = function (response) {
   return response.data;
 }

 const onError = function (error) {

   return Promise.reject(error.response || error.message || error.data);
 }

 axios.defaults.headers.common = {
   'X-Requested-With': 'XMLHttpRequest',
   'Authorization': authHeader
};

 if (call) {
   call.cancel("Only one request allowed at a time.");
 }
 call = axios.CancelToken.source();
 options.cancelToken = call.token
 // options.headers = { 'Access-Control-Allow-Origin': '*','Access-Control-Allow-Credentials':'true','Authorization': "Bearer 123456" }

 return client(options)
   .then(onSuccess)
   .catch(onError);
}

export default request;