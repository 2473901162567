import * as Actions from '../constants/ActionTypes';

const initialState ={
    data:[],
    bannerItemLoading:false,
}

export default function bannerReducer(state = initialState , action){
    switch(action.type){
       

        case Actions.BANNER_ITEMS_LOADING:
            return{
                bannerItemLoading:true
            }
        case Actions.BANNER_ITEMS:
            var item = action.data;
            if(action.skip !== null && action.skip > 0){
                item = item.concat(this.state.data)
            }
            return{
                bannerItemLoading:false,
                data: item
            }
        case Actions.BANNER_ITEMS_FAILED:
            return{
                bannerItemLoading:false,

            }
        case Actions.RESET_STORE:
            return {
                
            };
            
        default:
        return state
       
    }
}