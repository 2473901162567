import React, { Component } from 'react';
class ColWidth extends Component {
    render() { 
        const{SectionClass} =this.props;
        return ( 
            <section className={SectionClass?SectionClass:''}>
                <div className="container">
                    <div className="row">
                        {this.props.children}
                    </div>
                </div>
            </section>
         );
    }
}
 
export default ColWidth;