import request from '../config/HttpClient'
import * as URLS from '../constants/Urls'

function getProduct(product_id) {
    var sub_url = '/'+product_id;

    return request({
      url:   URLS.GET_PRODUCT+sub_url,
      method: 'GET',
    });
}

function getBannerItems(banner_id,store_id) {
  var sub_url = '/'+banner_id + '/'+ store_id;

  return request({
    url:   URLS.GET_BANNER_ITEMS+sub_url,
    method: 'GET',
  });
}

function getRelatedProducts(product_id, skip, take) {
  var sub_url = '/'+product_id;
  if(skip !==null && typeof skip !== 'undefined')sub_url = sub_url+'/'+skip;
  if(take !==null && typeof take !== 'undefined')sub_url = sub_url+'/'+take;
  return request({
    url:   URLS.GET_RELATED_PRODUCTS+sub_url,
    method: 'GET',
  });
}







const ProductService = {
    getProduct,
    getBannerItems,
    getRelatedProducts
}
  
  
  export default ProductService;