import React, { Component } from 'react'
import { Menu } from 'semantic-ui-react'
import {Link} from 'react-router-dom'

export default class ProfileSideNavigator extends Component {
  state = { activeItem: 'myOrders' }

  handleItemClick = (key) => {
    if(this.props.onChangeItem){
      this.props.onChangeItem(key)
    }
  }

  render() {
    const { activeItem, items } = this.props
    if(!items) return null

    return (
      <div className="side-menu-wrapper">
          <Menu fluid vertical tabular>
            {items.map((item, index)=>(
              <Menu.Item 
                name={item.name}
                key={index}
                icon={item.icon}
                active={activeItem === item.key}
                onClick={()=>this.handleItemClick(item.key)}
                />
              ))}
          </Menu>
      </div>
    )
  }
}