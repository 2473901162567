import * as Actions from '../constants/ActionTypes';

const initialState ={
    products:[],
    count:0,
    filterSuccess:false,
    loadingFilter:false,
    loadingFilterMore:false,
    index:0
}
export default function filterResultReducer(state = initialState , action){

    switch(action.type){
        case Actions.GET_FILTER_LOADING2:
        return {
          ...state,
          loadingFilter:true,
          filterSuccess:false,
          count: 0,
          index: action.index
        };
    
      case Actions.GET_FILTER2:
        var filter = action.result;
        // if(action.skip !== null && action.skip > 0){
        //   filter = state.filterProducts.concat(filter)
        // }
        return {
            ...state,
            loadingFilter:false,
            filterSuccess:true,
            products: filter,
            count: action.count,
            index: action.index
        };
    
    case Actions.GET_FILTER_FAILED2:
        return {
          ...state,
          filterSuccess:false,
          loadingFilter:false,
          error:action.error,
          index: action.index
        };

        case Actions.MORE_GET_FILTER_LOADING2:
        return {
          ...state,
          loadingFilterMore:true,
          index: action.index
        };
    
      case Actions.MORE_GET_FILTER2:
        var filter = action.result;
        filter = state.products.concat(filter)
        return {
            ...state,
            loadingFilterMore:false,
            products: filter,
            index: action.index
        };
    
    case Actions.MORE_GET_FILTER_FAILED2:
        return {
          ...state,
          loadingFilterMore:false,
          error:action.error,
          index: action.index
        };
        case Actions.RESET_FILTER:
          return {
              ...state,
              loadingFilterMore:false,
              loadingFilter:false
          };
    case Actions.RESET_FILTER_SUCCESS:
      return {
        
          ...state,
          filterSuccess:false
          
      };
     
        default:
            return state
    }
}