import * as Actions from '../constants/ActionTypes';

const initialState ={
    result:[],
    history:[],
    addToHistoryFailed:false
}

export default function searchReducer(state = initialState , action){
    switch(action.type){
        case Actions.SEARCH_STARTED:
            return{
                ...state,
                history : state.history,
                isLoading:true
            }
        case Actions.SEARCH:
            return{
                history : state.history,
                isLoading:false,
                result:action.result
            }
        case Actions.SEARCH_FAILED:
            return{
                result:[],
                history : state.history,
                isLoading:false,
                error: action.error

            }
        case Actions.INIT_SEARCH:
            return{
                result:[],
                history : state.history,
                isLoading:true
        }
            

        case Actions.ADD_TO_SEARCH_HISTORY:
            return{
                addToHistoryFailed:false,
                ...state
            }
        case Actions.ADD_SEARCH_HISTORY_FAILED:
            return{
                ...state,
                addToHistoryFailed : true
            }
        case Actions.GET_SEARCH_HISTORY:
            return{
                history: action.history
            }
        case Actions.GET_SEARCH_HISTORY_FAILED:
            return{
                addToHistoryFailed : true
            }
        case Actions.RESET_STORE:
            return {
                
            };
        default:
        return state
    
         
    }
}