import React, { Component } from 'react';
import Slider from "react-slick";
import Carousel from "react-multi-carousel";

import ProductContainer from '../../components/custom/ProductCard';

const SETTINGS = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 12
      }
    },
    {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
  ]
};

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 7
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2
  }
};

class ProductsSlider extends Component {

  render() { 
    const {list, history } = this.props
    if(!list || list.length < 1) return null
    return ( 
      <div className="deals">
        <Carousel responsive={responsive} infinite={true}>
          {list.map((product, index)=>(
            <div style={{marginRight: 10}}>
              <ProductContainer {...product} key={index} history={history}/>
            </div>
            
          ))}
        </Carousel>   
      </div>
    );
  }
}
 
export default ProductsSlider;