import * as Actions from '../constants/ActionTypes';
import SearchService from '../services/SearchServices'
import * as Constants from '../constants/AppConstants'

function search(query, skip = null, take = null, store_id=null){
    return dispatch => {
        dispatch(request());
        SearchService.search(query,skip,take, store_id)
        .then((resp) => {
    
          dispatch(success(resp.data));
    
        }).catch((error)=>{
            dispatch(failure(error));
        });
    
      };
    
      function request() { return { type: Actions.SEARCH_STARTED} }
      function success(result) { return { type: Actions.SEARCH, result:result} }
      function failure(error) { return { type: Actions.SEARCH_FAILED, error } }
}

function resetFilterSuccess(){
    return dispatch => {
        dispatch(success());
      };
    
      function success(result) { return { type: Actions.RESET_FILTER_SUCCESS} }

}

function addToLocalSearchHistory(keyword){


    // var array = [];
    // return dispatch => {
    //     AsyncStorage.getItem(Constants.SERACH_HISTORY).then((item)=>{
    //         if(item !== null){
    //             array = JSON.parse(item);
    //         }

    //         if(array.length < 15){
    //             array.unshift(keyword);
    //         }else{
    //             array.shift();
    //             array.unshift(keyword)
    //         }

    //         var obj = {};
    //         var array2 = [];

    //         for ( var i=0, len=array.length; i < len; i++ ){
    //             if(obj.hasOwnProperty(array[i]['title'])) continue;
    //             obj[array[i]['title']] = array[i];
    //             array2.push(array[i]);
    //         }
                
    //         // array = new Array();
    //         // for ( var key in obj )
    //         //     array.push(obj[key]);
            

    //         // for (i= 0; i < array.length; i++){
    //         //     if(i === 1){
    //         //         break;
    //         //     }
    //         //     for (i2 = 0; i2 < array.length; i2++){
    //         //         if(i2 <= i){
    //         //             continue;
    //         //         }

    //         //         if(array[i].title === array[i2].title && array[i].type === array[i2].type){
    //         //             array.splice(i2,1);
    //         //         }
    //         //     }
    //         // }

    //         array = JSON.stringify(array2);
    //         AsyncStorage.setItem(Constants.SERACH_HISTORY, array)
    //         .then(()=>{
    //             dispatch(success(array));
    //         })
    //         .catch((error)=>{
    //             dispatch(failure(error));
    //         });
            
    //     }).catch ((error) => {
    //         dispatch(failure(error));
    //     })
    // }
    
    //   function success(history) { return { type: Actions.ADD_TO_SEARCH_HISTORY, history:history} }
      function failure(error) { return { type: Actions.ADD_SEARCH_HISTORY_FAILED, error } }
}


function getHistory(){

    // var array = [];
    // return dispatch => {
    //          AsyncStorage.getItem(Constants.SERACH_HISTORY).then((item)=>{
                 
    //             if(item !== null){
    //                 array = JSON.parse(item);
    //             }

    //             dispatch(success(array));
    //         }).catch ((error) => {
    //             dispatch(failure(error));
    //         })
    // }
    
    //   function success(history) { return { type: Actions.GET_SEARCH_HISTORY, history:history} }
    //   function failure(error) { return { type: Actions.GET_SEARCH_HISTORY_FAILED, error } }
}

function init(){

    return dispatch=>{
        dispatch(success());
      }
    function success() { return { type: Actions.INIT_SEARCH } }
}

function filter(data,index){
    return dispatch => {
        dispatch(request(index));
        SearchService.filter(data)
        .then((resp) => {
            var deals = resp.data;
            var count = resp.count;
            dispatch(success(deals,count,index));
        }).catch((error)=>{
            dispatch(failure(error,index));
        });
    
      };
    
      function request(index) { return { type: Actions.GET_FILTER_LOADING,index} }
      function success(result,count,index) { return { type: Actions.GET_FILTER, result:result,count:count,index} }
      function failure(error,index) { return { type: Actions.GET_FILTER_FAILED, error,index } }
}

function getFilterValues(data){
    return dispatch => {
        dispatch(request());
        SearchService.getFilterValues(data)
        .then((resp) => {
            var data = resp.data;

            dispatch(success(data));
        }).catch((error)=>{
            dispatch(failure(error));
        });
    
      };
    
      function request() { return { type: Actions.GET_FILTER_VALUES_LOADING} }
      function success(data) { return { type: Actions.GET_FILTER_VALUES_SUCCESS, data} }
      function failure(error) { return { type: Actions.GET_FILTER_VALUES_FAILED, error } }
}

function filterMore(data,index){
    return dispatch => {
        dispatch(request(index));
        SearchService.filter(data)
        .then((resp) => {
            var deals = resp.data;
            var count = resp.count;
            dispatch(success(deals,count,index));
        }).catch((error)=>{
            dispatch(failure(error,index));
        });
    
      };
    
      function request(index) { return { type: Actions.MORE_GET_FILTER_LOADING, index} }
      function success(result,count,index) { return { type: Actions.MORE_GET_FILTER, result:result,count:count,index} }
      function failure(error,index) { return { type: Actions.MORE_GET_FILTER_FAILED, error ,index} }
}

function filter2(data,index){
    return dispatch => {
        dispatch(request(index));
        SearchService.filter(data)
        .then((resp) => {
            var deals = resp.data;
            var count = resp.count;
            dispatch(success(deals,count,index));
        }).catch((error)=>{
            dispatch(failure(error,index));
        });
    
      };
    
      function request(index) { return { type: Actions.GET_FILTER_LOADING2,index:index} }
      function success(result,count,index) { return { type: Actions.GET_FILTER2, result:result,count:count,index:index} }
      function failure(error,index) { return { type: Actions.GET_FILTER_FAILED2, error,index:index } }
}

function filter2More(data,index){
    return dispatch => {
        dispatch(request(index));
        SearchService.filter(data)
        .then((resp) => {
            var deals = resp.data;
            var count = resp.count;
            dispatch(success(deals,count,index));
        }).catch((error)=>{
            dispatch(failure(error,index));
        });
    
      };
    
      function request(index) { return { type: Actions.MORE_GET_FILTER_LOADING2,index:index} }
      function success(result,count,index) { return { type: Actions.MORE_GET_FILTER2, result:result,count:count,index:index} }
      function failure(error,index) { return { type: Actions.MORE_GET_FILTER_FAILED2, error,index:index } }
}

function resetFilterView ( ){
    return dispatch => {
        dispatch(request());
    }

    function request() { return { type: Actions.RESET_FILTER} }
}

export {
    search,
    addToLocalSearchHistory,
    getHistory,
    resetFilterSuccess,
    filter,
    filter2,
    init,
    filter2More,
    filterMore,
    getFilterValues,
    resetFilterView
};