import React, { Component } from 'react';
import Slider from "react-slick";
const SETTINGS = {
  dots: false,
  infinite: false,
  arrows: false,
  speed: 500,
  slidesToShow: 8,
  slidesToScroll: 8,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 6,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4
      }
    },
    {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      }
  ]
};

class ScrollingDate extends Component {

  render() { 
    const { days, onChange, selected } = this.props
    if(!days || days.length < 1) return null
    return ( 
        <div className="slot-picker">
          <Slider {...SETTINGS}>
            {days.map((day, index)=>(
              <div key={index} onClick={()=>onChange(index)}>
                <button className={selected===index?"slot-btn active":"slot-btn"}>{day.day}</button>
              </div>
            ))}
          </Slider>
        </div>
    );
  }
}
 
export default ScrollingDate;