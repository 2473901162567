import React from 'react'

export default class AInput extends React.Component{

    state = {
        type : 'password'
    }

    showHide = () => this.setState(({type}) => ({
        type: type === 'text' ? 'password' : 'text'
    }))

    render(){
        const {validator, rules, value, isPassword, ...props} = this.props
        return(
            <div className="form-group">
                <input 
                    placeholder="Enter here" 
                    className="form-control" 
                    value={value}
                    {...props}
                    type={isPassword?this.state.type:props.type?props.type:'text'}/>
                { isPassword && <i className={this.state.type != 'password' ? 'far eye-btn fa-eye-slash' : 'far eye-btn fa-eye'}
                  onClick={this.showHide}></i>}
                {validator ? validator.message(props.name, value, rules):null}
            </div>
        )
    }
}