import * as Actions from '../constants/ActionTypes';
import OrderService from '../services/OrderService'

function getOrders(data, loadMore=false){
    return dispatch => {
        dispatch(request());
        OrderService.getOrders(data)
        .then((resp) => {
          var orders = resp.data;
          dispatch(success(orders, resp.count, loadMore));
    
        }).catch((error)=>{
            dispatch(failure(error));
        });
    
      };
    
      function request() { return { type: Actions.GET_ORDERS_LOADING} }
      function success(orders, count, loadMore) { return { type: Actions.GET_ORDERS, orders:orders, count, loadMore} }
      function failure(error) { return { type: Actions.GET_ORDERS_FAILED, error } }
}

function getOrder(order_id){
  return dispatch => {
      dispatch(request());
      OrderService.getOrder(order_id)
      .then((resp) => {
        var order = resp.data;
        dispatch(success(order));
  
      }).catch((error)=>{
          dispatch(failure(error));
      });
  
    };
  
    function request() { return { type: Actions.GET_ORDER_LOADING} }
    function success(order) { return { type: Actions.GET_ORDER, order:order} }
    function failure(error) { return { type: Actions.GET_ORDER_FAILED, error } }
}

function getCancelReasons(){
  return dispatch => {
      dispatch(request());
      OrderService.getCancelReasons()
      .then((resp) => {
        var reasons = resp.data;
        dispatch(success(reasons));
  
      }).catch((error)=>{
          dispatch(failure(error));
      });
  
    };
  
    function request() { return { type: Actions.GET_CANCEL_REASONS_LOADING} }
    function success(reasons) { return { type: Actions.GET_CANCEL_REASONS, reasons} }
    function failure(error) { return { type: Actions.GET_CANCEL_REASONS_FAILED, error } }
}

function getFastDelivery(){
  return dispatch => {
      dispatch(request());
      OrderService.getFastDelivery()
      .then((resp) => {
        var data = resp.data;
        dispatch(success(data.cost, data.time));
  
      }).catch((error)=>{
          dispatch(failure(error));
      });
  
    };
  
    function request() { return { type: Actions.GET_FAST_DELIVERY_LOADING} }
    function success(cost, time) { return { type: Actions.GET_FAST_DELIVERY, cost, time} }
    function failure(error) { return { type: Actions.GET_FAST_DELIVERY_FAILED, error } }
}

function cancelOrder(data){
  return dispatch => {
      dispatch(request());
      OrderService.cancelOrder(data)
      .then((resp) => {
        dispatch(success());
  
      }).catch((error)=>{
          dispatch(failure(error));
      });
  
    };
  
    function request() { return { type: Actions.CANCEL_ORDER_LOADING} }
    function success() { return { type: Actions.CANCEL_ORDER} }
    function failure(error) { return { type: Actions.CANCEL_ORDER_FAILED, error } }
}

function rescheduleOrder(data){
  return dispatch => {
      dispatch(request());
      OrderService.rescheduleOrder(data)
      .then((resp) => {
        
        dispatch(success());
  
      }).catch((error)=>{
          dispatch(failure(error));
      });
  
    };
  
    function request() { return { type: Actions.RESCHEDULE_ORDER_LOADING} }
    function success() { return { type: Actions.RESCHEDULE_ORDER} }
    function failure(error) { return { type: Actions.RESCHEDULE_ORDER_FAILED, error } }
}

function addReview(data){
  return dispatch => {
      dispatch(request());
      OrderService.addReview(data)
      .then((resp) => {
        dispatch(success());
  
      }).catch((error)=>{
          dispatch(failure(error));
      });
  
    };
  
    function request() { return { type: Actions.ADD_REVIEW_LOADING} }
    function success() { return { type: Actions.ADD_REVIEW} }
    function failure(error) { return { type: Actions.ADD_REVIEW_FAILED, error } }
}

function reset(){
  return dispatch => {
    dispatch(success());
  };
  function success() { return { type: Actions.RESET_SUCCESS} }
}


export {
    getOrders,
    getOrder,
    getCancelReasons,
    cancelOrder,
    reset,
    addReview,
    rescheduleOrder,
    getFastDelivery
}