import React, { Component } from 'react';
import { connect } from 'react-redux';

import FullWidth from '../../components/basic/FullWidth';
import LeftBar from './Leftbar';
import FaqContent from './FaqAccordion';
import { getFaqs } from '../../actions/general';
import ActivityIndicator from '../../components/loaders/ActivityIndicator';

class FaqPage extends Component {

    state = {
        activeIndex:0,
        faq: null
    }

    componentDidMount(){
        this.setIninital()
        this.props.onGetFaqs()
    }

    onChangeFaq = (activeIndex, faq)=>{
        this.setState({activeIndex, faq})
    }

    componentDidUpdate(){
        this.setIninital()
    }

    setIninital = ()=>{
        if(!this.state.faq && this.props.faqs && this.props.faqs.length>0){
            this.setState({faq: this.props.faqs[0]})
        }
    }

    render() { 
        const { activeIndex, faq } = this.state
        const { loading, faqs } = this.props

        if(loading && (!faqs || faqs.length < 1)){
            return <FullWidth>
                <ActivityIndicator/>
            </FullWidth>
        }

        if(!faqs || faqs.length < 1){
            return <FullWidth>
                <div className="align-center">
                    <h3>No Faqs Found</h3>
                </div>
            </FullWidth>
        }

        return ( 
            <FullWidth SectionClass="account-wrapper two-rows-wrapper pb-100">
                <div className="flex-grid">
                    <div className="f-col left-column">
                        <LeftBar 
                            onChange={this.onChangeFaq}
                            activeIndex={activeIndex}
                            items={faqs}/>
                    </div>
                    <div className="f-col right-column">
                        {(faq && faq.items && faq.items.length > 0)?
                        <div className="faq-content-wrapper">
                            <FaqContent items={faq.items}/>
                        </div>:
                        <div className="faq-content-wrapper">
                            <h3>No Details</h3>
                        </div>
                        }
                        
                    </div>
                </div>
            </FullWidth>
         );
    }
}

const mapStateToProps = state=>({
    loading: state.faqReducer.loading,
    faqs: state.faqReducer.faqs,
})

const mapDispatchToProps = dispatch =>({
    onGetFaqs : ()=>{
        dispatch(getFaqs())
    }
})
 
export default connect(mapStateToProps,mapDispatchToProps)(FaqPage);