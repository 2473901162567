import * as Actions from '../constants/ActionTypes';

const initialState ={
    relatedProducts:[],
    relatedProductsLoading:false,
    moreRelatedProductsLoading:false,
    r_product_id:0
}

export default function relatedproductReducer(state = initialState , action){
    switch(action.type){
        
        case Actions.GET_RELATED_PRODUCTS_LOADING:
            return{
                relatedProductsLoading:true
            }
        case Actions.GET_RELATED_PRODUCTS:
            var rp = action.relatedProducts;
            // if(action.skip !== null && action.skip > 0){
            //     rp = rp.concat(this.state.relatedProducts)
            // }
            return{
                relatedProductsLoading:false,
                relatedProducts : rp,
                r_product_id:action.product_id
            }
        case Actions.GET_RELATED_PRODUCTS_FAILED:
            return{
                relatedProductsLoading:false,

            }

        case Actions.MORE_GET_RELATED_PRODUCTS_LOADING:
            return{
                ...state,
                moreRelatedProductsLoading:true
            }
        case Actions.MORE_GET_RELATED_PRODUCTS:
            var rp = action.relatedProducts;
            if(action.skip !== null && action.skip > 0){
                rp = state.relatedProducts.concat(rp)
            }
            return{
                ...state,
                moreRelatedProductsLoading:false,
                r_product_id:action.product_id,
                relatedProducts : rp
            }
        case Actions.MORE_GET_RELATED_PRODUCTS_FAILED:
            return{
                ...state,
                moreRelatedProductsLoading:false,

            }
        case Actions.RESET_STORE:
            return {
                
            };
            
        default:
        return state
       
    }
}