import request from '../config/HttpClient'
import * as URLS from '../constants/Urls'

function getCategoryChilds(store_id,category_id,skip,take) {
    var sub_url = '/'+store_id+'/'+category_id;
    if(skip !==null && typeof skip !== 'undefined')sub_url = sub_url+'/'+skip;
    if(take !==null && typeof take !== 'undefined')sub_url = sub_url+'/'+take;
    return request({
      url:   URLS.GET_CATEGORY_CHILDS+sub_url,
      method: 'GET',
    });
  }

  function getCategories(store_id,skip,take){
    var sub_url = '/'+store_id;
    if(skip !==null && typeof skip !== 'undefined')sub_url = sub_url+'/'+skip;
    if(take !==null && typeof take !== 'undefined')sub_url = sub_url+'/'+take;
    return request({
      url:   URLS.GET_CATEGORIES+sub_url,
      method: 'GET',
    });
  }

  function getChildCategories(category_id, store_id, skip, take){
    var sub_url = '/'+store_id;
    sub_url = sub_url + '/'+ category_id
    if(skip !==null && typeof skip !== 'undefined')sub_url = sub_url+'/'+skip;
    if(take !==null && typeof take !== 'undefined')sub_url = sub_url+'/'+take;
    return request({
      url:   URLS.GET_CHILD_CATEGORIES+sub_url,
      method: 'GET',
    });
  }

  const CategoryService = {
    getCategoryChilds,
    getCategories,
    getChildCategories
  }
  
  
  export default CategoryService;
  