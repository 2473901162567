import { image } from 'faker';
import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import prdoduct from "../../assets/images/product.jpeg";
import AImage from '../../components/basic/AImage';

function ThumbanilSlider({
  images
}) {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);

  useEffect(() => {

    setNav1(slider1);
    setNav2(slider2);

  });

  if(!images || images.length<1) return null


  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: '.slider-nav'
  };

  const settingsThumbs = {
    slidesToShow: images.length>3?3:images.length,
    slidesToScroll: 1,
    asNavFor: '.slider-for',
    arrows: false,
    swipeToSlide: true,
    focusOnSelect: true,
  };

  return (
      <div className="slider-wrapper">
        <Slider
          {...settingsMain}
          asNavFor={nav2}
          ref={slider => (setSlider1(slider))}>
            {images.map((slide, index) =>
              <div className="product-image" key={index}>
                <AImage 
                  alt={index}  
                  src={slide.uri}
                  className="img-fluid"/>
              </div>   
            )}
        </Slider>
        <div className="thumbnail-slider-wrap">
          <Slider
            {...settingsThumbs}
            asNavFor={nav1}
            ref={slider => (setSlider2(slider))}>
            {images.map((slide, index) =>
              <div className="slick-slide" key={index}>
                <AImage className="slick-slide-image" src={slide.uri} />
              </div>
            )}
          </Slider>
        </div>
      </div>
  );
}

export default ThumbanilSlider;