import * as Actions from '../constants/ActionTypes';
import HomeService from '../services/HomeServices'

function getProducts() {

    return dispatch => {
      dispatch(request());
      HomeService.getProducts()
      .then((resp) => {
        var date = new Date();
        var products = resp.data;
        dispatch(success(products,date));

      }).catch((error)=>{
          dispatch(failure(error));
      });

    };

    function request() { return { type: Actions.PRODUCTS_LOADING} }
    function success(products,date) { return { type: Actions.PRODUCTS, products:products,date:date} }
    function failure(error) { return { type: Actions.PRODUCTS_FAILURE, error } }
}


function getSlideBanners(store_id,skip=null,take=null){
  return dispatch => {
    dispatch(request());
    HomeService.getSlideBanners(store_id,skip,take)
    .then((resp) => {
      var date = new Date();
      var banners = resp.data;
      dispatch(success(banners,date,skip));

    }).catch((error)=>{
        dispatch(failure(error));
    });

  };

  function request() { return { type: Actions.SLIDE_BANNERS_LOADING} }
  function success(banners,date, skip) { return { type: Actions.SLIDE_BANNERS, slideBanners:banners,date:date,skip} }
  function failure(error) { return { type: Actions.SLIDE_BANNERS_FAILURE, error } }
}

function getDashboard(store_id,skip=null,take=null){
  return dispatch => {
    dispatch(request());
    HomeService.getDashboard(store_id,skip,take)
    .then((resp) => {
      var date = new Date();
      var still_banner = resp.data.still_banner;
      var volume_offer = resp.data.volume_offer;
      var todays_deal = resp.data.todays_deal;
      var fantastic_deal = resp.data.fantastic_deal;
      var slide_banner = resp.data.slide_banner;
      var category_offers = resp.data.category_offers;
      dispatch(success(still_banner,volume_offer,todays_deal,fantastic_deal,slide_banner,category_offers,date,skip));

    }).catch((error)=>{
        dispatch(failure(error));
    });

  };

  function request() { return { type: Actions.DASHBOARD_LOADING} }
  function success(still_banner,volume_offer,todays_deal,fantastic_deal,slide_banner,category_offers,date,skip) { return { type: Actions.DASHBOARD_SUCCESS, 
    still_banner:still_banner,
    volume_offer:volume_offer,
    todays_deal:todays_deal,
    fantastic_deal:fantastic_deal,
    slide_banner:slide_banner,
    category_offers:category_offers,
    date:date,
    skip:skip} }
  function failure(error) { return { type: Actions.DASHBOARD_FAILED, error } }
}





function getVolumeOffers(store_id,skip=null,take=null){
  return dispatch => {
    dispatch(request());
    HomeService.getVolumeOffers(store_id,skip,take)
    .then((resp) => {
      var date = new Date();
      var vol_offers = resp.data;
      dispatch(success(vol_offers,date,skip));

    }).catch((error)=>{
        dispatch(failure(error));
    });

  };

  function request() { return { type: Actions.VOLUME_OFFERS_LOADING} }
  function success(vol_offers,date,skip) { return { type: Actions.VOLUME_OFFERS, volOffers:vol_offers,date:date,skip} }
  function failure(error) { return { type: Actions.VOLUME_OFFERS_FAILURE, error } }
}

function getCategoryOffers(store_id,skip=null,take=null){
  return dispatch => {
    dispatch(request());
    HomeService.getCategoryOffers(store_id,skip,take)
    .then((resp) => {
      var date = new Date();
      var cat_offers = resp.data;
      dispatch(success(cat_offers,date,skip));

    }).catch((error)=>{
        dispatch(failure(error));
    });

  };

  function request() { return { type: Actions.CATEGORY_OFFERS_LOADING} }
  function success(cat_offers,date,skip) { return { type: Actions.CATEGORY_OFFERS, catOffers:cat_offers,date:date,skip} }
  function failure(error) { return { type: Actions.CATEGORY_OFFERS_FAILURE, error } }
}


function getCategoryOffersMore(store_id,skip=null,take=null){
  return dispatch => {
    dispatch(request());
    HomeService.getCategoryOffers(store_id,skip,take)
    .then((resp) => {
      var date = new Date();
      var cat_offers = resp.data;
      dispatch(success(cat_offers,date,skip));

    }).catch((error)=>{
        dispatch(failure(error));
    });

  };

  function request() { return { type: Actions.MORE_CATEGORY_OFFERS_LOADING} }
  function success(cat_offers,date,skip) { return { type: Actions.MORE_CATEGORY_OFFERS, catOffers:cat_offers,date:date,skip} }
  function failure(error) { return { type: Actions.MORE_CATEGORY_OFFERS_FAILURE, error } }
}

function getStillBanners(store_id,skip=null,take=null){
  return dispatch => {
    dispatch(request());
    HomeService.getStillBanners(store_id,skip,take)
    .then((resp) => {
      var date = new Date();
      var banners = resp.data;
      dispatch(success(banners,date,skip));

    }).catch((error)=>{
        dispatch(failure(error));
    });

  };

  function request() { return { type: Actions.STILL_BANNERS_LOADING} }
  function success(banners,date,skip) { return { type: Actions.STILL_BANNERS, stillBanners:banners,date:date,skip} }
  function failure(error) { return { type: Actions.STILL_BANNERS_FAILURE, error } }
}

function getTodaysDeals(store_id,skip=null,take=null,index){
  return dispatch => {
    dispatch(request(index));
    HomeService.getTodaysDeals(store_id,skip,take)
    .then((resp) => {

      var deals = resp.data;
      var count = resp.count;
      var date = new Date();
      dispatch(success(deals,count,date,skip,index));

    }).catch((error)=>{
        dispatch(failure(error,index));
    });

  };

  function request(index) { return { type: Actions.TODAYS_DEALS_LOADING,index} }
  function success(deals,count,date,skip,index) { return { type: Actions.TODAYS_DEALS, todDeals:deals,count,date:date,skip,index} }
  function failure(error,index) { return { type: Actions.TODAYS_DEALS_FAILURE, error,index } }
}

function getFantasticDeals(store_id,skip=null,take=null,index){
  return dispatch => {
    dispatch(request(index));
    HomeService.getFantasticDeals(store_id,skip,take)
    .then((resp) => {
      var date = new Date();
      var deals = resp.data;
      var count = resp.count;
      dispatch(success(deals,count,date,skip,index));

    }).catch((error)=>{
        dispatch(failure(error,index));
    });

  };

  function request(index) { return { type: Actions.FANTASTIC_DEALS_LOADING, index} }
  function success(deals,count,date,skip,index) { return { type: Actions.FANTASTIC_DEALS, fanDeals:deals,count,date:date,skip,index} }
  function failure(error,index) { return { type: Actions.FANTASTIC_DEALS_FAILURE, error, index } }
}

function getBrands(skip=null,take=null){
  return dispatch => {
    dispatch(request());
    HomeService.getBrands(skip,take)
    .then((resp) => {
      var brands = resp.data;
      dispatch(success(brands,skip));

    }).catch((error)=>{
        dispatch(failure(error));
    });

  };

  function request() { return { type: Actions.GET_BRANDS_LOADING} }
  function success(brands,skip) { return { type: Actions.GET_BRANDS_SUCCESS, brands:brands,skip} }
  function failure(error) { return { type: Actions.GET_BRANDS_FAILED, error } }
}

export {
  getProducts,
  getSlideBanners,
  getVolumeOffers,
  getCategoryOffers,
  getStillBanners,
  getTodaysDeals,
  getFantasticDeals,
  getBrands,
  getCategoryOffersMore,
  getDashboard

}
