import React, { Component } from 'react';
import { compose, withProps, lifecycle } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";

import icon from '../../assets/images/location.png';
const { SearchBox } = require("react-google-maps/lib/components/places/SearchBox");
var _ = require('lodash');


class  LocationSearchInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      markers: this.props.markers,
      center: { lat: 8.5241, lng: 76.9366 }
    }
  }

  //WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
  componentWillReceiveProps(nextProps) {
    if(nextProps.markers){
      this.setState({markers:nextProps.markers})
    }
  }

  componentDidMount(){
    navigator.geolocation.getCurrentPosition(
      position =>{
        var lat = position.coords.latitude 
        var lng = position.coords.longitude
        this.setState({center:{lat, lng}})
        console.log('location fetch')
      }, 
      err => {
      }
    );
  }

  render() {
    return (
      <React.Fragment>
        <MapWithASearchBox {...this.props} markers2={this.state.markers} center={this.state.center}/>
      </React.Fragment>
    )
  }
}

const MapWithASearchBox = compose(
  withProps({
    googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyDgUp0KhdiBj2YGjqiKolW7EARPVAaC-mQ&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `300px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  lifecycle({
    componentWillMount() {
      const refs = {}
      navigator.geolocation.getCurrentPosition(position => {
        var newMarkers = [{
          latitude: position.coords.latitude,
          longitude:position.coords.longitude
        }]
        const nextCenter = {
          lat: position.coords.latitude,
          lng:position.coords.longitude
        }
        this.setState({
          markers: newMarkers,
          center: nextCenter,
          positions: newMarkers
        },()=>this.props.onMapChange(this.state.positions[0]))
      }, err => console.log('error',err))
      this.setState({
        bounds: null,
        center: this.props.center,
        markers: this.props.markers2,
        onMapMounted: ref => {
          refs.map = ref;
        },
        onDrag:()=>{
          var lat = refs.map.getCenter().lat()
          var lng = refs.map.getCenter().lng()
          this.props.onMapChange({
            latitude:lat,
            longitude:lng
          })
        },
        onBoundsChanged: () => {
          this.setState({
            bounds: refs.map.getBounds(),
            center: refs.map.getCenter(),
          })
        },
        onSearchBoxMounted: ref => {
          refs.searchBox = ref;
        },
        onPlacesChanged: () => {
          const places = refs.searchBox.getPlaces();
          const bounds = new window.google.maps.LatLngBounds();
          places.forEach(place => {
            
            if (place.geometry.viewport) {
              bounds.union(place.geometry.viewport)
            } else {
              bounds.extend(place.geometry.location)
            }
          });

          const nextMarkers = places.map(place => ({
            position: place.geometry.location,
          }));

          const nextMarkers2 = places.map(place => ({
            latitude: place.geometry.location.lat(),
            longitude: place.geometry.location.lng()
          }));
 
          const positions = places.map(place => ({
            latitude: place.geometry.location.lat(),
            longitude: place.geometry.location.lng()
          }));
          const nextCenter = _.get(nextMarkers, '0.position', this.state.center);
          this.setState({
            center: nextCenter,
            markers: nextMarkers2,
            positions:positions
          },()=>this.props.onMapChange(this.state.positions[0]));
         
        },
      })
    },

    componentDidUpdate(prevProps, prevState) {
      if(typeof this.props.markers2 !== 'undefined' ){
        if(this.props.markers2.length > 0 && prevProps.markers2.length === 0 && this.props.isEditable){
          var center = _.get([{ lat: this.props.markers2.latitude, lng: this.props.markers2.longitude }], '0.position', this.state.center); 
          this.setState({
            markers:this.props.markers2,
            center:center
          })
        }
      }
    }
    //WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
    // componentWillReceiveProps(nextProps) {
      

    //   if(nextProps.markers2){
    //     this.setState({markers:nextProps.markers2})
    //   }
    // }
  }),
  withScriptjs,
  withGoogleMap
)(props =>
  <GoogleMap
    ref={props.onMapMounted}
    defaultZoom={10}
    center={props.center}
    draggable={true}
    onDragEnd={props.onDrag}
    // onBoundsChanged={props.onBoundsChanged}
  >
    <SearchBox
      ref={props.onSearchBoxMounted}
      bounds={props.bounds}
      controlPosition={window.google.maps.ControlPosition.TOP_LEFT}
      onPlacesChanged={props.onPlacesChanged}
    >
      <input
        type="text"
        placeholder="Search your Place"
        style={{
          boxSizing: `border-box`,
          border: `1px solid transparent`,
          width: `240px`,
          height: `40px`,
          marginTop: `10px`,
          padding: `0 12px`,
          borderRadius: `3px`,
          boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
          fontSize: `14px`,
          outline: `none`,
          textOverflow: `ellipses`,
        }}
      />
    </SearchBox>
    {/* {typeof props.markers !== 'undefined' && props.markers.map((marker, index) =>{
      return (<Marker key={index} position={{ lat: parseFloat(marker.latitude), lng: parseFloat(marker.longitude) }}/>)
    })} */}
    <div className="center-marker">
        <img src={icon} style={{width:50, marginBottom:20}}/>
    </div>
  </GoogleMap>
);
 
LocationSearchInput.defaultProps ={
  store_map:{},
  isEditable : false
}

export default  LocationSearchInput;

