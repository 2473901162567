import React from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import ActivityIndicator from '../loaders/ActivityIndicator'
import ProductContainer from './ProductCard'


function ProductList({
    loading,
    products,
    count,
    fetchMore,
    hasMore,
    history
}){

    if(loading){
        return(
            <div className="list-products">
                <ActivityIndicator/>
            </div>
        )
    }

    if(!products || products.length < 1){
        return(
            <div className="list-products">
                <div className="align-center">
                    <h3>No products found</h3>
                </div>
            </div>
        )
    }

    const refresh = ()=>{

    }

    return(
        <InfiniteScroll 
        datalength={count}
            loadMore={fetchMore}
            hasMore={hasMore}
            useWindow={false}
            loader={<ActivityIndicator/>}
            refreshfunction={refresh}>
                <div className="list-products">
                    <div className="row flex-row">
                        {products.map((product, index)=>(
                            <div className="col-md-6" key={index}>
                                <ProductContainer {...product} horizontal={true} history={history}/>
                            </div>
                        ))}
                    </div>
                </div>
        </InfiniteScroll>
    )
}

export default ProductList