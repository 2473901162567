import React, { Component, Profiler } from 'react';
import {Route, Switch } from "react-router-dom";

import NavBar from '../components/header/NavBar';
import Footer from '../components/footer/Footer';

import ThankYou from './cart/ThankYou';
import CategoryProductsContainer from './category/CategoryProducts';
import FaqPage from './help/Faq';
import HomeContainer from './home/Home';
import ProdductDetails from './home/ProductDetails';
import Cart from './cart/Cart';
import Checkout from './cart/Checkout';
import Profile from './profile/Profile';
import PointStatements from './profile/PointStatements';
import PointTerms from './help/PointTerms';
import About from './help/About';
import ProductsContainer from './home/Products';
import Privacy from './help/Privacy';
import Support from './help/Support';

class UILayout extends Component {

  render() {
    return (
      <div>
        <NavBar/>
        <main className="body-wrapper">
        <Switch>
          <Route path="/" exact component={HomeContainer} />
          <Route path="/category/:id" exact component={CategoryProductsContainer}/>
          <Route path="/faq" exact component={FaqPage}/>
          <Route path="/my-account" exact component={Profile}/>
          <Route path="/order-success" component={ThankYou}/>
          <Route path="/cart" component={Cart}/>
          <Route path="/checkout" component={Checkout}/>
          <Route path="/point-statements/:id" component={PointStatements}/>
          <Route path="/terms-condition" component={PointTerms}/>
          <Route path="/privacy-policy" component={Privacy}/>
          <Route path="/support" component={Support}/>
          <Route path="/about" component={About}/>
          <Route path="/product/:id" render={(props) => (
            <ProdductDetails key={props.match.params.id} {...props} />)
          } />
          <Route path="/products/:type/:id?" component={ProductsContainer}/>
        </Switch>
        </main>
        <Footer/>
      </div>
    )
  }
}
export default UILayout;