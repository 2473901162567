import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Radio } from 'semantic-ui-react';
import { setDeliveryMode, setDeliverySlot } from '../../actions/cart';
import { rescheduleOrder } from '../../actions/order';
import { getAvailability, getSlots } from '../../actions/store';

import ScrollingDate from '../../components/custom/ScrollingDate';
import ScrollingTime from '../../components/custom/ScrollingTime';
import { tConv24 } from '../../utils';

const DAYS = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]

const DeliveryOptions = [
    
    {
        title : "Normal Delivery", 
        charge : "Free Delivery", 
        subtitle:null, 
        time:true,
        value:1,
        keyword:'normal_delivery'
    },
    {
        title : "Fast Delivery", 
        charge : "INR", 
        subtitle: "Minutes Fast Delivery", 
        time:false,
        value:2,
        keyword:'fast_delivery'
    },
    // {
    //     title : "Take Away", 
    //     charge : "", 
    //     subtitle:null, 
    //     time:true,
    //     value: 3,
    //     keyword:'take_away'
    // }
]

class DeliverySlotPicker extends Component {
    state = { 
        days:[], 
        value:'normal_delivery',
        active: 0,
        activeTime:0 
    }

    handleChange = (value, index) => {
        const { fast_delivery_charge, slotObj } = this.props
        var charge = 0
        if(slotObj && value === 'normal_delivery'){
            charge = slotObj.shipping_charge
        }
        if(fast_delivery_charge && value === 'fast_delivery'){
            charge = fast_delivery_charge
        }
        this.setState({ value })
        this.props.onSetDeliveryMode(DeliveryOptions[index].value, charge)
    }

    chooseDate = (i)=>{
        const {store} = this.props
        const {days} = this.state
        if(store){
            var storeId = store.id
            var item = days[i]
            this.props.onGetSlots(storeId, item.date);
            this.props.onGetAvailabity(storeId, item.date);
            this.setState({active:i})
        }
    }

    onPickSlot = (index, s)=>{
        this.setState({activeTime: index})
        const {slot, availabilities} = this.props
        const {days, active, value} = this.state
        var items = []
        var obj = slot
        var type = 0
        if(value === 'take_away'){
            obj = availabilities
            type = 2
        }
        
        if(typeof days[active] !== 'undefined'){
            var item = days[active]
            var date = item.date
            if(obj.hasOwnProperty(date)){
                items = obj[date]
                if(typeof items[index] !== 'undefined'){
                    this.props.onSetDeliverySlot(date, items[index].from,  items[index].to, type,  items[index].id, items[index]) 
                }else{
                }
            }else{
            }
        }else{
        }
    }

    componentDidMount(){
        var now = new Date();
        var days = [];
        for(var i=0; i<=6; i++){
            var day = DAYS[now.getDay()]
            var item = {day:day, date:now.getDate()+'-'+(now.getMonth()+1)+'-'+now.getFullYear()}
            if(i==0)item['day'] = 'Today'
            if(i==1)item['day'] = 'Tomorrow'
            days.push(item)
            now.setDate(now.getDate() + 1)
        }
        this.setState({
            days
        },()=>this.chooseDate(0))
        this.props.onSetDeliveryMode(DeliveryOptions[0].value, 0)
    }

    render() { 
        const {value, days, active, activeTime } = this.state
        const { slot, availabilities, loading, fast_delivery_charge, slotObj, store, fast_time } = this.props

        var items = []
        if(typeof days[active] !== 'undefined' && value === 'normal_delivery'){
            var item = days[active]
            var date = item.date
            if(slot && slot.hasOwnProperty(date)){
                items = slot[date]
            }
        }

        if(typeof days[active] !== 'undefined' && value === 'take_away'){
            var item = days[active]
            var date = item.date
            if(availabilities && availabilities.hasOwnProperty(date)){
                items = availabilities[date]
            }
        }

        return ( 
            <div className="time-slote-wrapper">
                {DeliveryOptions.map((option, index)=>{
                    // if(!this.props[option.keyword]) return null
                    return(
                        <div className="choose-slot" key={index}>
                            <div className="type-wrapper">
                                <Radio
                                    label={option.title}
                                    name={option.keyword}
                                    value={option.keyword}
                                    checked={value === option.keyword}
                                    onChange={(e,{value})=>this.handleChange(value, index)}/>
                                {option.keyword == 'fast_delivery'? <span className="large-text">
                                    {store && store.currency.symbol} {fast_delivery_charge}</span>: null}
                                {option.keyword == 'normal_delivery' && slotObj? <span className="large-text">
                                    {store && store.currency.symbol} {slotObj.shipping_charge}</span>: null}
                            </div>
                            {slotObj && option.keyword == 'normal_delivery' && 
                            <span style={{marginLeft: 25}} className="free">{tConv24(slotObj.from, true)} - {tConv24(slotObj.to)} {this.props.date}</span>}
                             {option.keyword == 'fast_delivery' && 
                            <span style={{marginLeft: 25}} className="free">{fast_time} Minutes Fast Delivery</span>}
                            {(value === option.keyword && value != 'fast_delivery') ?
                                <div>
                                    <ScrollingDate 
                                        days={days} 
                                        selected={active} 
                                        onChange={this.chooseDate}/>
                                    <ScrollingTime 
                                        slots={items} 
                                        selected={activeTime} 
                                        onChange={this.onPickSlot} 
                                        loading={loading}/>
                                </div>:null
                            }
                        </div>
                    )
                })}
           </div>
         );
    }
}

const mapStateToProps = state => ({
    cart: state.cartReducer.cart,
    total : state.cartReducer.total,
    store : state.storeReducer.store,
    loggedIn : state.userReducer.loggedIn,
    user: state.userReducer.user,
    slot: state.storeReducer.slot,
    slotObj: state.cartReducer.slotObj,
    loading: state.storeReducer.loading,
    availabilities: state.storeReducer.availabilities,
    popup: state.orderReducer.popup,
    success: state.orderReducer.success,
    orLoader: state.orderReducer.loading,
    cost: state.orderReducer.cost,
    reflector: state.storeReducer.reflector,
    normal_delivery: state.cartReducer.normal_delivery,
    fast_delivery: state.cartReducer.fast_delivery,
    take_away: state.cartReducer.take_away,
    fast_delivery_charge:  state.cartReducer.fast_delivery_charge,
    normal_delivery_charge: state.cartReducer.normal_delivery_charge,
    date: state.cartReducer.date,
    fast_time: state.cartReducer.fast_time
});

const mapDispatchToProps = dispatch => ({
    onGetSlots:(store_id, date)=>{
        dispatch(getSlots(store_id, date))
    },
    onSetDeliverySlot:(date, from, to, index, slot_id, slot)=>{
        dispatch(setDeliverySlot(date, from, to, index, slot_id, slot ))
    },
    onGetAvailabity:(store_id, date)=>{
        dispatch(getAvailability(store_id, date))
    },
    onRescheduleOrder:(data)=>{
        dispatch(rescheduleOrder(data))
    },
    onSetDeliveryMode:(mode, charge)=>{
        dispatch(setDeliveryMode(mode, charge))
    },

});

export default connect(mapStateToProps, mapDispatchToProps) (DeliverySlotPicker);