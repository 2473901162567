import React, { Component } from 'react'
import { Menu,Label } from 'semantic-ui-react'

export default class LeftBar extends Component {

  handleItemClick = (index, item) => {
    const {onChange} = this.props
    if(onChange){
      onChange(index, item)
    }
  }

  render() {
    const { items, activeIndex } = this.props

    if(!items || items.length<1) return null

    return (
      <div className="side-menu-wrapper">
          <Menu fluid vertical tabular>
            {items.map((item, index)=>(
              <Menu.Item  
                name={item.title}
                key={index}
                active={activeIndex === index}
                onClick={()=>this.handleItemClick(index, item)}>
                {item.title}
                {/* <Label>{item.items.length}</Label> */}
              </Menu.Item>
            ))}
          </Menu>
      </div>
    )
  }
}