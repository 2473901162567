import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Form } from 'semantic-ui-react';

import { addToCart, removeFromCart } from '../../actions/cart';
import offerTag from '../../assets/images/offer.svg';
import { getCartCount } from '../../utils';
import AImage from '../basic/AImage';
import CartCounter from './CartCounter';

class ProductCard extends Component {

    state = {count:0, selected:0, popup:false, pack: null}

    chooseWeight = ()=>{
      this.setState({popup: !this.state.popup})
    }

    onClick = (e)=>{
      const { onClick } = this.props
      e.preventDefault()
      e.stopPropagation()
      if(onClick){
        onClick()
      }else{
        var id = this.props.id
        this.props.history.push('/product/'+id)
      }
    }

    onChangeWeight = (selected)=>{
      const { packs} = this.props
      this.setState({selected, popup:false})
      if(typeof packs.qtys[selected] !== 'undefined'){
        var position = packs.qtys[selected]['pos']
        var pack = packs.packs[position]
        this.setState({pack})
      }  
    }

    increment = ()=>{
      var { 
        stock
      } = this.props;
      const { pack } = this.state
      if(pack){
        var { stock} = this.state.pack;
      }
      var count = parseInt(this.state.count) + 1
      if(count && stock < count){
        alert("Sorry! We are out of stock for adding more quantity to cart");
        return
      }
      this.setState({
        count 
      })
      var {cart, ...item} = this.props
      if(this.state.pack){
         item = { ...this.state.pack, packs:item.packs}
      }
  
      this.props.onAddToCart(item, count)
    }
  
    decrement = ()=>{
      var count = this.state.count - 1
      this.setState({
        count
      })
      var {cart, ...item} = this.props
      if(this.state.pack){
        item = { ...this.state.pack, packs:item.packs}
      }
      this.props.onRemoveFromCart(item, count)
    }

    componentDidMount(){
      this.checkCount()
    }

    checkCount = ()=>{
      var id = this.props.id
      if(this.state.pack){
        var id = this.state.pack.id
      }
      var count = getCartCount(this.props.cart, id)
      if(count !== this.state.count){
        this.setState({count})
      }
    }

    componentDidUpdate(){
      this.checkCount()
    }

    renderWeights = (packs)=>{
      if(!packs || !packs.qtys) return null
      const qtys = packs.qtys
      return(
        <Form>
          <Form.Group grouped>
            {qtys.map((qty, index)=>(
              <Form.Checkbox 
                checked={this.state.selected===index}
                key={index}
                onChange={()=>this.onChangeWeight(index)}
                label={qty.qty} 
                name='filter' 
                value='low' />
            ))}
          </Form.Group>
        </Form>
      )
    }

    render() { 
      const {count, selected, pack} = this.state
      var {
        horizontal, 
        title, 
        selling_price, 
        disc_price, 
        brand, 
        featured_image, 
        byPerc,
        offer,
        discount,
        cart,
        packs,
        qty, 
        unit,
        store,
        attributes
      } = this.props

      if(pack){
        var { 
          title, 
          selling_price, 
          wholesale_price, 
          featured_image,
          brand, 
          qty, 
          unit, 
          offer, 
          discount, 
          byPerc, 
          disc_price
        } = pack;
      }

      var className = "prodcut-list Product-box b-shadow"

      if(cart){
        className = 'cart-item ' + className
      }

      const PriceWrapper = (
        <div className="price-wrapper">
          <span className="currency grey font-16">{store.currency.symbol}</span> 
          <span className="price black">{disc_price}</span> 
          {disc_price !== selling_price && <span className="old-price grey font-16">{selling_price}</span>}
        </div>
      )

      const Counter = (
        <CartCounter
          count={count}
          onIncrement={this.increment}
          onDecrement={this.decrement}/>    
      )

      const WeigthModal = (
        <Modal
          size={'mini'}
          open={this.state.popup}
          onClose={this.chooseWeight}
        >
          <Modal.Header>Available Packs</Modal.Header>
          <Modal.Content>
            {this.renderWeights(packs)}
          </Modal.Content>
        </Modal>
      )

      const OfferWrapper = (
        <div className="offer">
          <AImage src={offerTag} alt="offer" className="img-fluid"/>
          <div className="centered">
              {byPerc?
                <div className="per">{discount}% OFF</div>:
                <div className="per">INR {discount}</div>
              }
          </div>
        </div>
      )

      const WeightButton = (
        packs.qtys.map((qt, i)=>{
          if(i == selected){
            return  <button onClick={this.chooseWeight} className="weight mt-2 grey font-16 drp-btn" key={i}>
                {qty} {unit} <i class="zmdi zmdi-chevron-down"></i>
            </button>
          }
        })
      )

      if(horizontal){
        return ( 
          <div className={className} >
              <div className="left-box" onClick={this.onClick}>
                <AImage src={featured_image} className="img-fluid img-h" />
                {offer ? OfferWrapper:null}
              </div>
              <div className="rightox" >
                {/* <div className="cat-name grey font-14">{brand}</div> */}
                <div onClick={this.onClick}>
                  {PriceWrapper}
                  <h4 className="p-title font-18 black">{title}{attributes && attributes.map((at, i)=>(
                      <span> {at.value}</span>
                    ))}</h4>
                </div>
                <div className="flex-hr">
                  {WeightButton}
                <div className="btn-wrap">
                  {Counter}
                </div>
                </div>
              </div>
              {WeigthModal}
          </div>
        );
      }

      return ( 
          <React.Fragment>
            <div className="card Product-box" >
              <div className="card-top" onClick={this.onClick}>
              <AImage src={featured_image} className="img-fluid img-v" />
              {offer ? OfferWrapper:null}
              <div className="card-body p-ht">
                  {/* <div className="cat-name grey font-14">{brand}</div> */}
                  {PriceWrapper}
                  <h4 className="p-title font-18 black t-h">{title}{attributes && attributes.map((at, i)=>(
                      <span> {at.value}</span>
                    ))}</h4>
                  {WeightButton}
              </div>
              </div>
              <div className="card-footer">
                {Counter}
              </div>
            </div>
            {WeigthModal}
        </React.Fragment>
      );
    }
}

const mapStateToProps = state => ({
  cart: state.cartReducer.cart,
  store: state.storeReducer.store,
});

const mapDispatchToProps = dispatch => ({
     onAddToCart:(item, count)=>{
        dispatch(addToCart(item, count))
    },
    onRemoveFromCart:(item, count)=>{
      dispatch(removeFromCart(item, count))
    }
});
const ProductContainer = connect(
                        mapStateToProps,
                        mapDispatchToProps)
                        (ProductCard);
export default ProductContainer;
